import { keys } from "../utils";
import { isSSR } from "../utils/functions";
import { baseRequest } from "../utils/axios";

// get popular categories for homepage
export const getBranchDetails = ({ branch, isThunkCall = false }) => {
  let query = `query {
      branch_info(url: "${branch}") {
        branch_id
        store_id
        name
        status
        type
        address1
        address2
        address3
        address4
        postcode
        latitude
        telephone
        longitude
        distance
        image{
          url
          name
      }
        hyperlink
        description
        opening_times
        email
        is_hub
        opening_mon_fri
        closing_mon_fri
        open_saturday
        opening_sat
        closing_sat
        open_sunday
        opening_sun
        closing_sun
        type_id
         additional_info
    holiday_info
        {date,description}
      }
    }`;

  if (isThunkCall) {
    query = `query {
      branch_info(url: "${branch}") {
        branch_id
        store_id
        name
        type
        address1
        address2
        address3
        address4
        area
      distance
        postcode
        latitude
        telephone
        longitude
        hyperlink
        email
        type_id
    
      }
    }`;
  }
  return baseRequest(keys.general.graphqlUrl, query);
};


// get branches lists
export const getBranchesList = ({ lat, lng, limit = 5 }) => {
  const resolveURL = isSSR()
    ? keys.general.graphqlUrl
    : keys.general.backendGraphqlUrl;

  return baseRequest(
    resolveURL,
    `query {
    nearest_branches(lat:${lat} , long:${lng}, pageSize: ${limit}) {
      branch_id
      store_id
      type
      name
      area
      email
      is_hub
      address1
      address2
      address3
      address4
      postcode
      latitude
      holiday_info
        {date}
      longitude
      telephone
      hyperlink
      distance
      description
      opening_sat
      closing_sat
      open_sunday
      opening_sun
      closing_sun
      open_saturday
      closing_mon_fri
      opening_mon_fri
    }
  }`,
  );
};

// get branches lists
export const getAllBranches = () => {
  return baseRequest(
    keys.general.graphqlUrl,
    `query {
      branch_list{
        branch_id
        name
        type
        address1
        address2
        address3
        address4
        postcode
        latitude
        longitude
        hyperlink
        telephone
      }
    }`,
  );
};

// get branches list timings
export const getDefaultTimings = () => {
  return baseRequest(
    keys.general.graphqlUrl,
    `query {
      branch_default_timing{
        opening_mon_fri
        closing_mon_fri
        opening_sat
        closing_sat
        opening_sun
        closing_sun
        open_saturday
        open_sunday
      }
    }`,
  );
};

"use client";

import Image from "next/image";
import { useState, useEffect } from "react";
import BackToTop from "@/assets/icon/back-to-top.svg?url";
import Button from "@/components/common/shared/Button/button";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Button
      theme="iconOnly"
      onClick={scrollToTop}
      aria-label="Scroll to Top"
      className={`back-to-top z-[999] fixed bottom-8 right-8 xs:max-w-[64px] max-w-[42px] h-auto ${
        isVisible ? "opacity-100" : "opacity-0"
      } transition-opacity duration-300`}
    >
      <Image
        width={64}
        height={64}
        unoptimized
        src={BackToTop}
        alt="back-to-top"
      />
    </Button>
  );
};

export default BackToTopButton;

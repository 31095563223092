"use client";

import Link from "next/link";
import Image from "next/image";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { routes } from "@/lib/utils/routes";
import CustomSelect from "../shared/Select";
import { useAppSelector } from "@/lib/store";
import Button from "../shared/Button/button";
import { useRouter } from "next-nprogress-bar";
import { constants } from "@/lib/utils/constants";
import DeliveryOption from "./PLP/DeliveryOption";
import Map from "@/assets/icon/mapPinIcon.svg?url";
import ImageWithFallback from "../ImageWithFallback";
import { add_to_cart, select_item } from "@/lib/gtm";
import { Fragment, useCallback, useMemo } from "react";
import InfoIcon from "@/assets/icon/indo-tooltip-icon.svg";
import CheckCircle from "@/assets/icon/checkCircleIcon.svg?url";
import CategoryBox from "../shared/ProductListing/components/CategoryBox";
import {
  formatProductCategoriesGA4,
  updateGA4PayloadToSelectItem,
} from "@/lib/utils/functions";
import styles from "@/assets/css/pages/font.module.css";
// Dynamic importing components
const SpecialPriceBadge = dynamic(() => import("./SpecialPriceBadge"));
const ProductRating = dynamic(() => import("../shared/ProductRating"));
const GuaranteeBadge = dynamic(() => import("../shared/Badge/GuaranteeBadge"));
const CompatibleBadge = dynamic(() =>
  import("../shared/Badge/CompatibleBadge"),
);

export default function ProductListingCard({
  t,
  is3BO,
  assets,
  product,
  loop_index,
  showFitmentInfo,
  showBranchModal,
  handleBrandChange,
  vehicleSpecificSKU,
  handleAddToTrolley,
  showVehicleSpecific,
  vehicle = null,
}) {
  const router = useRouter();
  const { showCheckStock } = useAppSelector((state) => state.globalAssets);
  const detail = useMemo(() => {
    const brands = product?.brands || [];
    const totalBrands = brands?.length;
    const productBranch = product?.branch;
    const brand = brands.find((b) => b.is_selected);

    return {
      brands,
      totalBrands,
      productBranch,
      selectedBrand: brand,
      warranty_guaranteed_text: brand?.warranty_guaranteed_text || null,
      stop_start_compatible: brand?.stop_start_compatible || null,
      homeDeliveryStock: brand?.quantity > 0,
      brandTier: brand?.brand_tier?.toLowerCase(),
      title: brand?.product_title_with_brand || product?.product_title,
      product_name_without_brand: product?.product_title,
      muteClickCollect:
        productBranch?.branch_id &&
        brand?.branch_stock < 1 &&
        brand?.hub_stock < 1,
      stock_in_branch: brand?.branch_stock,

      surchargeItem: brand?.fitment_info?.find(
        (fi) => fi.label === "Surcharge",
      ),
    };
  }, [product]);

  let ga4_payload = {
    currency: detail?.selectedBrand?.currency,
    value: detail?.selectedBrand?.final_price_without_currency,
    items: [
      {
        item_id: detail?.selectedBrand?.sku,
        item_name: detail?.product_name_without_brand,
        discount: detail?.selectedBrand?.amountOff_without_currency,
        item_brand: detail?.selectedBrand?.title,
        price: detail?.selectedBrand?.final_price_without_currency,
        quantity: 1,
        index: loop_index,
        ...(Number(detail?.selectedBrand?.amountOff_without_currency) &&
          !detail?.selectedBrand?.specialCoupon_applied && {
            coupon: Cookies.get("specialCoupon"),
          }),
        ...formatProductCategoriesGA4(detail?.selectedBrand?.breadcrumbs),
      },
    ],
  };

  const addToCartEvent = () => {
    add_to_cart(ga4_payload);
  };

  const addProductToBasket = () => {
    handleAddToTrolley({
      product: {
        name: detail?.title,
        sku: detail?.selectedBrand?.sku,
        thumbnail: detail?.selectedBrand?.thumbnail,
        soldInPairs: detail?.selectedBrand?.sold_in_pairs || 0,
      },
      addToCartEvent: addToCartEvent,
    });
  };

  const renderInStockIcon = useCallback(() => {
    if (!detail?.homeDeliveryStock && detail?.muteClickCollect) return null;
    return (
      <Fragment>
        <Image
          width={20}
          height={20}
          src={CheckCircle}
          alt="check circle"
          className="max-w-[20px]"
        />
        <p className="text-body-text-3-sb  xs:text-body-text-3-m text-tertiary-green whitespace-nowrap">
          {t("product.in_stock")}
        </p>
      </Fragment>
    );
  }, [t, detail]);

  const renderIsFit = useCallback(() => {
    if (
      is3BO &&
      vehicle &&
      !showVehicleSpecific &&
      vehicleSpecificSKU.includes(detail?.selectedBrand.sku)
    ) {
      return (
        <>
          <div className="flex xs:w-max w-12/12 justify-between">
            <p className="capitalize text-minor-text-1 text-primary-black-500">
              {t("vehicle_fit", {
                model: `${vehicle?.vehicle?.make} ${vehicle?.vehicle?.model}`,
              })}
            </p>

            {detail?.brandTier && (
              <span
                className={`radio-badge capitalize xs:hidden block ${detail?.brandTier}`}
              >
                {detail?.brandTier}
              </span>
            )}
          </div>
          <span className="mx-1 capitalize text-minor-text-1 text-primary-black-500 xs:inline-block hidden">
            |
          </span>
        </>
      );
    }
    return null;
  }, [vehicle, is3BO, showVehicleSpecific, vehicleSpecificSKU, detail, t]);

  return (
    <div className="plpProductBox">
      <div className="lg:relative absolute lg:w-12/12 lg:max-w-[312px]">
        <Link
          prefetch={false}
          href={`${routes.products}/${detail?.selectedBrand?.url_key}`}
        >
          <div
            className="coverProductImage plp-coverProductImage absolute test"
            onClick={() => {
              select_item(
                updateGA4PayloadToSelectItem(
                  ga4_payload,
                  detail?.selectedBrand?.breadcrumbs,
                  "Category Products",
                ),
              );
            }}
          >
            <ImageWithFallback
              width={200}
              height={200}
              key={detail?.selectedBrand?.sku}
              type={constants.image_types.product}
              src={detail?.selectedBrand?.thumbnail?.url}
              className="w-12/12 h-auto aspect-[1/1] max-h-max"
              alt={
                detail?.selectedBrand?.thumbnail?.label ||
                detail?.selectedBrand?.sku
              }
            />
          </div>
        </Link>
        {(detail?.selectedBrand?.sold_in_pairs == 1 ||
          detail?.surchargeItem) && (
          <div className="offers-group">
            {detail?.selectedBrand.sold_in_pairs == 1 && (
              <div className="bg-tertiary-yellow-600 w-12/12 lg:block hidden p-[6px]">
                <div className="flex gap-2">
                  <InfoIcon />
                  <p className="w-full max-w-[clac(100%-28px)] text-minor-text-1-mb">
                    {t("sold_unit")}
                  </p>
                </div>
              </div>
            )}
            {detail?.surchargeItem && (
              <div className="bg-tertiary-yellow-600 w-12/12 lg:block hidden p-[6px]">
                <div className="flex gap-2">
                  <InfoIcon />
                  <p className="w-full max-w-[clac(100%-28px)] text-minor-text-1-mb">
                    {t("product-listing:trade_text", {
                      amount: detail?.surchargeItem.value,
                    })}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="badge-img">
          {detail?.selectedBrand?.best_seller == 1 && (
            <ImageWithFallback
              width={100}
              height={15.6}
              alt="Best seller"
              src={assets.bestseller}
              type={constants.image_types.product_best_seller}
              className="w-auto xs:h-[22px] xs:max-h-[22px] h-4 max-h-4 object-contain object-left"
            />
          )}

          {detail?.totalBrands === 1 &&
            detail?.selectedBrand?.is_recommended == 1 && (
              <div className="verified-product">
                <ImageWithFallback
                  width={100}
                  height={15.6}
                  alt="Recommended"
                  src={assets.recommended}
                  className="max-h-[27px] object-contain"
                  type={constants.image_types.brand_recommended}
                />
              </div>
            )}
        </div>

        {detail?.selectedBrand?.showDiscountedPrice && (
          <SpecialPriceBadge price={detail?.selectedBrand?.amountOff} />
        )}
      </div>

      <div className="plpContentBox">
        <div className="plpleftPart">
          <div className="plpTopleftPart">
            <Link
              prefetch={false}
              className="block w-12/12 relative z-10 max-w-max test"
              href={`${routes.products}/${detail?.selectedBrand.url_key}`}
            >
              <h2
                className={`${styles.heading22} heading-22-xs lg:max-w-12/12 font-semibold`}
                role="button"
                tabIndex={0}
                onClick={() => {
                  select_item(
                    updateGA4PayloadToSelectItem(
                      ga4_payload,
                      detail?.selectedBrand?.breadcrumbs,
                      "Category Products",
                    ),
                  );
                }}
              >
                {detail?.title}
              </h2>
            </Link>

            <div className="flex flex-row items-center xs:my-2 my-1 justify-between">
              <div className="flex xs:flex-row flex-col xs:items-center items-start">
                {renderIsFit()}
                <p className="capitalize text-minor-text-1 text-primary-black-500">
                  {t("product.part_number")}: {detail?.selectedBrand?.sku}
                </p>
              </div>
              <div className="xs:hidden block">
                {!is3BO && detail?.brandTier && (
                  <span
                    className={`radio-badge capitalize ${detail?.brandTier}`}
                  >
                    {detail?.brandTier}
                  </span>
                )}
              </div>
            </div>
            {detail?.selectedBrand?.review?.average_rating && (
              <ProductRating
                rating={detail?.selectedBrand?.review?.average_rating}
              />
            )}

            {(detail?.warranty_guaranteed_text ||
              detail?.stop_start_compatible) && (
              <div className="flex xs:flex-row flex-col gap-2 xs:mt-3 mt-1">
                {detail?.warranty_guaranteed_text && (
                  <GuaranteeBadge title={detail?.warranty_guaranteed_text} />
                )}
                {detail?.stop_start_compatible && (
                  <CompatibleBadge
                    title={t("common:product.stop_start_compatible")}
                  />
                )}
              </div>
            )}
            <div className="flex justify-between w-12/12 items-center space-x-2 my-0 mt-2 xs:mt-12">
              <div className="flex items-center">{renderInStockIcon()}</div>
              {detail?.totalBrands > 1 && (
                <p className="text-body-text-1-xb xs:hidden flex items-center">
                  {detail?.selectedBrand?.final_price}
                </p>
              )}
            </div>

            {showCheckStock && (
              <div
                className="flex items-center mb-2 animation-fadeIn mt-[6px] sm:hidden flex-wrap"
                onClick={() => showBranchModal(product)}
              >
                <div className="w-5">
                  <Image
                    alt="Map"
                    src={Map}
                    width={20}
                    height={20}
                    className="w-auto"
                  />
                </div>
                {detail?.productBranch?.name?.length > 0 && (
                  <>
                    <span className="text-body-text-3-m text-primary-blue-900 m-0 mr-1">
                      {detail?.productBranch.name}{" "}
                    </span>
                    {detail?.stock_in_branch > 0 &&
                      detail?.stock_in_branch <= 5 && (
                        <span className="text-tertiary-red  text-body-text-3-sb mr-1">
                          {" "}
                          {t("common:product.stock_in_branch", {
                            stock: detail?.stock_in_branch,
                          })}
                        </span>
                      )}
                  </>
                )}
                <span className="text-body-text-3-sb text-primary-blue-900  m-0 underline hover:no-underline cursor-pointer">
                  {detail?.productBranch?.name
                    ? t("product.delivery_points.change_branch")
                    : t("product.delivery_points.check_stock")}
                </span>
              </div>
            )}

            <div className="w-12/12 max-w-[322px] xs:mt-12 mb-3">
              <div
                role="button"
                tabIndex={0}
                onClick={() =>
                  showFitmentInfo(detail?.selectedBrand.fitment_info)
                }
                className="normalSelect white xs:relative absolute xs:bottom-0 bottom-4 left-0 w-12/12 "
              >
                <CustomSelect
                  isDisabled
                  options={[]}
                  placeholder={t("product-listing:fitment_info")}
                  id={`plp-select-${detail?.selectedBrand.sku}`}
                />
              </div>
            </div>
          </div>

          {detail?.totalBrands === 1 && (
            <div className="xs:hidden flex w-12/12 max-w-[calc(100%-140px)] items-center ml-[140px] justify-between">
              <div className="flex items-center">
                <ImageWithFallback
                  width={78}
                  height={16}
                  className="h-[24px] object-contain"
                  src={detail?.selectedBrand?.image}
                  type={constants.image_types.brand}
                  alt={detail?.selectedBrand?.title || "brand"}
                  key={`${detail?.selectedBrand?.title}_single`}
                />
              </div>
              <p className="xl:text-headline-3 sm:text-headline-4 text-body-text-1-b xs:mb-2 mb-0">
                <span
                  className={`${
                    detail?.selectedBrand?.showStrikePrice
                      ? "text-primary-red"
                      : ""
                  }`}
                >
                  {detail?.selectedBrand?.final_price}
                </span>
                {detail?.selectedBrand?.showStrikePrice && (
                  <span className="oldPrice ms-[6px]">
                    {detail?.selectedBrand?.regular_price}
                  </span>
                )}
              </p>
            </div>
          )}

          {detail?.totalBrands > 1 && (
            <div className="lg:pt-0 xs:pt-3 pt-0">
              <div className="w-12/12 xs:mb-0 relative">
                <CategoryBox
                  brands={detail?.brands}
                  identifier={product.article_number}
                  handleBrandChange={handleBrandChange}
                />
              </div>
            </div>
          )}
        </div>

        {(detail?.selectedBrand?.sold_in_pairs == 1 ||
          detail?.surchargeItem) && (
          <div className="block lg:hidden mt-3 w-full">
            {detail?.selectedBrand.sold_in_pairs == 1 && (
              <div className="flex items-center gap-2 bg-tertiary-yellow-600 rounded-lg p-[6px]">
                <InfoIcon />
                <p className="w-full max-w-[calc(100%-28px)] text-minor-text-1-mb">
                  {t("sold_unit")}
                </p>
              </div>
            )}
            {detail?.surchargeItem && (
              <div className="flex xs:items-center gap-2 mt-1  bg-tertiary-yellow-600 rounded-lg p-[6px]">
                <InfoIcon />
                <p className="w-full max-w-[calc(100%-28px)] text-minor-text-1-mb">
                  {t("trade_text", {
                    amount: detail?.surchargeItem.value,
                  })}
                </p>
              </div>
            )}
          </div>
        )}

        <div className="plpRighPart">
          <div className="lg:flex hidden justify-between items-start brand">
            {detail?.totalBrands > 0 && (
              <div className="w-auto max-h-[40px] min-h-[40px] relative flex">
                <ImageWithFallback
                  width={192}
                  height={40}
                  src={detail?.selectedBrand?.image}
                  type={constants.image_types.brand}
                  alt={detail?.selectedBrand?.title || "brand"}
                  key={`${detail?.selectedBrand?.title}_selected`}
                  className="object-contain object-left"
                />
              </div>
            )}
            {!is3BO && detail?.brandTier && (
              <span className={`radio-badge capitalize ${detail?.brandTier}`}>
                {detail?.brandTier}
              </span>
            )}
          </div>

          <DeliveryOption
            t={t}
            product={product}
            showCheckStock={showCheckStock}
            showBranchModal={showBranchModal}
            productBranch={detail?.productBranch}
            muteClickCollect={detail?.muteClickCollect}
            homeDeliveryStock={detail?.homeDeliveryStock}
            stock_in_branch={detail?.stock_in_branch}
          />

          <div className="flex lg:flex-col lg:items-start items-center justify-between flex-row">
            <div className="xs:flex hidden items-baseline">
              <p
                className={`xl:text-headline-3 xs:text-headline-4 text-body-text-1-b lg:mb-2 mb-0 flex ${
                  detail?.selectedBrand?.showStrikePrice
                    ? "text-primary-red"
                    : ""
                }`}
              >
                {detail?.selectedBrand?.final_price}
              </p>
              {detail?.selectedBrand?.showStrikePrice && (
                <span className="oldPrice ms-[6px]">
                  {detail?.selectedBrand?.regular_price}
                </span>
              )}
            </div>
            <Button
              icon="shopping"
              iconPosition="left"
              onClick={addProductToBasket}
              theme={`add-to-basket-btn ${
                !detail?.homeDeliveryStock &&
                detail?.muteClickCollect &&
                "grey-btn"
              }`}
              disabled={!detail?.homeDeliveryStock && detail?.muteClickCollect}
              className="xs:relative absolute xs:bottom-0 bottom-[68px] left-0 lg:!w-12/12 xs:!w-6/12 w-12/12"
            >
              {t("button.add_to_basket")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

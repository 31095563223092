"use client";

import "swiper/css/pagination";
import "swiper/css/effect-fade";

import Link from "next/link";
import { getImageProps } from "next/image";
import { Autoplay, Pagination } from "swiper/modules";
import { updateImageURL } from "@/lib/utils/functions";
import CommonSwiper from "@/components/common/shared/Slider";
import { getContentfulImgData } from "@/lib/services/contentful";

export default function ShopNow({
  sliderTime,
  promotionalBanner,
  promotionalBannerForMobile,
}) {
  const sliderConfig = {
    slidesPerView: 1,
    pagination: { clickable: true },
    modules: [Pagination, Autoplay],
    className: "megaSaleSwiper",
    speed: 300,
    autoplay: {
      delay: sliderTime || "7000",
      disableOnInteraction: false,
    },
    loop: true,
  };

  const slides = promotionalBanner?.map?.((banner, index) => {
    const desktopImg = getContentfulImgData(banner?.fields?.image);
    const mobileImg = getContentfulImgData(
      promotionalBannerForMobile?.[index]?.fields?.image,
    );
    const bannerLink = banner?.fields?.link;
    const common = {
      loading: "lazy",
      alt: desktopImg.title || desktopImg.fileName || "Show Now",
    };
    const {
      props: { srcSet: desktop },
    } = getImageProps({
      ...common,
      width: 1440,
      height: 440,
      quality: 90,
      src: updateImageURL(desktopImg.imageUrl, 1440, 440, true),
    });

    const {
      props: { srcSet: mobile, ...rest },
    } = getImageProps({
      ...common,
      width: 450,
      height: 450,
      quality: 85,
      src: updateImageURL(mobileImg.imageUrl, 450, 450, true),
    });

    const PictureTag = (
      <picture>
        <source media="(min-width: 768px)" srcSet={desktop} />
        <source media="(max-width: 767px)" srcSet={mobile} />
        <img
          {...rest}
          alt={common.alt}
          className="w-12/12 xs:order-1 order-2"
          style={{ width: "100%", height: "auto" }}
        />
      </picture>
    );

    return {
      content: (
        <div className="relative">
          {bannerLink ? (
            <Link prefetch={false} href={bannerLink}>
              {PictureTag}
            </Link>
          ) : (
            PictureTag
          )}
        </div>
      ),
    };
  });

  return (
    <section className="megaSaleSection bg-tertiary-white xs:pb-80">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <div className="relative w-12/12 greyBanner home-banner-section">
              <CommonSwiper slides={slides} config={sliderConfig} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import Button from "../Button/button";
import ProductItem from "./components/product";
import {
  getPrice,
  handleToastSuccess,
  messages,
  mutateProductInfo,
  routes,
} from "@/lib/utils";
import { useAppSelector } from "@/lib/store";
import { getCartCounts, getCartPrice } from "@/lib/store/reducers/cart";
import useTrolleyUtility from "@/lib/hooks/useTrolleyUtility";
import EmptyCart from "@/assets/static/empty-basket.svg?url";
import Image from "next/image";
import { useTranslation } from "@/i18n/client";
import { useRouter } from "next-nprogress-bar";
import { useTouchHandler } from "@/lib/hooks/useTouchHandler";
import Link from "next/link";
import styles from "@/assets/css/pages/font.module.css";
import { setMiniTrolley } from "@/lib/store/reducers/uiState";

const MiniTrolley = ({ isVisible, dispatch }) => {
  const router = useRouter();
  const { t } = useTranslation(["miniTrolley"]);
  const touchHandlers = useTouchHandler();

  const closeMiniTrolley = () => {
    dispatch(setMiniTrolley(false));
  };

  const {
    data: { cartProducts, productsState },
    methods: {
      handleRemoveFromCart,
      handleQuantityInput,
      handleEnterOnInput,
      handleMinus,
      handlePlus,
    },
  } = useTrolleyUtility();

  const { totalCount } = useAppSelector(getCartCounts);
  const cartPrice = useAppSelector(getCartPrice);

  const onRemove = (cartItem) => {
    handleRemoveFromCart({
      cartItem: cartItem,
      onSuccess: () => {
       return handleToastSuccess(messages.success.productRemoved , {
          autoClose: 2000,
          hideProgressBar: true,
        }
      );
      },
    });
  };

  return (
    <div className={`sideBar ${isVisible ? "slide-in" : "slide-out"}`}>
      <div className="box">
        <div className="boxHeader">
          <p className={`${styles.heading24} text-primary-black`}>
            {t("miniBasket")}

            {totalCount > 0 && <span>({totalCount})</span>}
          </p>
          <Button theme="iconOnly" icon="close" onClick={closeMiniTrolley} />
        </div>
        <div
          className={`boxListing scrollbar ${
            Object.keys(cartProducts).length > 0 ? "" : "flex-center"
          }`}
        >
          {Object.keys(cartProducts).length > 0 ? (
            Object.values(cartProducts).map((cartItem) => {
              const item = mutateProductInfo(cartItem, true);
              return (
                <ProductItem
                  key={cartItem?.uid}
                  cartItem={item}
                  onRemove={onRemove}
                  handleEnterOnInput={handleEnterOnInput}
                  handleQuantityInput={handleQuantityInput}
                  handleMinus={handleMinus}
                  handlePlus={handlePlus}
                  productState={productsState[cartItem?.product?.sku]}
                  fromMinitrolly={true}
                />
              );
            })
          ) : (
            <div
              className={`flex justify-center items-center flex-col sm:pb-0 pb-24 pnf-box ${
                Object.keys(cartProducts).length > 0
                  ? "sm:pt-[87.4px] pt-24"
                  : "pt-0"
              }`}
            >
              <Image
                width={360}
                height={131}
                src={EmptyCart}
                alt="Empty Basket"
                className="xs:mb-[40px] mb-[24px] xs:max-w-max max-w-[70%]"
              />

              <p className={`${styles.heading32} xs:mb-4 mb-12 text-primary-black`}>
                {t("emptyBasket")}
              </p>

              <p className="xs:text-body-text-1 text-body-text-3 text-primary-black-800 text-center xs:mb-8 mb-6">
                {t("noItemsInBasket")}
              </p>
              <Button
                type="button"
                onClick={() => {
                  router.push(routes.home);
                  closeMiniTrolley();
                }}
                theme="blue-btn"
                className="max-w-[220px]"
                icon="arrow"
                iconPosition="left"
              >
                {t("continueShopping")}
              </Button>
            </div>
          )}
        </div>
        <div className="boxFooter">
          <div className="flex justify-between items-center xs:px-24 px-4 xs:py-[9.5px] py-[11.5px] border-y border-y-primary-black-50 mb-12">
            <p className={`${styles.heading24} !text-primary-black`}>
              {t("total")}
            </p>
            <p className={`${styles.heading24} !text-primary-black`}>
              {getPrice(
                cartPrice?.grand_total?.value,
                cartPrice?.grand_total?.currency,
              )}
              <span className="ms-[7px] !text-primary-black-700 font-medium text-[14.62px]">
                {t("vat")}
              </span>
            </p>
          </div>
          <div className="flex flex-col xs:px-24 px-4 pb-24">
            <p className="text-primary-black-600 text-body-text-3 mb-12 text-center">
              {t("deliveryCharges")}
            </p>
            <Link
             prefetch={false}
              href={routes.basket}
              className="checkout-btn"
              onClick={closeMiniTrolley}
            >
              {t("goToBasket")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MiniTrolley;

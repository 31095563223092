"use client";

import Cookies from "js-cookie";
import { useTranslation } from "@/i18n/client";
import React, { useEffect, useState } from "react";
import { applyCouponToCart } from "@/lib/services/cart";
import { useAppSelector, useAppDispatch } from "@/lib/store";
import Button from "@/components/common/shared/Button/button";
import CloseIcon from "@/components/common/shared/Button/SvgIcons/close";
import { endCommonLoading, startCommonLoading } from "@/lib/store/reducers/uiState";
import {
  getProductsMap,
  handleToastError,
  handleGraphqlResponse,
} from "@/lib/utils/functions";
import {
  getCartCounts,
  updateUniqCount,
  updateTotalCount,
  addOrUpdateProducts,
  addOrUpdateCartPrice,
  addOrUpdateAppliedCoupons,
  updateIsHomedeliveryAvailable,
} from "@/lib/store/reducers/cart";

const TopAlertHeader = ({ cookieKeys = {}, data = {} }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["header"]);
  const { uniqCount } = useAppSelector(getCartCounts);
  const { timezone: tzString } = useAppSelector((state) => state.globalAssets);

  const [showTimer, setShowTimer] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [bannerData, setBannerData] = useState(data);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date(
        new Date().toLocaleString("en-US", { timeZone: tzString }),
      );
      const currentTime = now.getTime();
      const start = bannerData?.start_date_time
        ? new Date(bannerData?.start_date_time)
        : null;

      const end = bannerData?.end_date_time
        ? new Date(bannerData?.end_date_time)
        : null;

      const endLimit = bannerData?.to_date
        ? new Date(
            new Date(bannerData?.to_date).toLocaleString("en-US", {
              timeZone: tzString,
            }),
          )
        : null;

      // Scenario 1: countdown until end date if exists
      let targetTime = start && end ? end : null;
      if (!targetTime) {
        if (bannerData?.countdown_time == "00:00") {
          // Scenario 2: Daily reset at 12:00 PM
          if (start && !end) {
            const midday = new Date();
            midday.setHours(0, 0, 0, 0);
            if (now >= midday) {
              midday.setDate(midday.getDate() + 1);
            }
            targetTime = midday;
          }
        } else if (start && !end) {
          // Scenario 3: daily countdown
          const countdownArr = bannerData?.countdown_time.split(":");
          const hours = parseInt(countdownArr[0], 10);
          const minutes = parseInt(countdownArr[1] || "0", 10);

          // Set the start of the countdown (00:00)
          const countdownStart = new Date(now);
          countdownStart.setHours(0, 0, 0, 0); // Set to midnight of the current day

          // Set the end of the countdown (08:46)
          const countdownEnd = new Date(countdownStart);
          countdownEnd.setHours(hours, minutes, 0, 0);
          if (now >= countdownStart && now <= countdownEnd) {
            targetTime = countdownEnd;
          } else {
            setShowTimer(false);
            return;
          }
        }
      }

      if (targetTime) {
        const diff = targetTime - currentTime;
        if (diff > 0) {
          setTimeLeft(() => {
            return {
              days: Math.floor(diff / (1000 * 60 * 60 * 24)),
              hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
              minutes: Math.floor((diff / 1000 / 60) % 60),
              seconds: Math.floor((diff / 1000) % 60),
            };
          });
        } else {
          // Hide timer when time runs out
          setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
          setShowTimer(false);
        }
      }

      if (endLimit && now > endLimit) {
        setBannerData(null); // hide banner if past end date
      }
    };

    if (Number(bannerData?.countdown_status) === 1 && tzString) {
      calculateTimeLeft();
      const timer = setInterval(calculateTimeLeft, 1000);
      return () => clearInterval(timer);
    }
  }, [bannerData, tzString]);

  if (!bannerData?.entity_id) return null;

  const {
    text,
    terms,
    bkg_colour,
    button_text,
    coupon_code,
    font_colour,
    border_colour,
    countdown_status,
    cookies_lifetime,
    auto_apply_button,
  } = bannerData || {};

  const handleClose = () => {
    setIsVisible(false);
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    const offset = isMobile ? 99 : 118;

    const productSticky = document.getElementById("product-sticky-header");
    if (productSticky) {
      productSticky.style.marginTop = "0";
    }

    const basketStiicky = document.getElementById("basket-sticky-header");
    if (basketStiicky) {
      basketStiicky.style.top = "0";
    }
    const mobileSearchBox = document.getElementById("mobile-search-box");
    if (mobileSearchBox) {
      mobileSearchBox.style.top = `${offset}px`;
    }
    const modalStickyHeader = document.getElementById("modal-sticky-header");
    if (modalStickyHeader) {
      modalStickyHeader.style.top = "0";
    }

    if (cookies_lifetime && cookies_lifetime > 0) {
      const date = new Date();
      date.setTime(date.getTime() + cookies_lifetime * 3600 * 1000);
      Cookies.set(cookieKeys.headerBannerClosed, 1, {
        secure: true,
        expires: date,
        sameSite: "strict",
      });
    } else {
      Cookies.set(cookieKeys.headerBannerClosed, 1, {
        secure: true,
        sameSite: "strict",
      });
    }
  };

  const handleApplyCoupon = async () => {
    if (uniqCount == 0) {
      handleToastError("Coupon cannot be applied to an empty basket");
      return;
    }

    dispatch(startCommonLoading());
    try {
      const apiData = await applyCouponToCart({ couponCode: coupon_code });
      const data = handleGraphqlResponse(apiData);
      const products = data?.applyCouponToCart?.cart?.itemsV2?.items;
      const productsMap = getProductsMap(products);
      const _cartPrice = data?.applyCouponToCart?.cart?.prices;
      const _appliedCoupons = data?.applyCouponToCart?.cart?.applied_coupons;
      const uniq_Count = data?.applyCouponToCart?.cart?.itemsV2?.total_count;
      const total_Count = data?.applyCouponToCart?.cart?.total_quantity;
      const isHomeDeliveryAvailable =
        data?.applyCouponToCart?.cart?.is_home_delivery_available;
      dispatch(addOrUpdateProducts(productsMap));
      dispatch(addOrUpdateCartPrice(_cartPrice));
      dispatch(addOrUpdateAppliedCoupons(_appliedCoupons));
      dispatch(updateTotalCount(total_Count));
      dispatch(updateUniqCount(uniq_Count));
      dispatch(updateIsHomedeliveryAvailable(isHomeDeliveryAvailable));
      Cookies.set("specialCoupon", coupon_code, {
        secure: true,
        sameSite: "strict",
      });
      Cookies.set(cookieKeys.smartBannerCouponApplied, 1, {
        secure: true,
        sameSite: "strict",
      });
    } catch (error) {
      handleToastError(error.message);
    }
    dispatch(endCommonLoading());
  };

  return (
    <>
      {isVisible && (
        <div
          id="top-alert-header"
          className={`topAlertHeader ${
            bkg_colour == null
              ? "bg-gradient-to-b from-[#EFAF4F] to-[#F9D371]"
              : ""
          } ${isVisible ? "animate-fade-down" : "animate-fade-up"} ${
            border_colour == null ? "border-b border-b-tertiary-black" : ""
          }`}
          style={{
            ...(bkg_colour != null && { background: bkg_colour }),
            ...(border_colour != null && {
              borderBottom: `1px solid ${border_colour}`,
            }),
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col w-12/12">
                <div className="flex justify-between  items-center mx-auto">
                  {!Cookies.get(cookieKeys.smartBannerCouponApplied) ? (
                    <div className="flex flex-col w-full justify-center">
                      <div className="flex sm:gap-6 gap-[2px] items-center sm:flex-row flex-col w-full justify-center">
                        <div className="flex xs:gap-5 gap-4 xs:items-center items-start xs:mb-0 mb-1 justify-between px-[10px]">
                          <p
                            className={`text-body-text-3 capitalize xs:max-w-fit max-w-[70%] ${
                              font_colour == null ? `text-primary-black` : ""
                            }`}
                            style={
                              font_colour != null ? { color: font_colour } : {}
                            }
                            dangerouslySetInnerHTML={{ __html: text }}
                          ></p>
                          {/* <span className="w-[6px] h-[6px] rounded-[50%] bg-primary-black xs:mt-0 mt-[6px] min-w-[6px]">  </span> */}
                          <p
                            className={`text-body-text-3 capitalize relative use-code max-w-fit w-full pl-3 ${
                              font_colour == null ? `text-primary-black` : " "
                            }`}
                            style={
                              font_colour != null ? { color: font_colour } : {}
                            }
                          >
                            {t("coupon_banner.use_code")}{" "}
                            <span
                              className={`text-body-text-3-b ${
                                font_colour == null ? `text-primary-black` : ""
                              }`}
                              style={
                                font_colour != null
                                  ? { color: font_colour }
                                  : {}
                              }
                              dangerouslySetInnerHTML={{ __html: coupon_code }}
                            ></span>
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          {showTimer && countdown_status === "1" && (
                            <>
                              {" "}
                              <p
                                className={`text-body-text-3-m uppercase ${
                                  font_colour == null
                                    ? `text-primary-black`
                                    : ""
                                }`}
                                style={
                                  font_colour != null
                                    ? { color: font_colour }
                                    : {}
                                }
                              >
                                {t("coupon_banner.offer_expires_in")}{" "}
                              </p>
                              <div
                                className="flex gap-[2px]"
                                style={
                                  font_colour != null
                                    ? { color: font_colour }
                                    : {}
                                }
                              >
                                {timeLeft.days > 0 && (
                                  <>
                                    <div
                                      className="countDown-box"
                                      style={
                                        bkg_colour != null
                                          ? { background: bkg_colour }
                                          : {}
                                      }
                                    >
                                      <span className="text-body-text-2-b">
                                        {String(timeLeft.days).padStart(2, "0")}
                                      </span>
                                      <p className="text-minor-text-mb-1-sb">
                                        DD
                                      </p>
                                    </div>
                                    <div
                                      className="countDown-box"
                                      style={
                                        bkg_colour != null
                                          ? { background: bkg_colour }
                                          : {}
                                      }
                                    >
                                      <span className="text-body-text-2-b">
                                        {String(timeLeft.hours).padStart(
                                          2,
                                          "0",
                                        )}
                                      </span>
                                      <p className="text-minor-text-mb-1-sb">
                                        HH
                                      </p>
                                    </div>
                                  </>
                                )}
                                {timeLeft.days === 0 && timeLeft.hours > 0 && (
                                  <>
                                    <div
                                      className="countDown-box"
                                      style={
                                        bkg_colour != null
                                          ? { background: bkg_colour }
                                          : {}
                                      }
                                    >
                                      <span className="text-body-text-2-b">
                                        {String(timeLeft.hours).padStart(
                                          2,
                                          "0",
                                        )}
                                      </span>
                                      <p className="text-minor-text-mb-1-sb">
                                        HH
                                      </p>
                                    </div>
                                    <div
                                      className="countDown-box"
                                      style={
                                        bkg_colour != null
                                          ? { background: bkg_colour }
                                          : {}
                                      }
                                    >
                                      <span className="text-body-text-2-b">
                                        {String(timeLeft.minutes).padStart(
                                          2,
                                          "0",
                                        )}
                                      </span>
                                      <p className="text-minor-text-mb-1-sb">
                                        MM
                                      </p>
                                    </div>
                                  </>
                                )}
                                {timeLeft.days === 0 &&
                                  timeLeft.hours === 0 && (
                                    <>
                                      <div
                                        className="countDown-box"
                                        style={
                                          bkg_colour != null
                                            ? { background: bkg_colour }
                                            : {}
                                        }
                                      >
                                        <span className="text-body-text-2-b">
                                          {String(timeLeft.minutes).padStart(
                                            2,
                                            "0",
                                          )}
                                        </span>
                                        <p className="text-minor-text-mb-1-sb">
                                          MM
                                        </p>
                                      </div>
                                      <div
                                        className="countDown-box"
                                        style={
                                          bkg_colour != null
                                            ? { background: bkg_colour }
                                            : {}
                                        }
                                      >
                                        <span className="text-body-text-2-b">
                                          {String(timeLeft.seconds).padStart(
                                            2,
                                            "0",
                                          )}
                                        </span>
                                        <p className="text-minor-text-mb-1-sb">
                                          SS
                                        </p>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </>
                          )}
                          {auto_apply_button && (
                            <Button
                              className="bg-primary-black text-tertiary-white rounded py-[7px] px-[10.4px] text-minor-text-1-b uppercase"
                              onClick={handleApplyCoupon}
                            >
                              {button_text || t("coupon_banner.apply_code")}
                            </Button>
                          )}
                        </div>
                      </div>
                      {terms && (
                        <p
                          className={`text-center w-full text-minor-text-1 xs:mt-2 mt-[6px] ${
                            font_colour == null ? `text-primary-black` : ""
                          }`}
                          style={
                            font_colour != null ? { color: font_colour } : {}
                          }
                        >
                          {terms}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="w-full justify-center">
                      <p
                        className={`text-center ${
                          !font_colour ? "text-primary-black" : ""
                        }`}
                        style={
                          font_colour != null ? { color: font_colour } : {}
                        }
                      >
                        {t("coupon_banner.use_code")}{" "}
                        <span
                          className={`text-body-text-3-b ${
                            font_colour == null ? `text-primary-black` : ""
                          }`}
                          style={
                            font_colour != null ? { color: font_colour } : {}
                          }
                        >
                          {coupon_code}
                        </span>
                      </p>
                      <p
                        className={`text-center w-full text-minor-text-1 ${
                          font_colour == null ? `text-primary-black` : ""
                        }`}
                        style={
                          font_colour != null ? { color: font_colour } : {}
                        }
                      >
                        {t("coupon_banner.code_auto_applied")}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="overlay-crossicon absolute top-1/2 -translate-y-1/2 right-[10px] xs:right-[60px]">
                <button type="button" onClick={handleClose}>
                  <CloseIcon
                    color={font_colour}
                    className="h-6 w-6 text-gray-500"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopAlertHeader;

import moment from "moment-timezone";
import { constants } from "../constants";
import { getProductListing } from "@/lib/services/product";
import { formatProductCategoriesGA4, formatProductPrice } from "../functions";
import { handleErrorLog } from "../error_logs";

export const createListId = (listName) => {
  return listName
    .toLowerCase()
    ?.replace(/\s+/g, "_")
    ?.replace(/[^\w_]/g, "")
    ?.replace(/_+/g, "_");
};

export const getNextDeliveryDate = (nextDelivery) => {
  switch (nextDelivery) {
    case "Today":
      return moment();
    case "Tomorrow":
      return moment().add(1, "days");
    default:
      // Assume the rest are day names (e.g., "Monday", "Tuesday")
      const deliveryDate = moment().day(nextDelivery);
      // If the chosen day is earlier in the week, move to the next week
      if (deliveryDate.isBefore(moment(), "day")) {
        deliveryDate.add(1, "week");
      }
      return deliveryDate;
  }
};

export const getUpdatedDeliveryDate = (holidays, nextDelivery, branch) => {
  let deliveryDate = getNextDeliveryDate(nextDelivery);
  const openOnSun = branch?.open_sunday === 1;
  const openOnSat = branch?.open_saturday === 1;
  let hasHoliday = holidays.some((h) => deliveryDate.isSame(h.date, "day"));
  while (hasHoliday) {
    deliveryDate.add(1, "days");
    if (!openOnSat && deliveryDate.clone().day() === 6) {
      deliveryDate.add(1, "days");
      if (!openOnSun) {
        deliveryDate.add(1, "days");
      }
    }

    if (!openOnSun && deliveryDate.clone().day() === 7) {
      deliveryDate.add(1, "days");
    }
    hasHoliday = holidays.some((h) => deliveryDate.isSame(h.date, "day"));
  }
  return deliveryDate;
};

export const pageSummaryInfo = (currentPage, pageLimit, totalProducts) => {
  const toProduct = Math.min(pageLimit * currentPage, totalProducts);
  const startFrom = currentPage - 1;
  let info = {
    from: pageLimit * startFrom + 1,
    to: toProduct > totalProducts ? totalProducts : toProduct,
    total: totalProducts,
  };

  return info;
};

export const alertMessage = (t, category, is3BO) => {
  let message = t("messages.info.fitment_info_selection_3bo", {
    category: category?.name,
  });
  if (!is3BO) {
    message = t("messages.info.fitment_info_selection", {
      category: category?.name,
    });
  }
  return message;
};

export const updateProductInfo = (
  products,
  isBrandGrouped = false,
  branchStockData = {},
  bestSellerImg = "",
  recommendedImg = "",
) => {
  let group = {};
  products.forEach((product) => {
    const productTitle = product?.name;
    const metaData = branchStockData[product.sku] || {};
    const articleNo = product?.extraVariable?.art_nr || "";

    let index = articleNo;
    if (!isBrandGrouped) {
      index = articleNo ? articleNo + product?.uid : product?.uid;
    }

    if (!group[index]) {
      let smallImg = {};
      if (product?.small_image?.url) {
        smallImg = {
          ...product?.small_image,
          slug: product?.small_image?.label + " 1",
          position: 0,
        };
      }

      group[index] = {
        brands: [],
        small_image: smallImg,
        article_number: articleNo,
        product_title: productTitle,
        bestseller_img: bestSellerImg,
        branch: metaData?.branch || {},
        recommended_img: recommendedImg,
        description: product?.description || {},
        media_gallery: product?.media_gallery || [],
      };
    }

    const brands = product?.extraVariable?.brand_info || [];
    const brand = brands?.length > 0 ? brands[0] : {};
    const fitmentInfo = product?.extraVariable?.fitmentInfo || [];
    const minimumPrice = product?.price_range?.minimum_price || {};
    const isSelected = group[index]["brands"].length === 0;
    const percentOff = minimumPrice?.discount?.percent_off || 0;
    const amountOff = minimumPrice?.discount?.amount_off || 0;

    group[index]["brands"].push({
      ...brand,
      sku: product.sku,
      is_selected: isSelected,
      review: metaData?.review,
      product_id: product?.id,
      product_uid: product?.uid,
      url_key: product?.url_key,
      fitment_info: fitmentInfo,
      thumbnail: product?.thumbnail || {},
      special_price: product?.special_price,
      query_text: metaData?.query_text || "",
      best_seller: product?.best_seller || 0,
      is_recommended: product?.is_recommended,
      branch_stock: metaData?.branch_stock || 0,
      hub_stock: metaData?.hub_stock || 0,
      sold_in_pairs: product?.sold_in_pairs || 0,
      quantity: product?.extraVariable?.qty || 0,
      warranty_guaranteed_text:
        product?.extraVariable?.warranty_guaranteed_text || null,
      stop_start_compatible:
        product?.extraVariable?.stop_start_compatible || null,
      breadcrumbs: product?.extraVariable?.breadcrumbs || [],
      final_price_without_currency: minimumPrice?.final_price?.value,
      amountOff_without_currency: minimumPrice?.discount?.amount_off,
      specialCoupon_applied: product?.specialCoupon_applied || true,
      special_to_date: product?.special_to_date || "",
      special_from_date: product?.special_from_date || "",
      final_price: formatProductPrice(minimumPrice?.final_price || 0),
      showBtn: minimumPrice?.final_price?.value > 0,
      percent_off: percentOff > 9 ? `${percentOff}` : `0${percentOff}`,
      amountOff: formatProductPrice({
        ...minimumPrice?.regular_price,
        value: amountOff,
      }),
      regular_price: formatProductPrice(minimumPrice?.regular_price || 0),
      showStrikePrice:
        minimumPrice?.final_price?.value < minimumPrice?.regular_price?.value ||
        !!product?.special_price,
      currency: minimumPrice?.final_price?.currency || "",
      showDiscountedPrice: amountOff >= 5,
      product_title_with_brand: `${
        brand?.title ? brand?.title?.toUpperCase() + " - " : ""
      } ${productTitle}`,
      product_name_without_brand_name: productTitle,
    });
  });

  let updatedList = Object.values(group);
  if (isBrandGrouped) {
    updatedList = updatedList.map((p) => {
      let item = { ...p };
      let brandSelected = false;
      item.brands = item.brands.map((b) => {
        b.is_selected = false;
        if (!brandSelected && b.quantity > 0) {
          b.is_selected = true;
          brandSelected = true;
        }
        return b;
      });
      if (!brandSelected) {
        item.brands[0].is_selected = true;
      }
      return item;
    });
  }
  return updatedList;
};

export const getFromStorage = (key) => {
  const data = localStorage.getItem(key);
  try {
    if (data) return JSON.parse(data);
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { key },
      msg: "Error while fetching local storage data",
    });
  }
  return null;
};

export const setToStorage = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { key },
      msg: "Error while setting local storage data",
    });
  }
};

export const branchFormattedDetail = (branch) => {
  if (!branch) return null;
  return {
    name: branch?.name,
    type: branch?.type,
    email: branch?.email,
    is_hub: branch?.is_hub,
    address1: branch?.address1,
    address2: branch?.address2,
    address3: branch?.address3,
    address4: branch?.address4,
    store_id: branch?.store_id,
    postcode: branch?.postcode,
    latitude: branch?.latitude,
    distance: branch?.distance,
    branch_id: branch?.branch_id,
    hyperlink: branch?.hyperlink,
    longitude: branch?.longitude,
    telephone: branch?.telephone,
    closing_sun: branch?.closing_sun,
    closing_sat: branch?.closing_sat,
    open_sunday: branch?.open_sunday,
    opening_sun: branch?.opening_sun,
    opening_sat: branch?.opening_sat,
    holiday_info: branch?.holiday_info,
    open_saturday: branch?.open_saturday,
    closing_mon_fri: branch?.closing_mon_fri,
    opening_mon_fri: branch?.opening_mon_fri,
    title: `${branch?.type} ${branch?.name}`,
  };
};

const isBefore = (dateObj1, dateObj2) => {
  return moment(dateObj1.format("YYYY-MM-DD HH:mm:ss")).isBefore(
    moment(dateObj2.format("YYYY-MM-DD HH:mm:ss")),
  );
};

export const isDayIsSunOrSat = (dateObj) => {
  const numericDay = dateObj.clone().day();
  return {
    isThursday: numericDay === 4,
    isFriday: numericDay === 5,
    isSunday: numericDay === 0,
    isSaturday: numericDay === 6,
  };
};

const checkIsBefore30Min = (now, closingTime, format) => {
  return isBefore(now, moment(closingTime, format).subtract(30, "minutes"));
};

const checkSundayOpen = ({ openOnSun, now, branch, acceptedFormat }) => {
  let res = {};
  if (openOnSun) {
    res.isBefore30Min = checkIsBefore30Min(
      now,
      branch?.closing_sun,
      acceptedFormat,
    );

    if (res.isBefore30Min) {
      res.branchOpenTime = moment(branch?.opening_sun, acceptedFormat);
      res.isBeforeOpeningTime = isBefore(now, res.branchOpenTime);
    } else {
      res.nextDeliveryDay = "Monday";
    }
  } else {
    res.nextDeliveryDay = "Monday";
  }
  return res;
};

const checkSaturdayOpen = ({
  openOnSat,
  openOnSun,
  now,
  branch,
  acceptedFormat,
}) => {
  let res = {};
  if (openOnSat) {
    res.isBefore30Min = checkIsBefore30Min(
      now,
      branch?.closing_sat,
      acceptedFormat,
    );
    if (res.isBefore30Min) {
      res.branchOpenTime = moment(branch?.opening_sat, acceptedFormat);
      res.isBeforeOpeningTime = isBefore(now, res.branchOpenTime);
    } else {
      res = checkSundayOpen({ openOnSun, now, branch, acceptedFormat });
    }
  } else {
    res = checkSundayOpen({ openOnSun, now, branch, acceptedFormat });
  }
  return res;
};

const checkDayIsSaturdayOrSunday = (deliveryDate, branch, now) => {
  const acceptedFormat = constants.time_format;
  const { isSunday, isSaturday } = isDayIsSunOrSat(deliveryDate);

  const openOnSun = branch?.open_sunday === 1;
  const openOnSat = branch?.open_saturday === 1;

  let response = {};
  if (isSunday) {
    response = checkSundayOpen({ openOnSun, now, branch, acceptedFormat });
  } else if (isSaturday) {
    response = checkSaturdayOpen({
      openOnSat,
      openOnSun,
      now,
      branch,
      acceptedFormat,
    });
  }
  return response;
};

export const checkWithBanchStock = ({
  now,
  branch,
  tzString,
  isFriday,
  isSunday,
  isSaturday,
}) => {
  const openOnSun = branch?.open_sunday === 1;
  const openOnSat = branch?.open_saturday === 1;
  const acceptedFormat = constants.time_format;

  let isBefore30Min = false;
  let nextDeliveryDay = "Today";
  let branchOpenTime = moment(branch?.opening_mon_fri, acceptedFormat);
  let isBeforeOpeningTime = isBefore(now, branchOpenTime);

  try {
    if (isSunday || isSaturday) {
      const res = checkDayIsSaturdayOrSunday(now, branch, now);
      isBefore30Min = res?.isBefore30Min || isBefore30Min;
      branchOpenTime = res?.branchOpenTime || branchOpenTime;
      nextDeliveryDay = res?.nextDeliveryDay || nextDeliveryDay;
      isBeforeOpeningTime = res?.isBeforeOpeningTime || isBeforeOpeningTime;
    } else {
      isBefore30Min = checkIsBefore30Min(
        now,
        branch?.closing_mon_fri,
        acceptedFormat,
      );

      if (!isBefore30Min) {
        if (isFriday) {
          const dayRes = checkSaturdayOpen({
            openOnSat,
            openOnSun,
            now,
            branch,
            acceptedFormat,
          });

          if (openOnSat || openOnSun) {
            isBefore30Min = dayRes?.isBefore30Min || isBefore30Min;
            branchOpenTime = dayRes?.branchOpenTime || branchOpenTime;
            nextDeliveryDay = dayRes?.nextDeliveryDay || nextDeliveryDay;
            isBeforeOpeningTime =
              dayRes?.isBeforeOpeningTime || isBeforeOpeningTime;
          } else {
            nextDeliveryDay = "Monday";
          }
        } else {
          nextDeliveryDay = "Tomorrow";
        }
      }
    }

    const holidays = branch?.holiday_info || [];
    const nextDeliveryDate = getUpdatedDeliveryDate(
      holidays,
      nextDeliveryDay,
      branch,
    );
    const res = isDayIsSunOrSat(nextDeliveryDate);
    let isDaySunOrSat = res.isSaturday || res.isSunday;
    if (isDaySunOrSat) {
      const resData = checkDayIsSaturdayOrSunday(nextDeliveryDate, branch, now);
      isBefore30Min = resData?.isBefore30Min || isBefore30Min;
      branchOpenTime = resData?.branchOpenTime || branchOpenTime;
      nextDeliveryDay = resData?.nextDeliveryDay || nextDeliveryDay;
      isBeforeOpeningTime = resData?.isBeforeOpeningTime || isBeforeOpeningTime;
    } else {
      // format day of the week to today , tomorrow format
      const today = moment().tz(tzString);
      const tomorrow = today.clone().add(1, "days");

      // update delivery day to today
      if (nextDeliveryDate.isSame(today, "day")) {
        nextDeliveryDay = "Today";
      } else if (nextDeliveryDate.isSame(tomorrow, "day")) {
        nextDeliveryDay = "Tomorrow";
      } else {
        nextDeliveryDay = nextDeliveryDate.format("dddd");
      }
    }

    return {
      isBefore30Min,
      nextDeliveryDay,
      isBeforeOpeningTime,
      branchOpenTime: branchOpenTime.format("h:mma"),
    };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const checkWithHubStock = ({
  now,
  branch,
  tzString,
  isFriday,
  isSunday,
  isThursday,
  isSaturday,
}) => {
  const dayAfterTomorrow = moment().tz(tzString).add(2, "days").format("dddd");
  const isAfter2PM = now.isAfter(
    moment.tz(tzString).set({ hour: 14, minute: 0, second: 0, millisecond: 0 }),
  );

  let nextDeliveryDay = "Tomorrow";
  let branchOpenTime = "10:00am";

  try {
    if (isSunday || isSaturday) {
      nextDeliveryDay = "Tuesday";
    } else {
      if (isAfter2PM) {
        if (isFriday) {
          nextDeliveryDay = "Tuesday";
        } else if (isThursday) {
          nextDeliveryDay = "Monday";
        } else {
          nextDeliveryDay = dayAfterTomorrow;
        }
      } else if (isFriday) {
        nextDeliveryDay = "Monday";
      }
    }

    const holidays = branch?.holiday_info || [];
    const updatedDeliveryDay = getUpdatedDeliveryDate(
      holidays,
      nextDeliveryDay,
    ).format("dddd");

    return { isAfter2PM, nextDeliveryDay: updatedDeliveryDay, branchOpenTime };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getEncodedQueryString = (queryParams = {}) => {
  let queryString = "";
  const firstIndex = Object.keys(queryParams)?.at(0);
  for (const key in queryParams) {
    if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
      const value = encodeURIComponent(queryParams[key])?.replace(/%20/g, "+");
      if (key !== firstIndex) {
        queryString += `&`;
      }
      queryString += `${key}=${value}`;
    }
  }
  return queryString;
};

export const filterSubCategories = (
  dataArr,
  vehicleInfo,
  showForthLevelView = false,
) => {
  if (vehicleInfo) {
    let filteredArr = [];
    let componentIds = [];
    let filterComponent = false;

    if (vehicleInfo?.vehicle && !vehicleInfo?.isMvl) {
      filterComponent = true;
      componentIds = (vehicleInfo?.vehicle?.components || [])?.map((c) =>
        Number(c?.id),
      );
    }

    if (showForthLevelView) {
      dataArr.forEach((cat) => {
        if (filterComponent) {
          if (componentIds.includes(Number(cat.component_id))) {
            filteredArr.push(cat);
          }
        } else {
          filteredArr.push(cat);
        }
      });
    } else {
      dataArr.forEach((cat) => {
        const catObj = { name: cat.name, url_path: cat.url_path, children: [] };
        cat.children.forEach((subCat) => {
          if (filterComponent) {
            if (componentIds.includes(Number(subCat.component_id))) {
              catObj.children.push(subCat);
            }
          } else {
            catObj.children.push(subCat);
          }
        });
        if (catObj.children.length > 0) filteredArr.push(catObj);
      });
    }

    return filteredArr;
  }
  return dataArr;
};

export const formatProductListing = (products) => {
  const formattedProducts = products.map((product, i) => {
    const brands = product?.brands || [];
    const brand = brands.find((b) => b.is_selected);
    return {
      item_id: brand?.sku,
      item_name:
        brand?.product_name_without_brand_name || product?.product_title,
      discount: brand?.amountOff_without_currency,
      item_brand: brand?.title,
      price: brand?.final_price_without_currency,
      quantity: brand?.quantity || 1,
      index: i,
      ...(Number(brand?.amountOff_without_currency) &&
        !brand?.specialCoupon_applied && {
          coupon: Cookies.get("specialCoupon"),
        }),
      ...formatProductCategoriesGA4(brand?.breadcrumbs),
    };
  });

  return formattedProducts;
};

export const createItemListPayload = (
  products,
  listName,
  isFormatted = false,
) => {
  let obj = {
    item_list_id: createListId(listName),
    item_list_name: listName,
    items: isFormatted ? products : formatProductListing(products),
  };

  return obj;
};

export const getFilters = (aggregations = []) => {
  let res = [];
  if (aggregations && Array.isArray(aggregations)) {
    res = aggregations.filter((a) => a?.attribute_code !== "category_uid");
  }
  return res;
};

export const getSubCategoryItems = async ({
  page,
  sCoupon,
  pageSize,
  topCategory,
  sortingOptions,
}) => {
  const defaultSortOrder = sortingOptions[0];
  let info = {
    total: 0,
    data: [],
    filter_options: [],
    category: topCategory,
    sort_order: defaultSortOrder,
  };

  if (info.category?.id) {
    const res = await getProductListing({
      page,
      pageSize,
      specialCoupon: sCoupon,
      sortOrder: info.sort_order,
      categoryid: info.category.id,
    });

    if (!res?.error) {
      info.total = res?.data?.products?.total_count || 0;
      info.data = updateProductInfo(res?.data?.products?.items || []);
      info.filter_options = getFilters(res?.data?.products?.aggregations);
    }
  }
  return info;
};

export const updateMetaInfo = (p) => {
  let title = document.title;
  let descTag = document.querySelector("meta[name='description']");
  let desc = descTag?.content;
  const pageRegex = / - Page \d+/g;
  if (p > 1) {
    title = title.match(pageRegex)
      ? title.replace(pageRegex, ` - Page ${p}`)
      : title.replace(" |", ` - Page ${p} |`);

    desc = desc.replace(pageRegex, "") + ` - Page ${p}`;
  } else {
    title = title.replace(pageRegex, "");
    desc = desc.replace(pageRegex, "");
  }

  document.title = title;
  if (descTag) {
    descTag.setAttribute("content", desc);
  }
  const ogTitle = document.querySelector("meta[property='og:title']");
  if (ogTitle) {
    ogTitle.setAttribute("content", title);
  }
  const ogDesc = document.querySelector("meta[property='og:description']");
  if (ogDesc) {
    ogDesc.setAttribute("content", desc);
  }
  const tTitle = document.querySelector("meta[name='twitter:title']");
  if (tTitle) {
    tTitle.setAttribute("content", title);
  }
  const tDesc = document.querySelector("meta[name='twitter:description']");
  if (tDesc) {
    tDesc.setAttribute("content", desc);
  }
};

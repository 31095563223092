"use client";

import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import PLPBanner from "../PLPBanner";
import NoResult from "@/components/misc/NoResult";
import { constants, getPageType } from "@/lib/utils";
import EmptyResult from "@/components/misc/EmptyResult";
import useProductListing from "@/lib/hooks/useProductListing";
import { useCallback, useEffect, useMemo, useRef } from "react";
import CustomPagination from "@/components/common/CustomPagination";
import { no_products_found, viewItemListAnalytics } from "@/lib/gtm";
import ProductListingCard from "@/components/common/card/ProductListingCard";
import {
  alertMessage,
  pageSummaryInfo,
  createItemListPayload,
} from "@/lib/utils/helpers/plp";
import "@/assets/css/pages/basket.css";
import "@/assets/css/pages/product-description.css";

// Dynamic importing components
const Loader = dynamic(() => import("@/components/common/Loader"), {
  ssr: false,
});
const SelectionBox = dynamic(() => import("../SelectionBox"), {
  ssr: false,
});
const Modal = dynamic(() => import("@/components/common/shared/Modal"), {
  ssr: false,
});
const AlertBox = dynamic(() => import("@/components/common/shared/AlertBox"), {
  ssr: false,
});
const VehicleFitSelection = dynamic(
  () => import("@/components/common/shared/VehicleFitSelection"),
  {
    ssr: false,
  },
);

const BranchSelectionModal = dynamic(
  () => import("@/components/common/shared/BranchSelectionModal"),
  {
    ssr: false,
  },
);

export default function ProductListingDetail({
  is3BO,
  items,
  assets,
  category,
  pageSize,
  currentURL,
  isCarParts,
  initialPage,
  searchParams,
  bannerImages,
  hasManualLook,
  sortingOptions,
  selectedVehicle,
  pageSizeOptions,
}) {
  const {
    data: {
      vehicle,
      filters,
      products,
      sortOrder,
      pageLimit,
      isFetching,
      queryParam,
      branchList,
      currentPage,
      fitmentInfo,
      filterOptions,
      totalProducts,
      selectedProduct,
      branchModalOpen,
      fitmentModalOpen,
      vehicleSpecificSKU,
      appInitailized,
      showVehicleSpecific,
    },
    methods: {
      t,
      searchBranches,
      handlePageChange,
      toggleFitmentInfo,
      toggleBranchModal,
      handleBrandChange,
      handleAddToTrolley,
      handlePageSelection,
      handleFilterSelection,
      handleVehicleSpecific,
      handleSortingSelection,
      handleBranchSelection,
      onBranchRadioSelection,
    },
  } = useProductListing({
    pageSize,
    searchParams,
    hasManualLook,
    categoryid: category?.id,
    componentId: category?.component_id,
    initial: {
      is3BO,
      isCarParts,
      page: initialPage,
      products: items.data,
      product_total: items.total,
      sort_order: items.sortingOrder,
      selected_vehicle: selectedVehicle,
      filter_options: items.filterOptions,
    },
  });

  const isFirstRender = useRef(true);
  const pageSummary = useMemo(() => {
    let summary = {
      is_3bo_page: true,
      has_products: totalProducts > 0,
      show_fitment_alert: totalProducts > 0,
      text: t(
        "filters.result_summary",
        pageSummaryInfo(currentPage, pageLimit, totalProducts),
      ),
    };

    if (isCarParts && !is3BO) {
      const productCount = products.length;
      summary.is_3bo_page = false;
      summary.text = t("filters.result_summary_2", {
        total: productCount,
        count: productCount,
      });
    }

    if (isCarParts) {
      summary.show_fitment_alert = products.length > 1;
    }

    return summary;
  }, [currentPage, pageLimit, totalProducts, isCarParts, is3BO, products]);

  const vehicleSelectedOrNot = Cookies.get(constants.cookies.selectedVehicle);
  const renderBanner = useCallback(() => {
    return (
      <div className="sm:py-6 xs:py-5 py-0">
        <PLPBanner {...bannerImages} />
      </div>
    );
  }, []);

  useEffect(() => {
    if (appInitailized && !isFetching && totalProducts === 0) {
      no_products_found({
        page_type: getPageType(),
        ...(showVehicleSpecific?.current &&
          vehicleSelectedOrNot && {
            manufacturer: vehicle?.vehicle?.make,
            model: vehicle?.vehicle?.model,
            ...(vehicle?.vehicle?.engineSize && {
              engine: vehicle?.vehicle?.engineSize,
            }),
            years:
              vehicle?.vehicle?.modelVersion?.match(/\d{4}/)[0] ||
              vehicle?.vehicle?.dateFirstRegistered?.match(/^(\d{4})/)[0],
          }),
      });
    }
  }, [appInitailized, totalProducts, showVehicleSpecific?.current]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // Skip the effect on the first render
    }

    if (appInitailized && pageSummary?.has_products) {
      viewItemListAnalytics(createItemListPayload(products, "Product Listing"));
    }
  }, [products, appInitailized]);

  if (
    (queryParam.has("make") && queryParam.has("model")) ||
    (isCarParts && !vehicle)
  ) {
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            {bannerImages && renderBanner()}
            <EmptyResult />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-12/12 flex flex-col">
      {bannerImages && renderBanner()}

      {pageSummary.has_products && (
        <SelectionBox
          is3BO={is3BO}
          filters={filters}
          pageSize={pageLimit}
          sortOrder={sortOrder}
          isCarParts={isCarParts}
          categoryId={category?.id}
          totalItems={totalProducts}
          sortOptions={sortingOptions}
          filterOptions={filterOptions}
          pageSizeOptions={pageSizeOptions}
          handlePageSelection={handlePageSelection}
          handleFilterSelection={handleFilterSelection}
          handleSortingSelection={handleSortingSelection}
        />
      )}

      <div className="container" id="product-list">
        {!isFetching && pageSummary.show_fitment_alert && (
          <div className="row xs:mt-12">
            <div className="col">
              <AlertBox message={alertMessage(t, category, is3BO)} />
            </div>
          </div>
        )}

        {is3BO && (
          <div className="row mt-12">
            <div className="col w-12/12 flex lg:flex-row flex-col justify-between">
              <VehicleFitSelection
                t={t}
                onSelectionChange={handleVehicleSpecific}
                showVehicleSpecific={showVehicleSpecific?.current}
              />
            </div>
          </div>
        )}

        {pageSummary.has_products ? (
          <>
            <div className="row xs:mb-24 mt-10 mb-12">
              <div className="col w-12/12">
                <p className="text-primary-black-600 xs:text-body-text-3 text-body-text-3-m my-auto block w-12/12 xs:mb-0 mb-2">
                  {pageSummary.text}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col w-12/12">
                {products?.map((product, i) => (
                  <div
                    className="plp-box"
                    key={`${product.article_number}${i}`}
                  >
                    <ProductListingCard
                      t={t}
                      is3BO={is3BO}
                      loop_index={i}
                      assets={assets}
                      product={product}
                      vehicle={vehicle}
                      showFitmentInfo={toggleFitmentInfo}
                      showBranchModal={toggleBranchModal}
                      handleBrandChange={handleBrandChange}
                      handleAddToTrolley={handleAddToTrolley}
                      vehicleSpecificSKU={vehicleSpecificSKU}
                      showVehicleSpecific={showVehicleSpecific?.current}
                    />
                  </div>
                ))}
              </div>
            </div>
            {!isFetching && (
              <div className="row">
                <div className="col plp-pagination-box w-12/12">
                  {(!isCarParts || is3BO) && (
                    <>
                      <p className="text-primary-black-600 xs:text-body-text-3 text-body-text-3-m my-auto block w-12/12 xs:mb-0 mb-2">
                        {pageSummary.text}
                      </p>
                      <CustomPagination
                        scrollToTop
                        page={currentPage}
                        total={totalProducts}
                        currentURL={currentURL}
                        itemsPerPage={pageLimit}
                        setPage={handlePageChange}
                        searchParams={searchParams}
                      />
                    </>
                  )}
                  {!pageSummary.is_3bo_page && (
                    <p className="text-primary-black-600 xs:text-body-text-3 text-body-text-3-m my-auto block w-12/12 xs:mb-0 mb-2">
                      {pageSummary.text}
                    </p>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className={`row xs:mt-5 mt-12 ${isFetching ? "hidden" : ""}`}>
            <div className="col">
              <NoResult
                base_msg={t("messages.no_results.base_product")}
                msg={t("messages.no_results.product")}
              />
            </div>
          </div>
        )}

        <Modal
          isFitment
          open={fitmentModalOpen}
          title="Fitment Info"
          onCancel={toggleFitmentInfo}
        >
          {fitmentInfo.length > 0 ? (
            <div className="fitment-info">
              <div className="flex flex-col">
                {fitmentInfo?.map((row, i) => (
                  <div
                    key={`product-fitmet-info-${i}`}
                    className={`flex flex-row p-2 ${
                      i % 2 === 0 && "bg-primary-blue-50"
                    }`}
                  >
                    <div className="flex flex-col w-6/12">
                      <p className="fitment-info-label">{row.label}</p>
                    </div>
                    <div className="flex flex-col w-6/12">
                      <p className="fitment-info-value">{row.value}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </Modal>

        <BranchSelectionModal
          t={t}
          vehicle={vehicle}
          branchList={branchList}
          branchModalOpen={branchModalOpen}
          selectedProduct={selectedProduct}
          handleCheckStock={searchBranches}
          toggleBranchModal={toggleBranchModal}
          handleBranchSelection={handleBranchSelection}
          onBranchRadioSelection={onBranchRadioSelection}
        />
      </div>
    </div>
  );
}

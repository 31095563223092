import Cookies from "js-cookie";
import { useEffect } from "react";
import { useParams } from "next/navigation";
import { getDelocalizedPathname, i18nConfig } from "@/i18n/settings";
import {
  routes,
  notToShowAfterLogin,
  notToShowBeforeLogin,
} from "../utils/routes";

//Safety Cusion in case middleware fails
export const useRedirectToHome = ({ router, pathname, cookieKeys }) => {
  const { locale } = useParams();

  useEffect(() => {
    const cookieLocale =
      Cookies.get(cookieKeys.locale) || i18nConfig.defaultLocale;
    const isAuthenticated = Cookies.get(cookieKeys.customerToken);
    const delocalizedPathname = getDelocalizedPathname(pathname);

    if (locale !== cookieLocale) {
      Cookies.set(cookieKeys.locale, locale, {
        sameSite: "strict",
        expires: 365,
        secure: true,
      });
    }

    if (isAuthenticated) {
      if (notToShowAfterLogin.includes(delocalizedPathname)) {
        router.replace("/");
      }
    } else if (notToShowBeforeLogin.includes(delocalizedPathname)) {
      router.replace(routes.signin);
    }
  }, [router, pathname, locale]);
};

"use client";

import Cookies from "js-cookie";
import { useEffect } from "react";
import { constants } from "@/lib/utils";
import { useAppDispatch } from "@/lib/store";
import { updateVehicle } from "@/lib/store/reducers/vehicle";

const RemoveVehicleCookies = ({
  searchParams = null,
  removeVehicle = false,
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (searchParams && (!searchParams?.make || !searchParams?.model)) return;
    if (!removeVehicle && Cookies.get(constants.cookies.manual_lookup)) return;
    Cookies.remove(constants.cookies.new_vehicle);
    Cookies.remove(constants.cookies.manual_lookup);
    Cookies.remove(constants.cookies.selectedVehicle);
    if (removeVehicle) {
      dispatch(updateVehicle(null));
    }
  }, []);
};

export default RemoveVehicleCookies;

import { keys } from "../utils/keys";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useAppSelector } from "@/lib/store";
import { useRouter } from "next-nprogress-bar";
import { updateBreadcrumb } from "../store/reducers/globalAssets";

const HOME = { title: "Home", url: "/" };

export const usePageBreadcrumb = ({ pathArr = [] }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { breadcrumbs } = useAppSelector((state) => state.globalAssets);

  const updateBreadCrumbUrls = (breadcrumbArr = []) => {
    return breadcrumbArr?.map((b) => {
      let item = { ...b };
      if (b?.url && !b.url.includes(keys.general.websiteUrl)) {
        item.url = keys.general.websiteUrl + item.url;
      }
      return item;
    });
  };

  const [breadcrumbPaths, setBreadcrumbPaths] = useState(
    updateBreadCrumbUrls([HOME, ...pathArr]),
  );

  useEffect(() => {
    if (pathArr.length) {
      setBreadcrumbPaths(updateBreadCrumbUrls([HOME, ...pathArr]));
    }
  }, [pathArr]);

  useEffect(() => {
    if (breadcrumbs.length) {
      setBreadcrumbPaths(updateBreadCrumbUrls([HOME, ...breadcrumbs]));
      dispatch(updateBreadcrumb({ breadcrumbs: [] }));
    }
  }, [breadcrumbs]);

  return {
    methods: { router },
    data: { breadcrumbPaths },
  };
};

"use client";

import Link from "next/link";
import { useMemo } from "react";
import classNames from "classnames";
import { isSSR, keys, scrollToElement } from "@/lib/utils";
import { DOTS, usePagination } from "@/lib/hooks/usePagination";
import { updateMetaInfo } from "@/lib/utils/helpers/plp";

const NavLink = ({
  label,
  active = false,
  disabled = false,
  className = "",
  ...attributes
}) => {
  return (
    <li
      className={classNames(`pagination-box flex-center ${className}`, {
        disabled: disabled,
        active: active,
      })}
    >
      {label === DOTS ? (
        <span className="cursor-pointer">{label}</span>
      ) : (
        <Link prefetch={false} {...attributes}>
          {label}
        </Link>
      )}
    </li>
  );
};

export default function CustomPagination({
  page,
  total,
  setPage,
  currentURL,
  itemsPerPage,
  searchParams = "",
  scrollToTop = false,
}) {
  const serverSide = isSSR();
  const pageCount = useMemo(
    () => Math.ceil(total / itemsPerPage),
    [total, itemsPerPage],
  );
  const {
    data: { queryParams, paginationRange },
    methods: { t, handleActiveLink },
  } = usePagination({
    currentPage: page,
    totalPageCount: pageCount,
  });

  const isFirstPage = page <= 1;
  const isLastPage = page >= pageCount;

  const getAriaAttributes = (label, isDisable) => {
    return {
      "aria-label": label,
      "aria-disabled": isDisable,
    };
  };

  const paginationLinkURL = (pageNo = "") => {
    let queryObj = {};
    if (serverSide) {
      queryObj = { ...searchParams, page: pageNo };
    } else {
      for (const [key, value] of queryParams.entries()) {
        queryObj[key] = key === "page" ? pageNo : value;
      }
      if (!queryObj?.page) {
        queryObj["page"] = pageNo;
      }
    }

    const urlSchema = new URL(currentURL);
    const queryString = new URLSearchParams(queryObj).toString();
    const urlArr = [keys.general.websiteUrl + urlSchema.pathname];
    if (pageNo > 1 && queryString) {
      urlArr.push(queryString);
    }

    return urlArr.join("?");
  };



  const updateCanonicalTag = (p) => {
    updateMetaInfo(p)

    const tagURL = p > 1 ? `${currentURL}?page=${p}` : currentURL;
    const existingLink = document.querySelector("link[rel='canonical']");
    const ogURL = document.querySelector("meta[property='og:url']");
    if (ogURL) {
      ogURL.setAttribute("content", tagURL);
    }
    if (existingLink) {
      existingLink.href = tagURL; // Update existing canonical tag
    } else {
      // Create and append new canonical tag if not present
      const link = document.createElement("link");
      link.rel = "canonical";
      link.href = tagURL;
      document.head.appendChild(link);
    }
  };

  const handlePageClick = (e, p) => {
    e.preventDefault();
    setTimeout(() => {
      const htmlEl = document.querySelector("html");
      if (htmlEl) {
        htmlEl.classList.remove("nprogress-busy");
      }
    }, 0);

    if (page === p) {
      handleActiveLink();
      return;
    }

    setPage(p);
    if (scrollToTop) {
      scrollToElement("product-list");
    }
    updateCanonicalTag(p);
  };

  return (
    <div className="pagination-container">
      {pageCount > 0 && (
        <span className="total-page whitespace-nowrap">
          {t("paginations.page_summary", { page, total: pageCount })}
        </span>
      )}

      <ul className="flex ps-0" role="navigation" aria-label="Pagination">
        <NavLink
          label="<"
          rel="prev"
          role="button"
          className="prev"
          disabled={isFirstPage}
          tabIndex={isFirstPage ? -1 : 0}
          href={paginationLinkURL(isFirstPage ? "" : page - 1)}
          onClick={(e) => handlePageClick(e, isFirstPage ? 1 : page - 1)}
          {...getAriaAttributes("Previous page", isFirstPage)}
        />

        {paginationRange?.map((pageNumber, i) => {
          const isActive = page === pageNumber;
          let linkProps = { "aria-label": `Page ${pageNumber}` };
          if (isActive) {
            linkProps["aria-current"] = "page";
            linkProps["aria-label"] += " is your current page";
          }

          return (
            <NavLink
              active={isActive}
              label={pageNumber}
              tabIndex={isActive ? -1 : 0}
              href={paginationLinkURL(pageNumber)}
              key={`pagination_link_${pageNumber}_${i}`}
              onClick={(e) => handlePageClick(e, pageNumber)}
              {...linkProps}
            />
          );
        })}

        <NavLink
          label=">"
          rel="next"
          role="button"
          className="next"
          disabled={isLastPage}
          tabIndex={isLastPage ? -1 : 0}
          onClick={(e) => handlePageClick(e, page + 1)}
          href={paginationLinkURL(isLastPage ? pageCount : page + 1)}
          {...getAriaAttributes("Next page", isLastPage)}
        />
      </ul>
    </div>
  );
}

import axios from "axios";
import Cookies from "js-cookie";
import { logout } from "../gtm";
import { keys } from "../utils/keys";
import { constants } from "../utils/constants";
import { getCustomerAxios } from "../utils/axios";
import { resetCart } from "../store/reducers/cart";
import { logoutUser } from "../store/reducers/customer";
import {
  getBackendURL,
  handleGraphqlResponse,
  removeCartFromStorage,
} from "../utils/functions";
import { updateBranch } from "../store/reducers/misc";
import { endCommonLoading, startCommonLoading } from "../store/reducers/uiState";

/**
 * @param {{ email: string, password: string }} payload
 */
export const signIn = async (payload) => {
  const { email, password } = payload;
  const axiosRes = await axios.post(getBackendURL(), {
    query: `mutation {
			generateCustomerToken(
				email: "${email}"
				password: "${password}"
			) {
				token
			}
		}`,
  });
  return axiosRes.data;
};

export const signUp = async (payload, headers) => {
  const { firstName, lastName, email, password, newsletter, garage } = payload;
  const axiosRes = await axios.post(
    getBackendURL(),
    {
      query: `mutation CreateCustomer($input: CustomerCreateInput!){
        createCustomer(input: $input) {
          customer {
            firstname
            lastname
            email
          }
        }
      }`,
      variables: {
        input: {
          firstname: firstName,
          lastname: lastName,
          email,
          password,
          is_subscribed: newsletter,
          garage,
        },
      },
    },
    {
      headers: {
        "X-ReCaptcha": headers.get("x-recaptcha"),       
      },
    });

  return axiosRes.data;
};


export const forgotPassword = async (payload) => {
  const { email, recaptchaToken } = payload;
  const axiosRes = await axios.post(keys.general.backendGraphqlUrl, {
    query: `mutation {
        requestPasswordResetEmail(
          email: "${email}"
        ) {
          success
          message
        }
      }
		  `,
  },
  {
    headers: {
      "X-ReCaptcha": recaptchaToken,
    },
  });
  return axiosRes.data;
};

export const createPassword = async (payload) => {
  const { customerId, password, token } = payload;
  const axiosRes = await axios.post(getBackendURL(), {
    query: `mutation {
      resetForgotPassword(
        customer_id: ${customerId},
        resetPasswordToken: "${token}",
        newPassword: "${password}"
      ) {
        success
        message
      }
    }`,
  });
  return axiosRes.data;
};

export const signOut = async () => {
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(
    keys.general.backendGraphqlUrl,
    {
      query: `mutation {
			revokeCustomerToken {
				result
			}
		}`,
    },
    {
      extraData: { isSignOut: true },
    },
  );
  return axiosRes.data;
};

/**
 *
 * @param {{
 *  dispatch: function,
 * 	onSuccess: function,
 * 	onError: function
 * }} options
 */
const clearStorage = (dispatch) => {
  Cookies.remove(constants.cookies.new_vehicle);
  Cookies.remove(constants.cookies.customerToken);
  Cookies.remove(constants.cookies.manual_lookup);
  Cookies.remove(constants.cookies.selectedVehicle);
  removeCartFromStorage();
  localStorage.removeItem(constants.localStorage.cartAddressId);
  localStorage.removeItem(constants.localStorage.selectedBranch);
  localStorage.removeItem(constants.localStorage.cncAlerts);
  localStorage.removeItem(constants.localStorage.isBranchSelected);
  dispatch(updateBranch(null));
  dispatch(resetCart());
  dispatch(logoutUser());
  logout();
};

export const handleLogout = async (options) => {
  const { dispatch, onSuccess } = options || {};
  dispatch(startCommonLoading());
  const logined = Cookies.get(constants.cookies.customerToken);
  if (logined) {
    await signOut();
  }
  clearStorage(dispatch);
  onSuccess?.();
  dispatch(endCommonLoading());
};

export const handleClearBrowserStorage = async (options) => {
  const { dispatch, onSuccess } = options || {};
  dispatch(startCommonLoading());
  clearStorage(dispatch);
  onSuccess?.();
  dispatch(endCommonLoading());
};

export const isTokenExpired = async (payload) => {
  const { id, token } = payload;
  const axiosRes = await axios.post(keys.general.graphqlUrl, {
    query: `query {
				checkResetPasswordTokenExpiration(customerId: ${id}, rpToken: "${token}") {
					is_valid
					message
				}
			}
			`,
  });
  return axiosRes.data;
};

export const changePassword = async (payload, serverSideToken = null) => {
  const { oldPassword, password } = payload;
  const customerAxios = await getCustomerAxios(serverSideToken);
  const axiosRes = await customerAxios.post(getBackendURL(), {
    query: `mutation {
      changeCustomerPassword(
        currentPassword: "${oldPassword}"
        newPassword: "${password}"
      ) {
        id
        email
      }
    }`,
  });
  return axiosRes.data;
};

export const loginAsCustomer = async (secret) => {
  try {
    const axiosRes = await axios.post(keys.general.backendGraphqlUrl, {
      query: `query GenerateCustomerTokenAsAdminRemote($secretKey: String!) {
    generateCustomerTokenAsAdminRemote(secret: $secretKey)
}`,
      variables: {
        secretKey: secret,
      },
    });
    const res = handleGraphqlResponse(axiosRes);
    return { token: res?.data?.generateCustomerTokenAsAdminRemote, else: null };
  } catch (error) {
    return { data: null, error };
  }
};

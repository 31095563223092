"use client";

import classNames from "classnames";
import { constants } from "@/lib/utils";
import ImageWithFallback from "../../ImageWithFallback";

const CustomRadio = ({
  name,
  price,
  brand,
  title,
  badge,
  checked,
  onChange,
  isRecommended = false,
}) => {
  return (
    <div
      className={classNames("custom-radio-button-container", {
        "border border-tertiary-black": checked,
      })}
    >
      <label className="custom-radio-button-box">
        <div className="flex justify-end mb-0">
          <input
            type="radio"
            name={name}
            checked={checked}
            onChange={onChange}
          />
          <span className="custom-radio-checkmark"></span>
          <div className="xs:text-body-text-2-sb text-body-text-3-b">
            {price}
          </div>
        </div>
        <div className="flex justify-between gap-[5px]">
          <ImageWithFallback
            width={60}
            height={20}
            src={brand}
            alt={title}
            size="small"
            type={constants.image_types.brand}
            className="w-auto xs:max-h-5 min-h-5 max-h-5 xs:max-w-[85px] max-w-[56px] object-contain object-left"
          />
          {badge && (
            <span className={`radio-badge capitalize ${badge || ""}`}>
              {badge}
            </span>
          )}
        </div>
      </label>
      {isRecommended && (
        <div className="verified-product">
          <ImageWithFallback
            width={100}
            height={15.6}
            alt="Recommended"
            className="max-h-[27px] object-contain"
            type={constants.image_types.brand_recommended}
          />
        </div>
      )}
    </div>
  );
};

export default CustomRadio;

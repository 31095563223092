"use client";

import Image from "next/image";
import Input from "../Input/input";
import { useSearchParams } from "next/navigation";
import { Fragment, useRef, useState } from "react";
import UkFlag from "@/assets/static/ukFlag.svg?url";
import Button from "@/components/common/shared/Button/button";
import ManualVehicleSelection from "./ManualVehicleSelection";
import { useVehicleSelectionController } from "@/lib/hooks/useVehicleSelectionController";

export default function CurrenVehicleBox({
  t,
  submitForm,
  selectedMake,
  selectedModel,
  hasManualLook,
  removeManualSelection,
}) {
  const {
    data: { control, optionsList },
    methods: {
      register,
      handleSubmit,
      searchVehicleFromMVL,
      handleDropdownSelection,
      getValues,
    },
  } = useVehicleSelectionController({
    t,
    selectedMake,
    hasManualLook,
    selectedModel,
    manualSearch: false,
    removeManualSelection,
  });

  const params = useSearchParams();
  // const isFromMakes = params?.get("ref") === "makeAndModel";

  const yearValue = getValues("year");
  const [isSecondSectionOpen, setSecondSectionOpen] = useState(false);
  const contentRef = useRef(null);

  // useEffect(() => {
  //   if (isFromMakes) {
  //     setSecondSectionOpen(true);
  //   }
  // }, [isFromMakes]);

  return (
    <div className="current-vehicle-box plp-curren-vehiucle-box">
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <div className="curent-vehicle-container">
          <div className="flex xl:flex-row flex-col gap-12 xl:max-w-[400px]">
            <div className="left-section">
              <div className="regBoxContainer">
                <div className="inputBox">
                  <span>
                    <Image width={54} height={54} alt="UK Flag" src={UkFlag} />
                  </span>
                  <Input
                    type="text"
                    name="reg"
                    {...register("reg")}
                    id="registration-input"
                    className="find-car-input capitalize"
                    placeholder={t("current_vehicle.registration_placeholder")}
                  />
                </div>
              </div>
              <Button type="submit" theme="green-btn">
                {t("button.find_parts")}
              </Button>
            </div>

            <span className="separator-text flex justify-center items-center italic">
              {t("current_vehicle.or")}
            </span>
          </div>

          <div
            className="xs:hidden flex justify-between items-center mvl-header"
            onClick={() => setSecondSectionOpen(!isSecondSectionOpen)}
          >
            <div className="w-full text-left text-body-text-3-sb py-3 bg-gray-100 font-bold">
              Select Your Vehicle
            </div>
            <svg
              className={isSecondSectionOpen ? "rotate-180" : ""}
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 9.16211L12 15.1621L18 9.16211"
                stroke="#002F6E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div
            className={`select-box-container flex xl:max-w-[calc(100%-412px)]  xs:!max-h-max transition-all duration-500 ${
              isSecondSectionOpen
                ? "max-h-max"
                : "xs:max-h-max max-h-0 xs:overflow-visible overflow-hidden"
            }`}
            style={{
              maxHeight: isSecondSectionOpen
                ? (contentRef?.current?.scrollHeight || 0) + 200
                : 0,
            }}
            ref={contentRef}
          >
            <ManualVehicleSelection
              name="make"
              control={control}
              options={optionsList.make}
              label={t("current_vehicle.make.title")}
              placeholder={t("current_vehicle.make.placeholder")}
              isDisable={!optionsList.make.length || hasManualLook}
              handleChange={handleDropdownSelection}
            />

            <ManualVehicleSelection
              name="model"
              control={control}
              options={optionsList.model}
              label={t("current_vehicle.model.title")}
              placeholder={t("current_vehicle.model.placeholder")}
              isDisable={!optionsList.model.length || hasManualLook}
              handleChange={handleDropdownSelection}
            />

            <ManualVehicleSelection
              name="year"
              label={t("current_vehicle.year.title")}
              control={control}
              options={optionsList.year}
              placeholder={t("current_vehicle.year.placeholder")}
              isDisable={!optionsList.year.length}
              handleChange={handleDropdownSelection}
            />

            {(!yearValue || (yearValue && !!optionsList.engine.length)) && (
              <ManualVehicleSelection
                name="engine"
                label={t("current_vehicle.engine.title")}
                control={control}
                placeholder={t("current_vehicle.engine.placeholder")}
                options={optionsList.engine}
                isDisable={!optionsList.engine.length}
                handleChange={handleDropdownSelection}
              />
            )}

            {optionsList.body?.length > 0 && (
              <Fragment>
                <ManualVehicleSelection
                  name="body"
                  label={t("current_vehicle.body.title")}
                  control={control}
                  placeholder={t("current_vehicle.body.placeholder")}
                  options={optionsList.body}
                  handleChange={handleDropdownSelection}
                />
                {optionsList.kw_hp.length > 0 && (
                  <ManualVehicleSelection
                    name="kw_hp"
                    label={t("current_vehicle.kw_hp.title")}
                    control={control}
                    placeholder={t("current_vehicle.kw_hp.placeholder")}
                    options={optionsList.kw_hp}
                    handleChange={searchVehicleFromMVL}
                  />
                )}
              </Fragment>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

"use client";

import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "@/i18n/client";
import { useSearchParams } from "next/navigation";
import { endCommonLoading } from "../store/reducers/uiState";

export const DOTS = "...";
const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ currentPage, totalPageCount }) => {
  const dispacth = useDispatch();
  const queryParams = useSearchParams();
  const { t } = useTranslation(["common"]);

  const handleActiveLink = () => {
    dispacth(endCommonLoading());
  };

  const paginationRange = useMemo(() => {
    // Pages count is determined as siblings + firstPage + lastPage + currentPage + 2 * DOTS
    const siblings = 1;
    const totalPage = siblings + 5;

    // If the number of pages is less than the page numbers we want to show in our paginationComponent,
    // we return the range [1..totalPage]
    if (totalPage >= totalPageCount) return range(1, totalPageCount);

    const leftIndex = Math.max(currentPage - siblings, 1);
    const rightIndex = Math.min(currentPage + siblings, totalPageCount);

    // We do not want to show dots if there is only one position left after/before the left/right page count
    // as that would lead to a change if our Pagination component size which we do not want
    const showLeftDots = leftIndex > 2;
    const showRightDots = rightIndex < totalPageCount - 2;
    if (!showLeftDots && showRightDots) {
      return [
        ...range(1, 4),
        DOTS,
        ...range(totalPageCount - 1, totalPageCount),
      ];
    }

    if (showLeftDots && !showRightDots) {
      return [
        ...range(1, 2),
        DOTS,
        ...range(totalPageCount - 3, totalPageCount),
      ];
    }

    if (showLeftDots && showRightDots) {
      const middleRange = range(leftIndex, rightIndex);
      return [1, DOTS, ...middleRange, DOTS, totalPageCount];
    }

    return [];
  }, [totalPageCount, currentPage]);

  return {
    data: { queryParams, paginationRange },
    methods: { t, handleActiveLink },
  };
};

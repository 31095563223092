"use client";

import "swiper/css";
import "swiper/css/pagination";
import Link from "next/link";
import Image from "next/image";
import CommonSwiper from "../../Slider";
import { constants } from "@/lib/utils";
import ProductList from "./ProductList";
import Breadcrumb from "../../Breadcrumbs";
import { Navigation } from "swiper/modules";
import { useTranslation } from "@/i18n/client";
import Cross from "@/assets/icon/crossBlack.svg?url";
import ErrorHandler from "@/components/misc/ErrorHandler";
import ArrowDown from "@/assets/icon/gsf-arrow_down.svg?url";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ImageWithFallback from "@/components/common/ImageWithFallback";
import PrevButton from "@/components/common/shared/Button/SwipperSliderBtns/prev";
import NextButton from "@/components/common/shared/Button/SwipperSliderBtns/next";
import "@/assets/css/pages/accessories.css";
import "@/assets/css/pages/tools.css";

export default function TopSection({
  page,
  pageSize,
  pageData,
  currentURL,
  topCategory,
  searchParams,
  sortingOptions,
}) {
  const errorResponse = useRef([]);
  const { children: subCategoryDetail } = topCategory;
  const { t } = useTranslation(["common"]);
  const [dropDown, setDropDown] = useState(false);
  const [currentPage, setCurrentPage] = useState(page);
  const [selectedCategory, setSelectedCategory] = useState(
    pageData?.category || {},
  );

  const breadcrumbs = useMemo(() => {
    const pathArr = [];
    if (selectedCategory?.breadcrumbs) {
      selectedCategory.breadcrumbs.forEach((b) => {
        pathArr.push({
          title: b.category_name,
          url: `/${b.category_url_path}`,
        });
      });
      pathArr.push({ title: selectedCategory.name });
    }

    return pathArr;
  }, [selectedCategory]);

  const items = useMemo(() => {
    let sortingOrder = pageData.sort_order;
    if (searchParams?.sort_by) {
      const direction = searchParams?.order || "asc";
      const sortedBy = sortingOptions.find(
        (sp) => sp.field === searchParams.sort_by && sp.direction === direction,
      );
      if (sortedBy) {
        sortingOrder = sortedBy;
      }
    }

    return {
      sortingOrder,
      data: pageData.data,
      total: pageData.total,
      filterOptions: pageData.filter_options,
    };
  }, [searchParams, sortingOptions]);

  const toggleDropdown = () => setDropDown(!dropDown);

  useEffect(() => {
    if (dropDown) {
      document.body.classList.add("sort-dropdown-bg");
    } else {
      document.body.classList.remove("sort-dropdown-bg");
    }
    return () => {
      document.body.classList.remove("sort-dropdown-bg");
    };
  }, [dropDown]);

  const handleSorting = () => {
    setDropDown(false);
  };

  const handleChangeCategory = (selected) => {
    setSelectedCategory(() => selected);
    setCurrentPage(1);
    setDropDown(false);
  };

  const sliderConfig = {
    breakpoints: {
      0: {
        spaceBetween: 8,
        slidesPerView: "auto",
      },
      767: {
        spaceBetween: 12,
        slidesPerView: 4,
      },
      992: {
        spaceBetween: 12,
        slidesPerView: 5,
      },
      1280: {
        spaceBetween: 12,
        slidesPerView: 6,
      },
      1400: {
        spaceBetween: 12,
        slidesPerView: 7,
      },
    },
    navigation: {
      prevEl: ".swiper-button-prev-season",
      nextEl: ".swiper-button-next-season",
    },
    modules: [Navigation],
    speed: 300,
  };

  const slides = subCategoryDetail?.map?.((subCat) => {
    return {
      content: (
        <Link
          prefetch={false}
          href={`/${subCat?.url_path}`}
          className="flex h-full"
          key={`${subCat.key}-${subCat.name}`}
        >
          <div
            className={`w-12/12 py-2 xs:px-[23px] px-4 border hover:border-primary-black-700 ${
              selectedCategory?.id == subCat.id
                ? "border-primary-black-700"
                : "border-primary-black-50"
            }  rounded-xl bg-tertiary-white`}
          >
            <div className="w-full h-[132px] flex items-center">
              <ImageWithFallback
                width={141}
                height={132}
                key={subCat?.id}
                alt={subCat.name}
                src={subCat.image}
                type={constants.image_types.category}
                className="w-full h-full max-w-full max-h-full object-contain"
              />
            </div>
            <p className="xs:text-subtitle-3 text-body-text-2-sb text-center mt-1">
              {subCat.name}
            </p>
          </div>
        </Link>
      ),
    };
  });

  return (
    <div>
      <ErrorHandler err={errorResponse.current || []} />
      <Breadcrumb paths={breadcrumbs} />
      {topCategory && topCategory?.children_count > 0 && (
        <section className="additive-section">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="lg:text-headline-3 xs:text-headline-4 text-body-text-1-b xs:mb-24 mb-12">
                  {topCategory?.name}
                </h2>
                <div className="relative xs:block hidden mb-2">
                  <div>
                    <PrevButton className="swiper-button-prev-season" />
                    <NextButton className="swiper-button-next-season" />
                  </div>
                  <CommonSwiper
                    slides={slides}
                    config={sliderConfig}
                    className="customProductSlider"
                  />
                </div>
                <div className="relative sorting-section xs:hidden block">
                  <div className="sort-categories" onClick={toggleDropdown}>
                    <div className="flex gap-[6px] items-center">
                      <div className="w-8 h-8">
                        <ImageWithFallback
                          width={110}
                          height={110}
                          alt={selectedCategory?.name}
                          src={selectedCategory?.image}
                          type={constants.image_types.category}
                          className="w-full h-[auto] max-w-full max-h-full"
                        />
                      </div>
                      <p>{selectedCategory?.name}</p>
                    </div>
                    <div>
                      <Image
                        width={24}
                        height={24}
                        alt="arrow"
                        unoptimized
                        src={ArrowDown}
                      />
                    </div>
                  </div>
                  <div
                    className={`sort-dropdown custom-overlay ${
                      dropDown ? "animate-fade-down" : "animate-fade-up h-0 p-0"
                    }`}
                  >
                    <div className="flex justify-between border-b border-primary-black-100 pb-3 mb-2 ">
                      <p className=" text-body-text-2-b text-primary-black">
                        {t("common:selectCategory")}
                      </p>
                      <div onClick={toggleDropdown}>
                        <Image
                          width={24}
                          height={24}
                          alt="close"
                          unoptimized
                          src={Cross}
                        />
                      </div>
                    </div>

                    <div className="drop-down-content scrollbar">
                      {subCategoryDetail?.map?.((subCatItem) => (
                        <div
                          tabIndex={1}
                          role="button"
                          key={`${subCatItem.key} + ${subCatItem.name}`}
                          className="custom-radio-button-container"
                          onClick={() => handleChangeCategory(subCatItem)}
                        >
                          <label
                            htmlFor={subCatItem.key}
                            className="text-body-text-3-m cursor-pointer"
                            aria-label={subCatItem.key}
                          >
                            <div>
                              <div className="flex gap-[6px]">
                                <div className="w-8 h-8">
                                  <ImageWithFallback
                                    width={110}
                                    height={110}
                                    src={subCatItem.image}
                                    alt={subCatItem.name}
                                    className="w-full h-[auto] max-w-full max-h-full"
                                  />
                                </div>
                                <p className="xs:text-subtitle-3 text-body-text-2-sb text-center mt-1">
                                  {subCatItem.name}
                                </p>
                              </div>
                            </div>
                            <input
                              type="radio"
                              id={subCatItem.key}
                              name="product_sorting"
                              onChange={handleSorting}
                              checked={selectedCategory?.id == subCatItem.id}
                            />
                            <span className="custom-radio-checkmark"></span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <ProductList
        items={items}
        pageSize={pageSize}
        currentURL={currentURL}
        topCategory={topCategory}
        initialPage={currentPage}
        key={selectedCategory?.id}
        category={selectedCategory}
        searchParams={searchParams}
        sortingOptions={sortingOptions}
        categoryid={selectedCategory?.id}
        description={topCategory?.description}
      />
    </div>
  );
}

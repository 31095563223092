import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  branch: null,
  manualSessionId: null,
  lastSearchedTerm: "",
};

const misc = createSlice({
  name: "misc",
  initialState,
  reducers: {
    updateBranch(state, action) {
      state.branch = action.payload;
    },
    updateManualSessionId(state, action) {
      state.manualSessionId = action.payload;
    },
    updateLastSearchedTerm(state, action) {
      state.lastSearchedTerm = action.payload;
    },
  },
});

export const { updateBranch, updateManualSessionId, updateLastSearchedTerm } = misc.actions;

export const getBranch = (state) => state.misc.branch;

export default misc;

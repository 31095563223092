import Button from "../Button/button";

const PageSizeControl = ({ t, open, limit, options, onClick, onChange }) => {
  return (
    <div className="relative page-size-section">
      <Button
        icon="arrowDownNew"
        theme="sort-btn"
        onClick={onClick}
        iconPosition="right"
        className="max-h-[36px]"
      >
        <div className="sort-categories">
          <p className="block">
            {t("filters.page_size_options.title", { limit: limit })}
          </p>
        </div>
      </Button>
      <div
        className={`page-size-dropdown sort-dropdown ${
          open ? "animate-fade-down" : "animate-fade-up h-0 p-0"
        }`}
      >
        <p className="border-b border-primary-black-100 pb-3 mb-2 text-body-text-3-sb text-primary-black-800">
          {t("filters.page_size")}
        </p>

        {options.map((option) => (
          <div
            key={option}
            className="custom-radio-button-container"
          >
            <label
              htmlFor={`page_size_${option}`}
              className="pl-4 text-body-text-3-m cursor-pointer"
            >
              <input
                type="radio"
                name="page-size"
                id={`page_size_${option}`}
                onChange={() => onChange(option)}
                defaultChecked={limit === option}
              />
              <span className="custom-radio-checkmark"></span>
              {" " + t("filters.page_size_options.title", { limit: option })}
            </label>
          </div>
        ))}
    </div>
  </div>
  )
}

export default PageSizeControl
"use client";

import { useEffect, useMemo, useState } from "react";
import { sortArrayOfObjects } from "@/lib/utils";
import Button from '../../../Button/button';

const FilterOptions = ({ selected, handleFilterChange, options, filterLabel, filterCode}) => {
    const [showAll, setShowAll] = useState(false);
    const optionsInfo = useMemo(() => {
      const list = sortArrayOfObjects(options, "label");
      return { list, total: options.length };
    }, [options]);
    const optionList = optionsInfo.list.slice(0, showAll ? optionsInfo.total : 5);

    useEffect(() => {
      const handleShowAll = (event) => {
        const header = event.target.closest(".show-all-option");
        if (!header) return; // Exit if the click didn't happen on an accordion header

        const accordionContent = header.parentNode.parentNode;
        const accordionMaxHeight = accordionContent.style.maxHeight;
        if (accordionMaxHeight === "0px" || accordionMaxHeight.length === 0) {
          accordionContent.style.maxHeight = `${
            accordionContent.scrollHeight + 16
          }px`;
          header.parentNode.parentNode.classList.add("active");
        } else {
          accordionContent.style.maxHeight = "0px";
          header.parentNode.parentNode.classList.remove("active");
        }
      };
      document.addEventListener("click", handleShowAll);
      return () => {
        document.removeEventListener("click", handleShowAll);
      };
    }, []);

    return (
      <>
        {optionList?.map((option) => (
          <li key={option?.value}>
            <label className="custom-check-box">
              <input
                type="checkbox"
                className="form-checkbox"
                id={`filter-option-${option?.value}`}
                checked={selected.some(i => i.code === filterCode && i.value === option.value)}
                onChange={(e) => handleFilterChange(option, e.target.checked, { filter_label: filterLabel, code: filterCode })}
              />
              <span className="ml-6 text-gray-700 uppercase">
                {option?.label}{" "}
                <span className="font-light text-primary-black-400">{`(${option?.count || 0})`}</span>
              </span>
            </label>
          </li>
        ))}

        {optionsInfo.total > 5 && (
          <Button theme="red-underline" className="show-all-option" onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Show Less' : 'Show All'}
          </Button>
        )}
      </>
  )
}

export default FilterOptions
"use client";

import { useEffect, useState } from "react";
import Testimonal from "@/components/common/card/Testimonal";
import Button from "@/components/common/shared/Button/button";
import { documentToReactComponents } from "@/lib/utils/contentful";

const WhyGsf = ({ whyGsf }) => {
  const [active, setActive] = useState(false);
  const { title, usps, description } = whyGsf?.fields || {};

  const handleShow = () => {
    setActive(!active);
    const branchBox = document.getElementById("gsfDescription");
    const topElement = document.getElementById("top-alert-header");
    let topClientHeight = 0;
    if (topElement) {
      topClientHeight = topElement.clientHeight;
    }
    const position = branchBox.getBoundingClientRect().top + window.scrollY;
    setTimeout(() => {
      window.scrollTo({
        top: position - topClientHeight, // Apply offset here
        behavior: "smooth",
      });
    }, 50);
  };

  useEffect(() => {
    const gsfDescription = document.getElementById("gsfDescription");
    const scrollHeight = gsfDescription?.scrollHeight;
    if (scrollHeight < 140) {
      readmore.style.display = "none";
    }
  }, []);

  return (
    <section className="testimonial-section">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <h2 className="lg:text-headline-3 xs:text-headline-4 text-body-text-1-b sm:mb-6 mb-4">
              {title}
            </h2>

            <div className="grid grid-cols-4 sm:gap-6 gap-[10px]">
              {usps?.map?.((usp) => {
                const {
                  title: uspTitle,
                  description: uspDescription,
                  icon,
                } = usp?.fields || {};
                return (
                  <Testimonal
                    itemPic={icon}
                    heading={uspTitle}
                    key={usp?.sys?.id}
                    subHeading={uspDescription}
                  />
                );
              })}
            </div>
            {!!description && (
              <div className="flex flex-col xs:border-[3px] border-[1px] border-secondary-500 rounded-2xl p-3 xs:p-8 mt-6 test1">
                <div
                  id="gsfDescription"
                  className={`description ${active ? "show" : ""}`}
                >
                  {documentToReactComponents(description)}
                </div>
                <div className="xs:hidden flex ms-auto mt-1">
                  <Button
                    id="readmore"
                    onClick={handleShow}
                    theme="blue-bordered-btn-arrow"
                  >
                    {active ? "Read Less" : "Read More"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyGsf;

"use client";

import Link from "next/link";
import dynamic from "next/dynamic";
import { useAppSelector } from "@/lib/store";
import { getPrice } from "@/lib/utils/functions";
import { handleLogout } from "@/lib/services/auth";
import ArrowDesktop from "@/assets/static/headerArrow.svg";
import Button from "@/components/common/shared/Button/button";
import { getCartCounts, getCartPrice } from "@/lib/store/reducers/cart";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";

const CustomTooltip = dynamic(
  () => import("@/components/common/shared/ToolTip"),
  {
    ssr: false,
  },
);

const AccountTooltip = dynamic(
  () => import("@/components/common/shared/AccountTooltip"),
  {
    ssr: false,
  },
);

const Dropdown = dynamic(
  () => import("@/components/common/shared/Dropdown/dropdown"),
  {
    ssr: false,
  },
);

const TopRightHeader = ({
  t,
  routes,
  router,
  dispatch,
  hasLoggedIn,
  isDropdownOpen,
  openMiniTrolley,
  setIsDropdownOpen,
  handleToastSuccess,
}) => {
  const cartPrice = useAppSelector(getCartPrice);
  const { totalCount } = useAppSelector(getCartCounts);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const handleLogoutClick = useCallback(() => {
    handleLogout({
      dispatch,
      onSuccess: () => {
        handleToastSuccess(t("common:messages.success.logout"));
        router.push(routes.logout);
      },
    });
  }, [t, router, dispatch]);

  const options = useMemo(() => {
    return [
      { label: t("dropdown.account"), href: routes.accountDashboard },
      { label: t("dropdown.garage"), href: routes.garage },
      { label: t("dropdown.orders"), href: routes.orders },
      { label: t("dropdown.addressBook"), href: routes.addressBook },
      { label: t("dropdown.accountInfo"), href: routes.accountInfo },
      { label: t("dropdown.newsletter"), href: routes.newsletter },
      {
        label: t("dropdown.logout"),
        onClick: handleLogoutClick,
      },
    ];
  }, [handleLogoutClick, t]);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const openDropdown = () => {
    setIsDropdownOpen(true);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 991) {
        setIsMobileScreen(false);
      } else {
        setIsMobileScreen(true);
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="right-part flex w-12/12 max-w-[200px] lg:max-w-[455px] justify-end items-start">
      <div className="map-toolkit animation-fadeIn relative cartBox person-toolkit group lg:hover:text-tertiary-white lg:me-[18px] me-12 cursor-pointer  dropdownBox order-none lg:flex">
        <Button
          icon="map"
          type="link"
          theme="iconOnly"
          href={routes.branches}
          className="store-button lg:flex hidden items-center"
        >
          <span className="lg:pe-12 flex text-body-text-3-sb lg:text-body-text-2-m text-primary-black group-hover:text-tertiary-white items-center ps-2 order-2">
            {t("stores")}
          </span>
        </Button>
        <Button
          type="link"
          theme="iconOnly"
          href={routes.branches}
          icon="mobileMap"
          className="lg:hidden flex p-1"
        />
        <CustomTooltip anchorId=".map-toolkit" content="Stores" place="top" />
      </div>

      <div
        tabIndex={0}
        role="button"
        onMouseEnter={openDropdown}
        onMouseLeave={closeDropdown}
        className={`cartBox person-toolkit group lg:hover:text-tertiary-white lg:me-[18px] me-12 cursor-pointer flex relative dropdownBox lg:order-none order-3 ${
          hasLoggedIn ? "accountArrow" : ""
        }`}
        onClick={(e) => {
          if (hasLoggedIn) e.preventDefault();
          setIsDropdownOpen(!isDropdownOpen);
        }}
      >
        <Link
          prefetch={false}
          className="lg:pe-12 flex text-body-text-3-sb lg:text-body-text-2-m text-primary-black group-hover:text-tertiary-white items-center"
          href={hasLoggedIn ? "#" : routes.signin}
          onClick={(e) => {
            if (hasLoggedIn) e.preventDefault();
          }}
        >
          <Button
            theme="iconOnly"
            icon="personMobile"
            className="lg:hidden flex p-1"
          />
          <Button className="lg:flex hidden" theme="iconOnly" icon="person" />
          <span className="ps-2 lg:flex hidden">
            {hasLoggedIn ? t("dropdown.account") : t("signIn")}
          </span>

          {isDropdownOpen && (
            <AccountTooltip title={hasLoggedIn ? "My Account" : "Sign In"} />
          )}

          {hasLoggedIn && (
            <ArrowDesktop className="lg:flex hidden lg:ms-[1px] ms-0" />
          )}
        </Link>

        {hasLoggedIn && isDropdownOpen && (
          <Dropdown
            router={router}
            options={options}
            onOptionClick={closeDropdown}
          />
        )}
      </div>

      <div
        tabIndex={0}
        role="button"
        onClick={openMiniTrolley}
        className="cartBox group lg:hover:text-tertiary-white cursor-pointer flex lg:order-none order-3"
      >
        <div className="relative icon-box">
          <Button
            theme="iconOnly"
            icon="ShoppingMobile"
            className="cart lg:hidden flex p-1"
          />
          <Button
            icon="cart"
            theme="iconOnly"
            className="cart lg:flex hidden"
          />
          <Suspense>
            <span className="number">{totalCount}</span>
          </Suspense>
        </div>
        {!isMobileScreen && (
          <Suspense>
            <p className="ps-2 pe-12 lg:flex hidden text-body-text-3-sb lg:text-body-text-2-m text-primary-black group-hover:text-tertiary-white">
              {getPrice(
                cartPrice?.grand_total?.value,
                cartPrice?.grand_total?.currency,
              )}
            </p>
          </Suspense>
        )}
        <CustomTooltip anchorId=".cart" content="Basket" place="top" />
      </div>
    </div>
  );
};

export default TopRightHeader;

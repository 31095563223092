"use client";

import Filters from "../Filters";
import PLPBanner from "../PLPBanner";
import Button from "../../../Button/button";
import { usePathname } from "next/navigation";
import { useTranslation } from "@/i18n/client";
import Car from "@/assets/icon/car-model-design.svg";
import PageSizeControl from "../../../PageSizeControl";
import { useClickHandler } from "@/lib/hooks/useClickHandler";
import { useCallback, useEffect, useMemo, useState } from "react";
import { hasParentWithMatchingSelector, sortArrayOfObjects } from "@/lib/utils";

export default function SelectionBox({
  is3BO,
  filters,
  pageSize,
  sortOrder,
  isCarParts,
  totalItems,
  categoryId,
  sortOptions,
  bannerImages,
  filterOptions,
  pageSizeOptions,
  handlePageSelection,
  handleFilterSelection,
  handleSortingSelection,
}) {
  const path = usePathname();

  const [dropDown, setDropDown] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [itemsDropDown, setItemsDropDown] = useState(false);
  const totalSelected = useMemo(() => filters.length, [filters]);
  const { t } = useTranslation(["common"]);

  const toggleDropdown = () => setDropDown(!dropDown);
  const toggleItemsDropdown = () => setItemsDropDown(!itemsDropDown);
  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleSorting = (sortBy) => {
    setDropDown(false);
    handleSortingSelection(sortBy);
  };

  const handlePageLimitChange = (limitNumber) => {
    setItemsDropDown(false);
    handlePageSelection(limitNumber);
  };

  const closeSortingDropdown = useCallback(
    (event) => {
      if (
        dropDown &&
        !hasParentWithMatchingSelector(event.target, ".sorting-section")
      ) {
        setDropDown(false);
      }
    },
    [dropDown],
  );

  const closePageSizeDropdown = useCallback(
    (event) => {
      if (
        itemsDropDown &&
        !hasParentWithMatchingSelector(event.target, ".page-size-section")
      ) {
        setItemsDropDown(false);
      }
    },
    [itemsDropDown],
  );

  const fitmentFilters = useMemo(() => {
    let filterCategory =
      filterOptions.find((el) => el.attribute_code === "filter_by") || {};

    if (filterCategory?.count) {
      filterCategory["options"] = filterCategory.options.filter((el) =>
        ["front", "rear"].includes(el?.label?.toLowerCase()),
      );
    }
    return filterCategory;
  }, [filterOptions]);

  const sizeFilters = useMemo(() => {
    if (!path.includes("engine-oil")) {
      return false;
    }
    let filterCategory =
      filterOptions.find((el) => el.attribute_code === "packaging_unit") || {};

    if (filterCategory?.count) {
      const list = sortArrayOfObjects(filterCategory.options, "label");
      filterCategory["options"] = list;
    }

    return filterCategory;
  }, [filterOptions, path]);

  const handleFitmentOptions = (item, isChecked = false, filterInfo = {}) => {
    let selection = [...filters];
    if (isChecked) {
      const exist = selection.some((i) => i.value === item.value);
      if (!exist) selection.push({ ...item, ...filterInfo });
    } else {
      selection = selection.filter((i) => i.value !== item.value);
      const filterOption = document.querySelector(
        `#fitment-option-${item.value}`,
      );
      if (filterOption) {
        filterOption.checked = false;
      }
    }
    handleFilterSelection([...selection]);
  };

  useClickHandler(closeSortingDropdown);
  useClickHandler(closePageSizeDropdown);
  useEffect(() => {
    if (isVisible) {
      document.body.classList.add("body-fixed");
    } else {
      document.body.classList.remove("body-fixed");
    }
  }, [isVisible]);

  return (
    <>
      {bannerImages && (
        <div className="sm:py-6 xs:py-5 py-0">
          {bannerImages && <PLPBanner {...bannerImages} />}
        </div>
      )}

      <div className="selectionBox">
        <div className="container">
          <div className="row">
            <div className="col w-12/12">
              <div
                className={`filterBox ${
                  fitmentFilters?.options?.length > 0 ? "" : "!pb-0"
                }`}
              >
                <div className="filterBox-content flex sm:flex-row flex-col gap-3">
                  <Button
                    icon="filter"
                    theme="filter-btn"
                    iconPosition="left"
                    onClick={toggleVisibility}
                    className="flex"
                  >
                    <p className="xs:block hidden whitespace-nowrap">
                      {t("filters.filter")}
                    </p>
                    <p className="block xs:hidden whitespace-nowrap">
                      {t("filters.filter_by")}
                    </p>
                    {totalSelected > 0 && (
                      <span className="number flex-center">
                        {totalSelected}
                      </span>
                    )}
                  </Button>
                  <div className="flex items-center gap-2">
                    {fitmentFilters?.options?.length > 0 &&
                      fitmentFilters?.options?.map((option) => (
                        <div
                          className="custom-radio-button-container"
                          key={`fitment-key-${option?.value}`}
                        >
                          <input
                            type="checkbox"
                            name="car"
                            id={`fitment-option-${option?.value}`}
                            checked={filters.some(
                              (i) => i?.value === option?.value,
                            )}
                            onChange={(e) =>
                              handleFitmentOptions(option, e.target.checked, {
                                filter_label: fitmentFilters?.label,
                                code: fitmentFilters?.attribute_code,
                              })
                            }
                          />
                          <label
                            htmlFor={`fitment-option-${option?.value}`}
                            className="custom-radio-label"
                          >
                            {option?.label?.toLowerCase() === "front" ? (
                              <span
                                className={`custom-radio-checkmark ${
                                  option?.label?.toLowerCase() === "front"
                                    ? "front"
                                    : ""
                                }`}
                              >
                                <Car />
                              </span>
                            ) : (
                              <span className="custom-radio-checkmark">
                                <Car />
                              </span>
                            )}
                            {option?.label}
                          </label>
                        </div>
                      ))}
                  </div>
                  {/* ----filters for size---- */}
                  {sizeFilters?.options?.length > 0 && (
                    <div className="flex flex-row h-full sm:items-center items-start gap-2 border border-primary-black-100 rounded-[5px] p-2">
                      <span className="text-primary-blue-900 text-body-text-3-m">
                        Size:
                      </span>
                      <div className="flex gap-4">
                        {sortArrayOfObjects(sizeFilters?.options, "label")?.map(
                          (option) => (
                            <div
                              key={`brand-key-${option?.value}`}
                              className="filter-size-container"
                            >
                              <label className="custom-check-box">
                                <input
                                  type="checkbox"
                                  name="brand"
                                  id={`brand-option-${option?.value}`}
                                  checked={filters.some(
                                    (i) => i?.value === option?.value,
                                  )}
                                  onChange={(e) =>
                                    handleFitmentOptions(
                                      option,
                                      e.target.checked,
                                      {
                                        filter_label: sizeFilters?.label,
                                        code: sizeFilters?.attribute_code,
                                      },
                                    )
                                  }
                                />
                                <span
                                  htmlFor={`brand-option-${option?.value}`}
                                  className="ml-6 text-gray-700"
                                >
                                  {option?.label}
                                </span>
                                <span className="text-primary-black-400 text-body-text-3-sb">{`(${
                                  option?.count || 0
                                })`}</span>
                              </label>
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex xs:gap-[10px] gap-2 ms-auto items-center flex-row sm:relative absolute top-0 right-0">
                  {(!isCarParts || is3BO) && (
                    <PageSizeControl
                      t={t}
                      limit={pageSize}
                      open={itemsDropDown}
                      options={pageSizeOptions}
                      onClick={toggleItemsDropdown}
                      onChange={handlePageLimitChange}
                    />
                  )}

                  <div className="relative sorting-section">
                    <Button
                      icon="upDown"
                      theme="sort-btn"
                      iconPosition="right"
                      onClick={toggleDropdown}
                      className="max-h-[36px]"
                    >
                      <div className="sort-categories">
                        <p className="xs:block hidden">
                          {t("filters.sort_by")}: {sortOrder.label}
                        </p>
                        <p className="xs:hidden block">
                          {t("filters.sort_by")}
                        </p>
                      </div>
                    </Button>
                    <div
                      className={`sort-dropdown ${
                        dropDown
                          ? "animate-fade-down"
                          : "animate-fade-up h-0 p-0"
                      }`}
                    >
                      <p className="border-b border-primary-black-100 pb-3 mb-2 text-body-text-3-sb text-primary-black-800">
                        {t("filters.sort_by")}
                      </p>

                      {sortOptions.map((sortOption) => (
                        <div
                          key={sortOption.key}
                          className="custom-radio-button-container"
                        >
                          <label
                            htmlFor={sortOption.key}
                            className="pl-4 text-body-text-3-m cursor-pointer"
                          >
                            <input
                              type="radio"
                              id={sortOption.key}
                              name="product_sorting"
                              defaultChecked={sortOrder.key === sortOption.key}
                              onChange={() => handleSorting(sortOption)}
                            />
                            <span className="custom-radio-checkmark"></span>
                            {" " +
                              t(`filters.sorting_options.${sortOption.key}`)}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {totalSelected > 0 && (
                <div className="info-listing">
                  <div className="selected-filters">
                    <ul className="w-12/12 flex flex-wrap gap-[6px]">
                      {filters.map((item) => (
                        <li key={item.value}>
                          <span>
                            <strong>{`${item.filter_label}: `}</strong>
                            {item.label}
                            <span className=" ms-[2px] font-light text-primary-black-400">{`(${
                              item?.count || 0
                            })`}</span>
                          </span>
                          <Button
                            icon="cross-white"
                            theme="iconOnly"
                            onClick={() =>
                              handleFilterSelection(
                                filters.filter((i) => i.value !== item.value),
                              )
                            }
                          />
                        </li>
                      ))}
                      <Button
                        theme="red-underline"
                        onClick={() => handleFilterSelection([])}
                      >
                        Clear All
                      </Button>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Filters
          t={t}
          filters={filters}
          isVisible={isVisible}
          categoryId={categoryId}
          totalProducts={totalItems}
          totalSelected={totalSelected}
          filterOptions={filterOptions}
          toggleVisibility={toggleVisibility}
          handleFilterSelection={handleFilterSelection}
        />
      </div>
    </>
  );
}

"use client";

import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { constants } from "@/lib/utils";
import { useAppSelector } from "@/lib/store";
import Spinner from "@/components/common/Spinner";
import SubCategoriesListing from "../SubCategoriesListing";
import { useEffect, useMemo, useRef, useState } from "react";
import { filterSubCategories } from "@/lib/utils/helpers/plp";
const FourthLevelCategories = dynamic(
  () => import("../FourthLevelCategories"),
  {
    loading: () => <Spinner />,
    ssr: false,
  },
);

const SubCategory = ({
  title,
  queryString,
  subCategories,
  currentVehicle,
  filteredSubCategories,
  showForthLevelView = false,
}) => {
  const { vehicle } = useAppSelector((state) => state.vehicle);
  const vehicleCookie = Cookies.get(constants.cookies.selectedVehicle);

  const isMounted = useRef(null);
  const [isFiltering, setIsFiltering] = useState(true);
  const [vehicleInfo, setVehicleInfo] = useState(
    vehicleCookie ? currentVehicle : null,
  );

  useEffect(() => {
    setVehicleInfo(vehicle);
  }, [vehicle]);

  const categoryList = useMemo(() => {
    setIsFiltering(true);
    let data = filteredSubCategories;
    if (!isMounted.current) {
      isMounted.current = true;
      if (!vehicleInfo) {
        data = subCategories;
      }
    } else {
      data = filterSubCategories(
        subCategories,
        vehicleInfo,
        showForthLevelView,
      );
    }
    setIsFiltering(false);
    return data;
  }, [vehicleInfo]);

  if (showForthLevelView) {
    return (
      <FourthLevelCategories
        title={title}
        categories={categoryList}
        isFiltering={isFiltering}
        queryString={queryString}
      />
    );
  }

  return (
    <SubCategoriesListing categories={categoryList} queryString={queryString} />
  );
};

export default SubCategory;

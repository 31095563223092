"use client";

import { memo } from "react";
import dynamic from "next/dynamic";
import classNames from "classnames";
import Input from "@/components/common/shared/Input/input";
const Suggestions = dynamic(
  () => import("@/components/common/shared/SearchSuggestionBox"),
  {
    ssr: false,
  },
);

// Dynamically import and memoize SearchBoxPlaceholder
const Placeholder = dynamic(
  () =>
    import("./SearchBoxPlaceholder").then((mod) =>
      memo(mod.SearchBoxPlaceholder),
    ),
  {
    ssr: false, // Optional: only for client-side rendering
  },
);

export default function SearchBox({
  t,
  routes,
  searching,
  isFocused,
  searchTerm,
  isSearched,
  suggestions,
  setIsFocused,
  enterKeyHandler,
  handleResultLink,
  handleClearSearch,
  queryToSearchItems,
  handleSearchChange,
}) {
  return (
    <>
      <div
        className={classNames("searchBox", {
          focused: isFocused,
          suggestions: isFocused && (searching || isSearched),
        })}
      >
        <Input
          type="search"
          id="desktop-search"
          placeholder="Search for parts"
          defaultValue={searchTerm}
          onKeyDown={enterKeyHandler} // Handles Enter key press
          onClear={handleClearSearch}
          onSearch={queryToSearchItems}
          onFocus={() => setIsFocused(true)}
          icon={searchTerm ? "cross" : "search"}
          onChange={(e) => {
            setIsFocused(true);
            handleSearchChange(e); // Syncs and debounces search input
          }}
          onBlur={() =>
            setTimeout(() => {
              setIsFocused(false);
            }, 500)
          }
        />

        {!searchTerm && <Placeholder t={t} isFocused={isFocused} />}
        <div className="search-suggestion-box">
          <Suggestions
            routes={routes}
            isFetching={searching}
            searchTerm={searchTerm}
            suggestions={suggestions}
            handleResultLink={handleResultLink}
          />
        </div>
      </div>

      <div
        tabIndex={0}
        role="button"
        className="search-bg"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      ></div>
    </>
  );
}

import React from "react";
import Image from "next/image";
import NoVehicle from "@/assets/static/no-vehicle-selected.svg?url";

export default function EmptyResult() {
  return (
    <div className="bg-primary-blue-50 rounded xs:py-6 py-2 px-3 items-center justify-center w-full xs:mt-0 mt-2 xs:flex hidden">
      <Image
        width={1320}
        height={90}
        alt="NoVehicle"
        src={NoVehicle}
        className="min-h-[90px]"
      />
    </div>
  );
}

"use client";

import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { constants } from "@/lib/utils/constants";
import { useSearchParams } from "next/navigation";
import { viewItemListAnalytics } from "@/lib/gtm";
import { getRecentlyViewedProducts } from "@/lib/services/home";
import { createItemListPayload } from "@/lib/utils/helpers/plp";
import {
  formatProductGA4,
  handleToastError,
  mutateProductInfo,
} from "@/lib/utils/functions";

const RecentViewedList = dynamic(() => import("./RecentViewedList"));

const Recent = ({ headings, bestSellerImg = "", recommendedImg = "" }) => {
  const searchParams = useSearchParams();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const sendGoogleAnalytics = (items) => {
      // View cart GA4 for recently viewed
      const list = formatProductGA4(
        items
          .map((p) => mutateProductInfo(p))
          .map((el) => ({ ...el, quantity: el.extraVariable.qty })),
      );

      viewItemListAnalytics(
        createItemListPayload(list, "Recently Viewed", true),
      );
    };

    const fetchRecentProducts = async () => {
      let productList = [];
      try {
        const jsonStr = localStorage.getItem(
          constants.localStorage.recently_viewed,
        );
        productList = jsonStr ? JSON.parse(jsonStr) : [];
      } catch (err) {
        console.error("Error fetching recent product:", err);
      }

      if (productList.length > 0) {
        try {
          const scRes = Cookies.get(constants.cookies.specialCoupon);
          const coupon = searchParams.get("auto_apply_coupon") || scRes || null;

          const { products } = await getRecentlyViewedProducts(
            productList,
            coupon,
            productList,
          );

          sendGoogleAnalytics(products);
          setProducts(products);
        } catch (err) {
          handleToastError(err.message);
        }
      }
    };
    fetchRecentProducts();
  }, []);

  if (products && !products?.length) return null;
  return (
    <RecentViewedList
      products={products}
      bestSellerImg={bestSellerImg}
      recommendedImg={recommendedImg}
      title={headings?.recentlyview_product_title}
      description={headings?.recentlyview_product_desc}
    />
  );
};

export default Recent;

"use client";

import Cookies from "js-cookie";
import React, { useEffect } from "react";
import NoResult from "@/components/misc/NoResult";
import useProductListing from "@/lib/hooks/useProductListing";
import CustomPagination from "@/components/common/CustomPagination";
import { no_products_found, viewItemListAnalytics } from "@/lib/gtm";
import SubCategoryCard from "@/components/common/card/SubCategoryCard";
import SelectionBox from "../../ProductListing/components/SelectionBox";
import CategoryDescription from "../../ProductListing/components/CategoryDescription";
import {
  constants,
  getPageType,
  formatProductCategoriesGA4,
} from "@/lib/utils";
import {
  pageSummaryInfo,
  createItemListPayload,
} from "@/lib/utils/helpers/plp";
import styles from "@/assets/css/pages/font.module.css";
export default function ProductList({
  items,
  category,
  pageSize,
  currentURL,
  categoryid,
  description,
  initialPage,
  searchParams,
  sortingOptions,
  topCategory = {},
}) {
  const {
    data: {
      filters,
      products,
      sortOrder,
      pageLimit,
      currentPage,
      filterOptions,
      totalProducts,
      isFetching,
    },
    methods: {
      t,
      handlePageChange,
      handleAddToTrolley,
      handlePageSelection,
      handleFilterSelection,
      handleSortingSelection,
    },
  } = useProductListing({
    pageSize,
    categoryid,
    searchParams,
    categoryIdParam: true,
    initial: {
      page: initialPage,
      products: items.data,
      product_total: items.total,
      sort_order: items.sortingOrder,
      filter_options: items.filterOptions,
    },
  });

  useEffect(() => {
    if (totalProducts > 0) {
      const formattedProducts = products.map((product, i) => {
        const brands = product?.brands || [];
        const selectedBrand = brands.find((b) => b.is_selected);
        const productInfo = {
          item_id: selectedBrand?.sku,
          item_name: selectedBrand?.product_name_without_brand_name,
          discount: selectedBrand?.amountOff_without_currency,
          item_brand: selectedBrand?.title,
          price: selectedBrand?.final_price_without_currency,
          quantity: 1,
          index: i,
          ...(Number(selectedBrand?.amountOff_without_currency) &&
            !selectedBrand?.specialCoupon_applied && {
              coupon: Cookies.get("specialCoupon"),
            }),
          ...formatProductCategoriesGA4(selectedBrand?.breadcrumbs),
        };
        return productInfo;
      });

      viewItemListAnalytics(
        createItemListPayload(formattedProducts, "Category Products", true),
      );
    } else {
      no_products_found({
        page_type: getPageType(),
      });
    }
  }, [totalProducts]);

  return (
    <section className="accessories-subcategories">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className={`${styles.heading32} capitalize small-heading-text`}>
              {topCategory?.children_count > 0 && t("common:all")}{" "}
              {category?.name}
            </h1>
          </div>
        </div>
      </div>
      {products?.length > 0 ? (
        <>
          <SelectionBox
            pageSize={pageLimit}
            sortOrder={sortOrder}
            currentPage={currentPage}
            totalItems={totalProducts}
            sortOptions={sortingOptions}
            filterOptions={filterOptions}
            handlePageChange={handlePageChange}
            handlePageSelection={handlePageSelection}
            handleFilterSelection={handleFilterSelection}
            handleSortingSelection={handleSortingSelection}
            pageSizeOptions={constants.pageSizesSubcategory}
            filters={category.id == categoryid ? filters : []}
          />
          <div className="container xs:mb-[80px] mb-[40px]">
            <div className="row xs:mt-24 xs:mb-24 mt-10 mb-12">
              <div className="col w-12/12">
                <p className="text-primary-black-600 xs:text-body-text-3 text-body-text-3-m my-auto block w-12/12 mb-[6px]">
                  {t(
                    "filters.result_summary",
                    pageSummaryInfo(currentPage, pageLimit, totalProducts),
                  )}
                </p>
              </div>
            </div>
            <div className="row" id="product-list">
              <div className="col">
                <div className="grid lg:grid-cols-4 xs:grid-cols-3 grid-cols-2 xs:gap-6 gap-4">
                  {products?.map?.((product, i) => (
                    <SubCategoryCard
                      t={t}
                      loop_index={i}
                      product={product}
                      item_list="Category Products"
                      imageTypes={constants.image_types}
                      key={`${product.article_number}${i}`}
                      handleAddToTrolley={handleAddToTrolley}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="plp-pagination-box w-12/12">
                  <p className="text-primary-black-600 xs:text-body-text-3 text-body-text-3-m my-auto block w-12/12 mb-[6px]">
                    {t(
                      "filters.result_summary",
                      pageSummaryInfo(currentPage, pageLimit, totalProducts),
                    )}
                  </p>
                  <CustomPagination
                    scrollToTop
                    page={currentPage}
                    total={totalProducts}
                    currentURL={currentURL}
                    itemsPerPage={pageLimit}
                    setPage={handlePageChange}
                    searchParams={searchParams}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        !isFetching && (
          <div className="container">
            <div className="row">
              <div className="col">
                <NoResult
                  base_msg={t("messages.no_results.base_product")}
                  msg={t("messages.no_results.product")}
                />
              </div>
            </div>
          </div>
        )
      )}
      <div>
        <CategoryDescription description={description} />
      </div>
    </section>
  );
}

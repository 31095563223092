"use client";

import { useState } from "react";
import { renderHTML } from "@/lib/utils";
import { useTranslation } from "@/i18n/client";
import Button from "@/components/common/shared/Button/button";
import "@/assets/css/pages/cms.css";

const CategoryDescription = ({ description = "" }) => {
  const { t } = useTranslation(["common"]);
  const [isReadMore, setIsReadMore] = useState(false);

  if (!description) return null;
  return (
    <div className="container xs:mb-[80px] mb-[0px]">
      <div className="row">
        <div className="col w-12/12">
          <div
            className={`description-content ${
              isReadMore ? "" : "xs:line-clamp-none line-clamp-6"
            }`}
          >
            {renderHTML(description)}
          </div>
          <div className="xs:hidden flex ms-auto mt-1">
          <Button
            id="readmore"
            onClick={() => setIsReadMore(!isReadMore)}
            className="text-primary-blue-900 underline text-body-text-3-b"
          >
            {isReadMore ? t("button.read_less") : t("button.read_more")}
          </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryDescription;

"use client";

import { registration_lookup } from "../gtm";
import { useSearchParams } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { checkVehicleSchema } from "../utils/schemas";
import { useAppDispatch, useAppSelector } from "@/lib/store";
import { getVehicleInfo, handleVehicleResposne } from "@/lib/services/api";
import { getModelList, getVehicleNextOptions } from "@/lib/services/vehicles";
import {
  MVLPayloadToKey,
  handleToastError,
  MVLKeyToQueryString,
  replaceHistoryState,
} from "@/lib/utils/functions";
import { fetchMakeOptions } from "../store/reducers/makeOptions";
import { updateVehicle } from "../store/reducers/vehicle";
import { updateManualSessionId } from "../store/reducers/misc";
import { endCommonLoading, startCommonLoading } from "../store/reducers/uiState";

export const useVehicleSelectionController = ({
  t,
  manualSearch,
  hasManualLook,
  selectedMake,
  selectedModel,
  removeManualSelection = () => {},
}) => {
  const modelOptions = useRef([]);
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();

  const [enableSearchBtn, setEnableSearchBtn] = useState(true);
  const { makeOptions } = useAppSelector((state) => state.makeOptions);
  const { reset, control, register, setValue, getValues, handleSubmit } =
    useForm({
      defaultValues: {
        reg: "",
        make: hasManualLook ? selectedMake?.value || "" : "",
        model: "",
        type: "",
        year: "",
        engine: "",
        body: "",
        kw_hp: "",
      },
      resolver: yupResolver(checkVehicleSchema(() => {})),
    });
  const modelField = useWatch({ control, name: "model" });
  const [optionsList, setOptionsList] = useState({
    make: [],
    model: [],
    year: [],
    engine: [],
    body: [],
    kw_hp: [],
  });

  useEffect(() => {
    if (!modelField) {
      setValue("type", "");
    }
  }, [modelField]);

  const searchVehicleFromMVL = async () => {
    if (manualSearch) {
      setEnableSearchBtn(false);
    } else {
      try {
        dispatch(startCommonLoading());
        let payload = getValues();
        delete payload.reg;
        const queryStr = MVLKeyToQueryString(MVLPayloadToKey(payload));
        const vRes = await getVehicleInfo(queryStr, true, hasManualLook);
        const vData = handleVehicleResposne(t, vRes, handleToastError);
        if (!vData) return;

        registration_lookup({
          manufacturer: vData?.vehicle?.make,
          model: vData?.vehicle?.model,
          years: vData?.vehicle?.modelVersion?.match(/\d{4}/)[0],
          ...(vData?.vehicle?.engineSize && {
            engine: vData?.vehicle?.engineSize,
          }),
        });
        dispatch(updateVehicle(vData));
        dispatch(updateManualSessionId(null));
        const urlSearchParams = new URLSearchParams(window.location.search);
        const { make, model, ref, ...params } = Object.fromEntries(
          urlSearchParams.entries(),
        );
        const searchParamString = new URLSearchParams(params).toString();
        replaceHistoryState({ url: `?${searchParamString}` });
        setOptionsList((prev) => ({
          make: prev.make,
          model: [],
          year: [],
          engine: [],
          body: [],
          kw_hp: [],
        }));
        reset();
      } catch (err) {
        handleToastError(err.message);
      } finally {
        dispatch(endCommonLoading());
      }
    }
  };

  const handleDropdownSelection = async (field) => {
    switch (field) {
      case "body":
        setValue("kw_hp", "");
        setEnableSearchBtn(true);
        break;
      case "engine":
        setValue("kw_hp", "");
        setValue("body", "");
        setEnableSearchBtn(true);
        break;
      case "year":
        setValue("kw_hp", "");
        setValue("body", "");
        setValue("engine", "");
        setEnableSearchBtn(true);
        break;
      case "model":
        setValue("kw_hp", "");
        setValue("body", "");
        setValue("engine", "");
        setValue("year", "");
        setEnableSearchBtn(true);
        break;
      default:
        setValue("type", "");
        setValue("model", "");
        setValue("year", "");
        setValue("engine", "");
        setValue("body", "");
        setValue("kw_hp", "");
        setEnableSearchBtn(true);
        break;
    }

    try {
      const { reg, ...rest } = getValues();
      if (field === "make") {
        const models = await getModelList(rest.make, t);
        setOptionsList((prev) => ({
          make: prev.make,
          model: models,
          year: [],
          engine: [],
          body: [],
          kw_hp: [],
        }));

        modelOptions.current = models;
        if (hasManualLook && selectedModel?.value) {
          const modelObj = models.find(
            (res) => res.value === selectedModel.value,
          );

          setValue("model", selectedModel.value);
          setValue("type", modelObj?.type || "LCV");
          handleDropdownSelection("model");
        }
      } else {
        if (hasManualLook) dispatch(startCommonLoading());
        if (rest.model && !rest.type) {
          const modelObj = modelOptions.current.find(
            (r) => r.value === rest.model,
          );
          setValue("type", modelObj?.type || "LCV");
        }

        const options = await getVehicleNextOptions({ ...getValues() }, field);
        if (options?.sessionId) {
          dispatch(updateManualSessionId(options?.sessionId));
          dispatch(endCommonLoading());
        }

        if (options?.search_now) {
          searchVehicleFromMVL();
        } else if (options?.extra_fields) {
          Object.entries(options.extra_fields).forEach(([key, value]) => {
            if (!value) {
              setOptionsList((prev) => {
                return {
                  ...prev,
                  [key]: [],
                };
              });
            }
            setValue(key, value);
          });
          searchVehicleFromMVL();
        } else {
          setOptionsList((prev) => {
            switch (field) {
              case "year":
                return { ...prev, engine: options.engine, body: [], kw_hp: [] };
              case "engine":
                return { ...prev, body: options.body, kw_hp: [] };
              case "body":
                return { ...prev, kw_hp: options.kw_hp };
              default:
                return {
                  ...prev,
                  year: options.year,
                  engine: [],
                  body: [],
                  kw_hp: [],
                };
            }
          });
        }
        dispatch(endCommonLoading());
      }
    } catch (err) {
      dispatch(endCommonLoading());
      handleToastError(err?.message);
    }
  };

  useEffect(() => {
    if (!makeOptions.length) {
      dispatch(fetchMakeOptions()).then(({ meta, payload }) => {
        setOptionsList({
          make: meta?.requestStatus === "fulfilled" ? payload : [],
          model: [],
          year: [],
          engine: [],
          kw_hp: [],
        });
      });
    } else {
      setOptionsList({
        make: makeOptions,
        model: [],
        year: [],
        engine: [],
        kw_hp: [],
      });
    }
  }, [makeOptions, dispatch]);

  useEffect(() => {
    if (hasManualLook && selectedMake?.value) {
      handleDropdownSelection("make");
    }
  }, [hasManualLook, selectedMake]);

  useEffect(() => {
    return () => {
      reset();
      setOptionsList((prev) => ({
        make: prev.make,
        model: [],
        year: [],
        engine: [],
        kw_hp: [],
      }));
    };
  }, [reset]);

  useEffect(() => {
    if (
      hasManualLook &&
      (!searchParams.has("make") || !searchParams.has("model"))
    ) {
      removeManualSelection();
      setValue("kw_hp", "");
      setValue("body", "");
      setValue("engine", "");
      setValue("year", "");
      setValue("model", "");
      setValue("make", "");
      setValue("type", "");
      setOptionsList((prev) => ({
        make: prev.make,
        model: [],
        year: [],
        engine: [],
        kw_hp: [],
      }));
    }
  }, [searchParams]);

  return {
    data: {
      control,
      optionsList,
      enableSearchBtn,
    },
    methods: {
      register,
      handleSubmit,
      getValues,
      searchVehicleFromMVL,
      handleDropdownSelection,
      setOptionsList,
      reset,
    },
  };
};

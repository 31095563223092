"use client";
import "@/assets/css/pages/footer.css";
import Link from "next/link";
import Image from "next/image";
import Cookies from "js-cookie";
import { useState } from "react";
import dynamic from "next/dynamic";
import { routes } from "@/lib/utils/routes";
import { useAppSelector } from "@/lib/store";
import { useTranslation } from "@/i18n/client";
import { constants } from "@/lib/utils/constants";
import { getCustomerToken } from "@/lib/store/reducers/customer";

import Logo from "@/assets/static/Logo.svg?url";
import Amex from "@/assets/paymentIcon/amex.svg?url";
import Visa from "@/assets/paymentIcon/visa.svg?url";
import PayPal from "@/assets/paymentIcon/paypal.svg?url";
import Klarna from "@/assets/paymentIcon/klarnapink.svg?url";
import ApplePay from "@/assets/paymentIcon/applepay.svg?url";
import GooglePay from "@/assets/paymentIcon/googlepay.svg?url";
import MasterCard from "@/assets/paymentIcon/mastercard.svg?url";
import Facebook from "@/assets/static/Facebook.svg?url";
import Recaptcha from "@/assets/icon/recaptcha_symbol.svg?url";

const NewsLetter = dynamic(() => import("./NewsLetter"), {
  ssr: false,
});

const footerKeys = {
  aboutUs: {
    key: "about-us",
    title: "footer.about.title",
  },
  wayToShop: {
    key: "how-to-shop",
    title: "footer.how_to_shop.title",
  },
  customerServices: {
    key: "customer-services",
    title: "footer.customer_services.title",
  },
};

const AccordianHeader = ({
  t,
  footerKey = {},
  hasIncludedKey,
  handleAccordianClick,
}) => {
  return (
    <div
      className="flex justify-between"
      onClick={() => handleAccordianClick(footerKey.key)}
    >
      <h3 className="lg:text-headline-5 xs:text-body-text-1-b text-subtitle-4-b lg:mb-[40px] xs:mb-7 mb-[16px]">
        {t(footerKey.title)}
      </h3>
      <div className="xs:hidden block">
        <svg
          width="24"
          height="25"
          fill="none"
          viewBox="0 0 24 25"
          xmlns="http://www.w3.org/2000/svg"
          className={hasIncludedKey(footerKey.key) ? "rotate-180" : ""}
        >
          <path
            d="M6 9.16211L12 15.1621L18 9.16211"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export const Footer = () => {
  const { t } = useTranslation(["footer"]);
  const token = useAppSelector(getCustomerToken);
  const [footerList, setFooterList] = useState([]);

  const hasIncludedKey = (keyName) => footerList.includes(keyName);
  const handleMakesModelsClick = () => {
    Cookies.remove(constants.cookies.new_vehicle);
    Cookies.remove(constants.cookies.manual_lookup);
    Cookies.remove(constants.cookies.selectedVehicle);
  };

  const handleAccordianClick = (key) => {
    if (hasIncludedKey(key)) {
      setFooterList(footerList.filter((item) => item !== key));
    } else {
      setFooterList((oldList) => [...oldList, key]);
    }
  };

  return (
    <>
      <NewsLetter t={t} />
      <section className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col w-12/12 flex md:flex-row flex-col mb-8">
              <div className="footer-left-part">
                <div>
                  <Link prefetch={false} href="/">
                    <Image
                      alt="GSF"
                      src={Logo}
                      width={78}
                      height={50}
                      unoptimized
                      className="xs:w-[79px] w-[64px] lg:mb-[48px] xs:mb-6"
                    />
                  </Link>
                </div>
                <div className="footer-socialmedia-box">
                  <p className="text-subtitle-2-b md:mb-[20px] mb-[8px]">
                    {t("footer.follow_us")}
                  </p>
                  <span>
                    <Link
                      rel="noopener"
                      target="_blank"
                      prefetch={false}
                      href={routes.facebook}
                      className="flex md:justify-start justify-end"
                    >
                      <Image
                        width={20}
                        height={20}
                        src={Facebook}
                        alt="Social Media Facebook"
                      />
                    </Link>
                  </span>
                </div>
              </div>
              <div className="footer-middle-part">
                <div className="flex flex-col">
                  <AccordianHeader
                    t={t}
                    footerKey={footerKeys.aboutUs}
                    hasIncludedKey={hasIncludedKey}
                    handleAccordianClick={handleAccordianClick}
                  />
                  <ul
                    className={
                      !hasIncludedKey(footerKeys.aboutUs.key)
                        ? "hidden xs:flex flex-col"
                        : "flex flex-col xs:mb-0 mb-12"
                    }
                  >
                    <li className="footer-list-item">
                      <Link prefetch={false} href={routes.aboutUs}>
                        {t("footer.about.about_link")}
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link
                        rel="noopener"
                        target="_blank"
                        prefetch={false}
                        href={routes.careers}
                      >
                        {t("footer.about.careers")}
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link
                        rel="noopener"
                        target="_blank"
                        prefetch={false}
                        href={routes.corporateInfo}
                      >
                        {t("footer.about.corporate_info")}
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link prefetch={false} href={routes.sitemap}>
                        {t("footer.about.sitemap")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col">
                  <AccordianHeader
                    t={t}
                    hasIncludedKey={hasIncludedKey}
                    footerKey={footerKeys.wayToShop}
                    handleAccordianClick={handleAccordianClick}
                  />
                  <ul
                    className={
                      !hasIncludedKey(footerKeys.wayToShop.key)
                        ? "hidden xs:flex flex-col"
                        : "flex flex-col xs:mb-0 mb-12"
                    }
                  >
                    <li className="footer-list-item">
                      <Link
                        prefetch={false}
                        href={routes.makesAndModels}
                        onClick={handleMakesModelsClick}
                      >
                        {t("footer.how_to_shop.makeModel")}
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link prefetch={false} href={routes.deliveryInfo}>
                        {t("footer.how_to_shop.home_delivery")}
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link prefetch={false} href={routes.clickCollect}>
                        {t("footer.how_to_shop.click_collect")}
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link prefetch={false} href={routes.storeLocator}>
                        {t("footer.how_to_shop.store")}
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link prefetch={false} href={routes.knowHub}>
                        {t("footer.how_to_shop.hub")}
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link
                        prefetch={false}
                        href={routes.garageEquipment}
                        target="_blank"
                      >
                        {t("footer.how_to_shop.garage")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col">
                  <AccordianHeader
                    t={t}
                    hasIncludedKey={hasIncludedKey}
                    footerKey={footerKeys.customerServices}
                    handleAccordianClick={handleAccordianClick}
                  />
                  <ul
                    className={
                      !hasIncludedKey(footerKeys.customerServices.key)
                        ? "hidden xs:flex flex-col"
                        : "flex flex-col xs:mb-0 mb-12"
                    }
                  >
                    <li className="footer-list-item">
                      <Link
                        prefetch={false}
                        href={token ? routes.accountDashboard : routes.signin}
                      >
                        {t("footer.customer_services.account")}
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link prefetch={false} href={routes.guestReturns}>
                        {t("footer.customer_services.refunds")}
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link prefetch={false} href={routes.klarna}>
                        {t("footer.customer_services.klarna")}
                      </Link>
                    </li>
                    <li className="footer-list-item">
                      <Link prefetch={false} href={routes.contactUs}>
                        {t("footer.customer_services.contact")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-right-part">
                <h3 className="lg:text-headline-5 xs:text-body-text-1-b text-subtitle-4-b lg:mb-[40px] xs:mb-7 mb-[16px]">
                  {t("footer.checkout_with")}
                </h3>
                <div className="flex">
                  <span className="icon-container">
                    <Image
                      width={48}
                      unoptimized
                      height="100%"
                      alt="PayPal"
                      src={PayPal}
                    />
                  </span>
                  <span className="icon-container">
                    <Image
                      width={48}
                      unoptimized
                      height="100%"
                      alt="Klarna"
                      src={Klarna}
                    />
                  </span>
                  <span className="icon-container">
                    <Image
                      width={48}
                      unoptimized
                      height="100%"
                      alt="Visa"
                      src={Visa}
                    />
                  </span>
                  <span className="icon-container">
                    <Image
                      width={48}
                      unoptimized
                      height="100%"
                      alt="MasterCard"
                      src={MasterCard}
                    />
                  </span>
                </div>
                <div className="flex mt-[7px]">
                  <span className="icon-container">
                    <Image
                      width={48}
                      unoptimized
                      height="100%"
                      src={ApplePay}
                      alt="Apple Pay"
                    />
                  </span>
                  <span className="icon-container">
                    <Image
                      width={48}
                      unoptimized
                      height="100%"
                      alt="Google Pay"
                      src={GooglePay}
                    />
                  </span>
                  <span className="icon-container">
                    <Image
                      width={48}
                      alt="Amex"
                      src={Amex}
                      unoptimized
                      height="100%"
                    />
                  </span>
                </div>

                <div className="custom-recaptcha mt-[7px]">
                  <div className=" w-full h-full xs:max-w-[70px] max-w-full flex xs:flex-row flex-col justify-center items-center">
                    <Image
                      width={40}
                      unoptimized
                      height="100%"
                      src={Recaptcha}
                      alt="recaptcha sumbol"
                      className="xs:w-[40px] w-[28px] xs:h-[40px] h-[28px]"
                    />
                    <div className="xs:hidden block text-minor-text-500 text-primary-black text-center mt-2">
                      <p className="leading-[6px]">reCAPTCHA</p>
                      <p>
                        <Link
                          prefetch={false}
                          href={"https://policies.google.com/privacy"}
                        >
                          Privacy
                        </Link>
                        -
                        <Link
                          prefetch={false}
                          href={"https://policies.google.com/terms"}
                        >
                          Terms
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="xs:bg-primary-blue-30 w-full h-full xs:flex hidden flex-col justify-center pl-4 gap-[6px]">
                    <div className="flex flex-col">
                      <p className="text-body-text-13-lb">protected by </p>
                      <p className="text-body-text-13-lb ">reCAPTCHA</p>
                    </div>
                    <p className="flex items-center">
                      <Link
                        prefetch={false}
                        href={"https://policies.google.com/privacy"}
                        className="text-minor-text-mb-1-sb font-normal"
                      >
                        Privacy
                      </Link>
                      <span className="text-minor-text-mb-1-sb">-</span>

                      <Link
                        prefetch={false}
                        href={"https://policies.google.com/terms"}
                        className="text-minor-text-mb-1-sb font-normal"
                      >
                        Terms
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col w-12/12">
              <div className="flex flex-row justify-center items-center  border-t-[1px] border-t-secondary xs:py-5 pt-6 pb-[80px]">
                <div className="flex xs:flex-row flex-col">
                  <div className="flex">
                    <div className="flex items-center xs:text-body-text-3 text-minor-text-1 xs:mb-0 mb-12 xs:pl-1 pl-0">
                      {t("footer.copyright", {
                        year: new Date().getFullYear(),
                      })}
                    </div>
                    <span className="xs:inline-block text-tertiary-blue-50 text-body-text-3 mx-[10px] hidden">
                      |
                    </span>
                  </div>
                  <div className="flex items-end">
                    <span className="text-body-text-3-sb underline">
                      <Link prefetch={false} href={routes.termsConditions}>
                        {t("footer.terms_conditions")}
                      </Link>
                    </span>
                    <span className="inline-block text-tertiary-blue-50 text-body-text-3 mx-2">
                      |
                    </span>
                    <span className="text-body-text-3-sb underline xs:pl-1 pl-0">
                      <Link prefetch={false} href={routes.privacyPolicy}>
                        {t("footer.privacy")}
                      </Link>
                    </span>
                    <span className="inline-block text-tertiary-blue-50 text-body-text-3 mx-2">
                      |
                    </span>
                    <span className="text-body-text-3-sb underline xs:pl-1 pl-0">
                      <Link prefetch={false} href={routes.cookiePolicy}>
                        {t("footer.cookie_policy")}
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

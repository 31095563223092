import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: 0,
  miniTrolley: false,
  activeMenuKey: null,
  activeSidebar: false,
  activeAlphabet: null,
  appInitailized: false,
  isBasketLoader: false,
};

const uiState = createSlice({
  name: "uiState",
  initialState,
  reducers: {
    startCommonLoading(state) {
      state.loading += 1;
    },
    endCommonLoading(state) {
      if (state.loading > 0) {
        state.loading -= 1;
      }
    },
    setMiniTrolley(state, action) {
      state.miniTrolley = action.payload;
    },
    setActiveSidebar(state, action) {
      state.activeSidebar = action.payload;
    },
    setActiveMenuKey(state, action) {
      state.activeMenuKey = action.payload;
    },
    setActiveAlphabet(state, action) {
      state.activeAlphabet = action.payload;
    },
    updateAppInitailized(state, action) {
      state.appInitailized = action.payload;
    },
    updateIsBasketLoader(state, action) {
      state.isBasketLoader = action.payload;
    }
  },
});

export const {
  startCommonLoading,
  endCommonLoading,
  setMiniTrolley,
  setActiveSidebar,
  setActiveMenuKey,
  setActiveAlphabet,
  updateAppInitailized,
  updateIsBasketLoader
} = uiState.actions;

export const getActiveMenuKey = (state) => state.uiState.activeMenuKey;

export default uiState;

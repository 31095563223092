"use client";

import Link from "next/link";
import Image from "next/image";
import { useCallback } from "react";
import Home from "@/assets/static/home.svg?url";
import { useTouchHandler } from "@/lib/hooks/useTouchHandler";
import { usePageBreadcrumb } from "@/lib/hooks/usePageBreadcrumb";
import { getBreadcrumbSchema } from "@/lib/utils/helpers/metadata";
import "@/assets/css/pages/breadcrumb.css";


const Breadcrumb = ({ paths = [], onDoubleTap }) => {
  const {
    methods: { router },
    data: { breadcrumbPaths },
  } = usePageBreadcrumb({ pathArr: paths });

  const total = breadcrumbPaths.length;
  const touchHandlers = useTouchHandler(onDoubleTap);
  // Handler for touch events that also navigates without preventDefault
  const handleTouch = useCallback(
    (url) => () => {
      // Navigate using router.push
      router.push(url);
    },
    [router],
  );

  return (
    <nav aria-label="breadcrumb" className="breadcrumbBox">
      <div className="container">
        <div className="row">
          <div className="col">
            <ol className="breadcrumb">
              {breadcrumbPaths?.map?.((path, i) => {
                const isLast = i === total - 1;
                return (
                  <li
                    key={path.url + i}
                    className={`breadcrumb-item ${isLast ? "active" : ""}`}
                  >
                    {path?.url ? (
                      <Link
                        href={path.url}
                        {...touchHandlers}
                        prefetch={false}
                        onClick={handleTouch(path.url)}
                      >
                        <span className="breadcrumb-title">{path.title}</span>
                        {i === 0 && (
                          <Image
                            width={15}
                            src={Home}
                            alt="Home"
                            height="auto"
                          />
                        )}
                      </Link>
                    ) : (
                      path.title
                    )}
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </div>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(getBreadcrumbSchema(breadcrumbPaths)),
        }}
      />
    </nav>
  );
};

export default Breadcrumb;

import Image from "next/image";
import Map from "@/assets/icon/location_on.svg?url";
import Pointer from "@/assets/static/pointerBlue.svg?url";
import Truck from "@/assets/static/local_shipping.svg?url";

const DeliveryOption = ({
  t,
  product,
  productBranch,
  showCheckStock,
  showBranchModal,
  muteClickCollect,
  homeDeliveryStock,
  isPDP = false,
  stock_in_branch = 0
}) => {
  let clickCollectMessage;

  if (muteClickCollect) {
    clickCollectMessage = t("product.delivery_points.click_and_collect_unavailable");
  } else if (productBranch?.branch_id) {
    clickCollectMessage = t("product.delivery_points.click_and_collect_available");
  } else {
    clickCollectMessage = t("product.delivery_points.free_click_and_collect");
  }

  return (
    <ul className={`w-12/12 p-0 m-0 ${!isPDP && "lg:block hidden"}`}>
      <li className={`flex items-center ${isPDP ? "mb-[6px]" : "mb-2 "}`}>
        <div className="w-5">
          <Image
            width={20}
            height={20}
            src={Truck}
            alt="Timeline"
            className={`w-auto ${!homeDeliveryStock ? "image-grayout" : ""}`}
          />
        </div>
        <span
          className={`text-body-text-3-m  ms-[6px] m-0 ${homeDeliveryStock
            ? "text-primary-blue-900"
            : "text-primary-black-400"
            }`}
        >
          {homeDeliveryStock
            ? t("product.delivery_points.free_home_delivery")
            : t("product.delivery_points.home_delivery_unavailable")}
        </span>
      </li>

      <li className="flex items-center mb-2">
        <div className="w-5">
          <Image
            width={20}
            height={20}
            alt="Timeline"
            src={Pointer}
            className={`w-auto ${muteClickCollect ? "image-grayout" : ""}`}
          />
        </div>
        <span
          className={`text-body-text-3-m ms-[6px] m-0 ${muteClickCollect
            ? "text-primary-black-400"
            : "text-primary-blue-900"
            }`}
        >
          {clickCollectMessage}
        </span>
      </li>

      {showCheckStock && (
        <li
          className=" flex mb-2 animation-fadeIn"
          onClick={() => showBranchModal(product)}
        >
          <div className="w-5">
            <Image
              width={20}
              height={20}
              alt="Map"
              src={Map}
              className="w-auto"
            />
          </div>
          <div className="flex items-start flex-wrap">

          {productBranch?.name?.length > 0 && (
            <span className="text-body-text-3-m text-primary-blue-900 ms-[6px] m-0 w-auto break-all">
              {productBranch.name}  {(stock_in_branch > 0 && stock_in_branch <= 5) &&
                <span className="text-tertiary-red  text-body-text-3-sb ">  {t("common:product.stock_in_branch", { stock: stock_in_branch })}</span>
              }

            </span>
          )}
          
         
          <span className="text-body-text-3-sb text-primary-blue-900 ms-[6px] m-0 underline hover:no-underline cursor-pointer">
            {productBranch?.name
              ? t("product.delivery_points.change_branch")
              : t("product.delivery_points.check_stock")}
          </span>
          </div>
        </li>
      )}
    </ul>
  );
};

export default DeliveryOption;

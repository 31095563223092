import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

//Import your reducers here.
import customer from "./reducers/customer";
import cart from "./reducers/cart";
import makeOptions from "./reducers/makeOptions";
import vehicle from "./reducers/vehicle";
import globalAssets from "./reducers/globalAssets";
import misc from "./reducers/misc"
import uiState from "./reducers/uiState"

export const makeStore = () => {
  return configureStore({
    reducer: {
      [customer.name]: customer.reducer,
      [cart.name]: cart.reducer,
      [makeOptions.name]: makeOptions.reducer,
      [vehicle.name]:vehicle.reducer,
      [globalAssets.name]:globalAssets.reducer,
      [misc.name]:misc.reducer,
      [uiState.name]:uiState.reducer,
    },
    devTools: process.env.NODE_ENV !== "production",
  });
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes();
export const useAppSelector = useSelector.withTypes();

"use client";

import dynamic from "next/dynamic";

const MobileBlueHeader = dynamic(() => import("./MobileBlueHeader"));
const DesktopBlueHeader = dynamic(() => import("./DesktopBlueHeader"));

const TopBlueHeader = ({ t, isSmallScreen }) => {
  return (
    <div className="topBlueHeader">
      <div className="container">
        {!isSmallScreen ? (
          <DesktopBlueHeader t={t} />
        ) : (
          <MobileBlueHeader t={t} />
        )}
      </div>
    </div>
  );
};

export default TopBlueHeader;

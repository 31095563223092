"use client";

import Cookies from "js-cookie";
import { constants } from "../utils/constants";
import { useTranslation } from "@/i18n/client";
import { useSearchParams } from "next/navigation";
import useTrolleyUtility from "./useTrolleyUtility";
import { handleErrorLog } from "../utils/error_logs";
import { getProductListing } from "../services/product";
import { useAppDispatch, useAppSelector } from "../store";
import { useCallback, useEffect, useRef, useState } from "react";
import { getNearestBranches, getProductsStocks } from "../services/api";
import {
  setCnCBranchThunk,
  resetCnCBranchThunk,
  fetchCartForGuestUser,
  fetchCartForLoggedInUser,
} from "../store/reducers/cart";
import {
  setToStorage,
  getFromStorage,
  updateProductInfo,
  branchFormattedDetail,
  updateMetaInfo,
} from "../utils/helpers/plp";
import {
  scrollToElement,
  getGeocodeLatLng,
  pushHistoryState,
  handleToastError,
  formatGeocodeError,
  sortArrayOfObjects,
  handleThunkResponse,
  isSSR,
} from "../utils";
import { updateShowCheckStock } from "../store/reducers/globalAssets";
import { updateBranch, updateManualSessionId } from "../store/reducers/misc";
import {
  endCommonLoading,
  startCommonLoading,
} from "../store/reducers/uiState";

export const useProductListing = ({
  initial,
  pageSize,
  categoryid,
  searchParams,
  hasManualLook,
  componentId = "",
  categoryIdParam = false,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["product-listing", "common"]);
  const { appInitailized } = useAppSelector((state) => state.uiState);

  const { manualSessionId, branch: globalBranch } = useAppSelector(
    (state) => state.misc,
  );

  const { vehicle } = useAppSelector((state) => state.vehicle);

  const {
    methods: { handleAddToTrolley },
  } = useTrolleyUtility();

  let serverRes = { ...initial };
  const queryParam = useSearchParams();
  const urlPage = queryParam.get("page");
  if (urlPage && urlPage != serverRes?.page) {
    serverRes = {
      page: urlPage,
      products: [],
      product_total: 0,
    };
  }

  const productsList = useRef({});
  const appliedFilters = useRef(null);
  const productFitmentInfo = useRef([]);
  const vehicleSpecificSKU = useRef([]);
  const isManual = useRef(hasManualLook);
  const pageNumber = useRef(parseInt(serverRes.page));
  const vehicleInfo = useRef(initial.selected_vehicle);
  const totalProducts = useRef(serverRes.product_total);
  const showVehicleSpecific = useRef(!!initial.selected_vehicle);

  const [isFetching, setIsFetching] = useState(false);
  const [pageLimit, setPageLimit] = useState(pageSize);
  const [sortOrder, setSortOrder] = useState(initial.sort_order);
  const [products, setProducts] = useState([...serverRes.products]);

  const [hasVehicle, setHasVehicle] = useState(!!initial.selected_vehicle);

  const [branchList, setBranchList] = useState([]);
  const [branchModalOpen, setBranchModalOpen] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isResetingBranch, setIsResetingBranch] = useState(false);
  const [fitmentModalOpen, setFitmentModalOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState(
    sortArrayOfObjects(initial.filter_options, "label"),
  );

  const getBranches = useCallback(async ({ coords, limit = 3 }) => {
    const skus = productsList.current?.items.map((p) => p.sku);
    const res = await getNearestBranches({ ...coords, skus, limit });

    if (
      res?.branches &&
      Array.isArray(res.branches) &&
      res.branches.length > 0
    ) {
      if (limit === 1) return res.branches[0];
      return res.branches;
    }
    return null;
  }, []);

  const addProductReviewsAndStocks = useCallback(
    async ({ items = [], total_count = 0 }) => {
      let metaInfo = {};
      if (!categoryIdParam) {
        if (items.length > 0) {
          let branch = globalBranch || {};
          const skus = items.map((i) => i.sku);
          if (!branch?.branch_id) {
            branch = getFromStorage(constants.localStorage.selectedBranch);
          }

          let stockRes = {};
          if (branch?.branch_id) {
            stockRes = await getProductsStocks({
              branchCode: branch.branch_id,
              hubCode: branch?.is_hub || "",
              sku: skus,
            });
            setBranchList(() => []);
          }

          // // Wait for the promises to resolve
          // const [reviewsRes, stockRes = {}] = await Promise.all([
          //   fetchProductsReviews(skus),
          //   ...requests,
          // ]);

          skus.forEach((sku) => {
            const hubStock = stockRes?.hub?.find((i) => i.sku === sku);
            const branchStock = stockRes?.products?.find((i) => i.sku === sku);
            // const review = reviewsRes?.reviews?.find((r) => r.sku === sku);

            metaInfo[sku] = {
              branch,
              review: null,
              query_text: branch?.name || "",
              hub_stock: hubStock?.freeStock || 0,
              branch_stock: branchStock?.freeStock || 0,
            };
          });
        }
      }

      productsList.current = { items, metaInfo };
      setProducts(() =>
        updateProductInfo(
          items,
          categoryIdParam ? false : !initial.is3BO,
          metaInfo,
        ),
      );
      totalProducts.current = total_count;
    },
    [globalBranch, categoryIdParam, initial],
  );

  const getQueryParams = useCallback(
    (selectedPage, selectedFilters, pushState) => {
      let queryParamObj = { page: selectedPage };
      if (isManual.current && !hasVehicle) {
        queryParamObj["make"] = searchParams?.make;
        queryParamObj["model"] = searchParams?.model;
        queryParamObj["ref"] = searchParams?.ref;
      }
      if (queryParamObj.page == 1) {
        delete queryParamObj.page;
      }

      if (pushState) {
        const queryStr = new URLSearchParams(queryParamObj).toString();
        pushHistoryState({ url: queryStr ? `?${queryStr}` : "" });
      }

      let paramsRes = {};
      selectedFilters.forEach((filter) => {
        if (!paramsRes[filter.code]) paramsRes[filter.code] = [];
        paramsRes[filter.code].push(filter.value);
      });

      return paramsRes;
    },
    [hasVehicle, categoryid, categoryIdParam, isManual, searchParams],
  );

  const fetchProducts = useCallback(
    async (options = {}) => {
      dispatch(startCommonLoading());
      dispatch(updateShowCheckStock(false));

      const { limitOpt, sortProductBy, filterOpts, pushState = true } = options;
      let reqData = {
        skuList: null,
        vFilter: null,
        sessionInfo: null,
        currentVehicle: vehicleInfo.current,
        sortOpt: sortProductBy || sortOrder,
        specialCoupon: searchParams?.auto_apply_coupon || null,
      };

      if (!isSSR()) {
        const spVal = Cookies.get(constants.cookies.specialCoupon) || {};
        const sParams = new URLSearchParams(window.location.search);
        if (sParams.has("auto_apply_coupon")) {
          reqData.specialCoupon = sParams.get("auto_apply_coupon");
        } else {
          reqData.specialCoupon = spVal || null;
        }
      }

      if (initial.is3BO) {
        if (showVehicleSpecific.current) {
          reqData.sessionInfo = reqData.currentVehicle?.session;
          isManual.current = queryParam.has("make") && queryParam.has("model");
          if (!reqData.sessionInfo && isManual.current && manualSessionId) {
            reqData.sessionInfo = { sessionId: manualSessionId };
          }
        }
      } else if (initial.isCarParts) {
        reqData.sessionInfo = reqData.currentVehicle?.session;
        isManual.current = queryParam.has("make") && queryParam.has("model");
        if (!reqData.sessionInfo && isManual.current && manualSessionId) {
          reqData.sessionInfo = { sessionId: manualSessionId };
        }
      }

      if (reqData.sessionInfo) {
        if (!componentId) {
          setProducts([]);
          setFilterOptions([]);
          pageNumber.current = 1;
          updateMetaInfo(1);
          totalProducts.current = 0;
          dispatch(endCommonLoading());
          handleErrorLog({
            error: "",
            is_warning: true,
            msg: "component id does not exist for this category",
          });
          return;
        }
        reqData.skuList = isManual.current ? [] : vehicleSpecificSKU.current;
        reqData.vFilter = {
          component_id: componentId,
          session_id: reqData.sessionInfo?.sessionId,
          vrm: reqData?.currentVehicle?.vehicle?.vrm || "",
          make: reqData?.currentVehicle?.vehicle?.make || "",
          model: reqData?.currentVehicle?.vehicle?.model || "",
          year:
            (reqData?.currentVehicle.vehicle?.modelVersion?.match(
              /\(([^)]+)\)/,
            ) || [])[1] ||
            reqData?.currentVehicle.vehicle?.dateOfManufacture?.match(
              /^(\d{4})/,
            )[0] ||
            "",
          engine: reqData?.currentVehicle?.vehicle?.engineSize || "",
          body: reqData?.currentVehicle?.vehicle?.bodyStyle || "",
          hp: reqData?.currentVehicle?.vehicle?.power || "",
        };
      }

      setIsFetching(() => true);
      let pageSize = limitOpt || pageLimit;
      if (initial?.is3BO) {
        pageSize = limitOpt || pageLimit;
      } else if (initial?.isCarParts) {
        pageSize = 100;
      }

      const { data, errors } = await getProductListing({
        pageSize,
        categoryid: categoryid,
        skuList: reqData.skuList,
        page: pageNumber.current,
        sortOrder: reqData.sortOpt,
        vehicleFilter: reqData.vFilter,
        specialCoupon: reqData.specialCoupon,
        filtersObj: getQueryParams(
          pageNumber.current,
          filterOpts || appliedFilters.current,
          pushState,
        ),
      });

      if (errors) {
        let errMsg = errors;
        if (Array.isArray(errors) && errors.length > 0) {
          errMsg = errors[0]?.message || "";
        }

        if (
          reqData.vFilter &&
          errMsg === constants.service_response.unavailable
        ) {
          handleToastError(t("messages.service_unavailable"));
        } else if (errMsg) {
          handleToastError(errMsg);
        }

        setProducts(() => []);
        setFilterOptions(() => []);
        setIsFetching(() => false);
      } else if (data?.products?.items && Array.isArray(data.products.items)) {
        if (reqData.sessionInfo) {
          vehicleSpecificSKU.current = data.products.items.map((p) => p.sku);
        }

        const appliedArr = [];
        const sortedArr = sortArrayOfObjects(
          data.products?.aggregations?.filter(
            (a) => a?.attribute_code !== "category_uid",
          ),
          "label",
        );

        setFilterOptions(sortedArr);
        appliedFilters.current.forEach((i) => {
          sortedArr.forEach((item) => {
            if (item.attribute_code === i.code) {
              item.options.forEach((op) => {
                if (op.value === i.value) {
                  appliedArr.push({ ...i, count: op.count });
                }
              });
            }
          });
        });

        appliedFilters.current = appliedArr;
        await addProductReviewsAndStocks(data.products);
        setIsFetching(() => false);
      }
      dispatch(endCommonLoading());
      dispatch(updateShowCheckStock(true));
    },
    [
      initial,
      dispatch,
      isManual,
      sortOrder,
      pageLimit,
      categoryid,
      componentId,
      getQueryParams,
      manualSessionId,
      addProductReviewsAndStocks,
    ],
  );

  const handleVehicleSpecific = useCallback(
    (e) => {
      const { id } = e.target;
      const isShowAll = id === "show_all";
      if (!isShowAll && !hasVehicle) {
        document
          .querySelector("#current-vehicle")
          .classList.add("border-danger");
        scrollToElement("current-vehicle");
        const showAll = document.querySelector("#show_all");
        e.target.checked = false;
        showAll.checked = true;
        return;
      }

      if (isShowAll) {
        dispatch(startCommonLoading());
        dispatch(updateManualSessionId(null));
        pageNumber.current = 1;
        updateMetaInfo(1);
        isManual.current = false;
        appliedFilters.current = [];
        showVehicleSpecific.current = false;
        fetchProducts({ filterOpts: [] });
        dispatch(endCommonLoading());
        return;
      }

      pageNumber.current = 1;
      updateMetaInfo(1);
      appliedFilters.current = [];
      showVehicleSpecific.current = !isShowAll;
      fetchProducts({ filterOpts: [] });
    },
    [hasVehicle, manualSessionId, fetchProducts],
  );

  const handlePageChange = useCallback(
    (pageNo) => {
      setBranchList(() => []);
      pageNumber.current = pageNo;
      fetchProducts();
    },
    [fetchProducts],
  );

  const handleSortingSelection = useCallback(
    (sorting) => {
      pageNumber.current = 1;
      updateMetaInfo(1);
      setSortOrder(sorting);
      setBranchList(() => []);
      fetchProducts({ sortProductBy: sorting });
    },
    [fetchProducts],
  );

  const handleFilterSelection = useCallback(
    (filterArr) => {
      pageNumber.current = 1;
      updateMetaInfo(1);
      appliedFilters.current = filterArr;
      fetchProducts({ filterOpts: filterArr });
    },
    [fetchProducts],
  );

  const handlePageSelection = useCallback(
    (limit) => {
      pageNumber.current = 1;
      updateMetaInfo(1);
      setPageLimit(limit);
      fetchProducts({ limitOpt: limit });
    },
    [fetchProducts],
  );

  const toggleFitmentInfo = useCallback(
    (fitment) => {
      const isModalOpen = !fitmentModalOpen;
      const info = fitmentModalOpen ? [] : fitment;
      productFitmentInfo.current = info;
      setFitmentModalOpen(isModalOpen);
    },
    [fitmentModalOpen],
  );

  const handleCheckStock = useCallback(
    async (e) => {
      try {
        dispatch(startCommonLoading());
        const customerToken = Cookies.get(constants.cookies.customerToken);
        const cartId = localStorage.getItem(constants.localStorage.cartId);

        if (!cartId) {
          if (!customerToken) {
            await dispatch(fetchCartForGuestUser()).then(handleThunkResponse);
          } else {
            await dispatch(fetchCartForLoggedInUser()).then(
              handleThunkResponse,
            );
          }
        }

        const coords = await getGeocodeLatLng(e?.postCode);
        const nearestBranch = await getBranches({ coords, limit: 1 });

        if (nearestBranch) {
          setToStorage(constants.localStorage.selectedBranch, nearestBranch);
          dispatch(updateBranch(nearestBranch));
          const cncRes = await dispatch(
            setCnCBranchThunk(branchFormattedDetail(nearestBranch)),
          );
          handleThunkResponse(cncRes);

          // Updating branch stock details
          let { items, metaInfo } = productsList.current;
          for (const skuKey in metaInfo) {
            if (Object.hasOwnProperty.call(metaInfo, skuKey)) {
              const hubStock = nearestBranch?.hub?.find(
                (i) => i.sku === skuKey,
              );
              const branchStock = nearestBranch.products.find(
                (p) => p.sku === skuKey,
              );

              metaInfo[skuKey] = {
                ...metaInfo[skuKey],
                branch: nearestBranch,
                query_text: nearestBranch?.name,
                hub_stock: hubStock?.stock_qty || 0,
                branch_stock: branchStock?.stock_qty || 0,
              };
            }
          }

          productsList.current = { items, metaInfo };
          setProducts(() => updateProductInfo(items, !initial.is3BO, metaInfo));

          dispatch(endCommonLoading());
        } else {
          handleToastError(t("messages.no_results.branch"));
          dispatch(endCommonLoading());
        }
      } catch (err) {
        console.error(err);
        handleToastError(formatGeocodeError(err?.code));
      } finally {
        dispatch(endCommonLoading());
      }
    },
    [dispatch, getBranches, initial.is3BO, t],
  );

  const toggleBranchModal = useCallback(
    async (product = false) => {
      let modalStatus = 0;
      if (product) {
        modalStatus = branchList.length ? 2 : 1;
        if (
          globalBranch?.branch_id &&
          globalBranch?.latitude &&
          globalBranch?.longitude &&
          !branchList.length
        ) {
          dispatch(startCommonLoading());
          const list = await getBranches({
            coords: {
              lat: globalBranch.latitude,
              lng: globalBranch.longitude,
            },
          });

          let branches = [];
          if (list && Array.isArray(list)) {
            branches = list.map((b) => ({
              ...b,
              selected: globalBranch?.branch_id === b.branch_id,
            }));
            modalStatus = 2;
          }
          setBranchList(() => branches);
          dispatch(endCommonLoading());
        }
      }
      setSelectedProduct(product);
      setBranchModalOpen(modalStatus);
    },
    [branchList, dispatch, getBranches, globalBranch],
  );

  const searchBranches = useCallback(
    async (e) => {
      try {
        dispatch(startCommonLoading());
        const coords = await getGeocodeLatLng(e?.postCode);
        const list = await getBranches({ coords });
        let branches = [];
        if (list && Array.isArray(list)) {
          branches = list.map((b) => ({
            ...b,
            selected: globalBranch?.branch_id === b.branch_id,
          }));
        }
        setSelectedProduct((prev) => ({ ...prev, query_text: e?.postCode }));
        setBranchList(() => branches);
        setBranchModalOpen(2);
        dispatch(endCommonLoading());
      } catch (error) {
        dispatch(endCommonLoading());
        handleToastError(formatGeocodeError(error?.code));
        console.error(error);
      }
    },
    [dispatch, getBranches, globalBranch],
  );

  const onBranchRadioSelection = useCallback((branchId) => {
    setBranchList((prev) =>
      prev.map((b) => ({ ...b, selected: branchId === b.branch_id })),
    );
  }, []);

  const handleBranchSelection = useCallback(async () => {
    let branch = branchList.find((b) => b.selected);
    if (!branch) {
      handleToastError(t("messages.error.invalid_branch"));
      return;
    }

    toggleBranchModal(false);
    setToStorage(constants.localStorage.selectedBranch, branch);
    setProducts((prev) => {
      return prev.map((p) => {
        let info = { ...p, branch };
        const brands = info.brands.map((brand) => {
          const hubStock = branch?.hub?.find((i) => i.sku === brand.sku);
          const branchStock = branch?.products?.find(
            (i) => i.sku === brand.sku,
          );

          return {
            ...brand,
            branch_stock: branchStock ? branchStock?.stock_qty || 0 : 0,
            hub_stock: hubStock ? hubStock?.stock_qty || 0 : 0,
          };
        });

        info.brands = brands;
        return info;
      });
    });

    const res = await dispatch(
      setCnCBranchThunk(branchFormattedDetail(branch)),
    );
    handleThunkResponse(res);
  }, [branchList, toggleBranchModal, dispatch, t]);

  const removeBranch = useCallback(async () => {
    setIsResetingBranch(true);
    handleThunkResponse(await dispatch(resetCnCBranchThunk()));
    setBranchList(() => []);
    setProducts((prev) => {
      return prev.map((p) => {
        const brands = p.brands.map((brand) => ({ ...brand, branch_stock: 0 }));
        return { ...p, brands, branch: {} };
      });
    });
    setIsResetingBranch(false);
  }, [dispatch]);

  const handleBrandChange = useCallback((articleNo, brandId, index) => {
    setProducts((prevState) => {
      return prevState.map((p) => {
        let item = { ...p };
        if (articleNo === item.article_number) {
          const brands = item.brands.map((b, i) => ({
            ...b,
            is_selected: index === i && b.id === brandId,
          }));
          item.brands = brands;
        }
        return item;
      });
    });
  }, []);

  const applyQueryFilters = useCallback(
    (filterOpts) => {
      const options = sortArrayOfObjects(initial.filter_options, "label");
      try {
        options.forEach((f) => {
          if (searchParams?.[f.attribute_code]) {
            let fArr = [];
            if (searchParams[f.attribute_code]) {
              fArr = searchParams[f.attribute_code].split(",");
            }
            f.options.forEach((o) => {
              if (fArr.includes(o.value)) {
                if (!Array.isArray(filterOpts)) {
                  filterOpts = [];
                }
                filterOpts.push({
                  ...o,
                  filter_label: f.label,
                  code: f.attribute_code,
                });
              }
            });
          }
        });
      } catch (err) {
        console.error(err);
      }

      return filterOpts || [];
    },
    [initial, searchParams],
  );

  useEffect(() => {
    if (categoryIdParam) {
      pageNumber.current = 1;
      updateMetaInfo(1);
    }
  }, [categoryid]);

  useEffect(() => {
    if (vehicle) {
      if (
        vehicleInfo.current?.session?.sessionId === vehicle?.session?.sessionId
      ) {
        return;
      }
    } else if (Cookies.get(constants.cookies.selectedVehicle)) {
      return;
    }

    vehicleInfo.current = vehicle;
    setHasVehicle(!!vehicle);
  }, [vehicle]);

  useEffect(() => {
    const currentVehicle = vehicleInfo.current;
    const hasNoVehicle = !currentVehicle?.session?.sessionId;
    const sParams = new URL(window.location.href).searchParams;
    isManual.current = sParams.has("make") && sParams.has("model");

    showVehicleSpecific.current = currentVehicle !== null;
    if (!showVehicleSpecific.current && isManual.current) {
      showVehicleSpecific.current = true;
    }

    let stopFetching = false;
    if (initial.is3BO) {
      if (showVehicleSpecific.current) {
        stopFetching = !componentId;
        if (!stopFetching && hasNoVehicle) {
          stopFetching = isManual.current;
        }
      }
    } else if (initial.isCarParts) {
      stopFetching = isManual.current || hasNoVehicle;
    }

    if (stopFetching) {
      setProducts(() => []);
      setFilterOptions(() => []);
      pageNumber.current = 1;
      updateMetaInfo(1);
      totalProducts.current = 0;
      return;
    }

    let filterOpts;
    if (!appliedFilters.current) {
      appliedFilters.current = applyQueryFilters(filterOpts);
      filterOpts = appliedFilters.current;
    } else {
      appliedFilters.current = [];
    }

    const newVehicleSelected = Cookies.get(constants.cookies.new_vehicle);
    if (newVehicleSelected) {
      pageNumber.current = 1;
      updateMetaInfo(1);
      Cookies.remove(constants.cookies.new_vehicle);
    }

    isManual.current = false;
    fetchProducts({ filterOpts });
    scrollToElement("product-list");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasVehicle]);

  useEffect(() => {
    const handleBrowserBackAndForward = () => {
      const sParams = new URLSearchParams(window.location.search);
      setBranchList(() => []);
      pageNumber.current = sParams.has("page")
        ? Number(sParams.get("page"))
        : 1;
      fetchProducts({ pushState: false });
    };

    window.addEventListener("popstate", handleBrowserBackAndForward);
    return () => {
      window.removeEventListener("popstate", handleBrowserBackAndForward);
    };
  }, []);

  return {
    data: {
      products,
      sortOrder,
      pageLimit,
      branchList,
      isFetching,
      queryParam,
      globalBranch,
      filterOptions,
      appInitailized,
      selectedProduct,
      branchModalOpen,
      manualSessionId,
      fitmentModalOpen,
      isResetingBranch,
      showVehicleSpecific,
      vehicle: vehicleInfo.current,
      currentPage: pageNumber.current,
      totalProducts: totalProducts.current,
      filters: appliedFilters?.current || [],
      fitmentInfo: productFitmentInfo.current,
      vehicleSpecificSKU: vehicleSpecificSKU.current,
    },
    methods: {
      t,
      removeBranch,
      searchBranches,
      handlePageChange,
      handleCheckStock,
      toggleBranchModal,
      toggleFitmentInfo,
      handleBrandChange,
      handleAddToTrolley,
      handlePageSelection,
      handleVehicleSpecific,
      handleFilterSelection,
      handleBranchSelection,
      onBranchRadioSelection,
      handleSortingSelection,
    },
  };
};

export default useProductListing;

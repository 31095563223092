"use client";

import Link from "next/link";
import Image from "next/image";
import dynamic from "next/dynamic";
import { routes } from "@/lib/utils";
import { useAppSelector } from "@/lib/store";
import { useTranslation } from "@/i18n/client";
import Button from "../common/shared/Button/button";
import NoRecordFound from "@/assets/image/no-record-found.svg?url";
import GsfMailIcon from "../common/shared/Button/SvgIcons/GsfMailIcon";
const ZendeskWidgetManger = dynamic(
  () => import("@/components/common/zendesk"),
  {
    ssr: false,
  },
);

const NoResult = ({
  base_msg = "",
  msg = "",
  className = "",
  showZendesk = true,
}) => {
  const { GSF_telephone } = useAppSelector((state) => state.globalAssets);
  const { t } = useTranslation(["common"]);

  const openZendesk = () => {
    if (window?.zE) {
      window.zE("messenger", "open");
    }
  };

  return (
    <>
      <ZendeskWidgetManger show={showZendesk} />
      <section className={`register ${className}`}>
        <div className="pnf-box max-w-[870px] w-full">
          <div className="flex flex-col gap-6 items-center border-b border-b-primary-black-100 pb-6 w-full">
            <Image
              width={200}
              height={200}
              src={NoRecordFound}
              alt="No Record Found"
              className="xs:w-[200px] max-w-[114px]"
            />
            <div>
              <h4 className="lg:text-headline-2 xs:text-headline-3 text-subtitle-2-b xs:mb-4 mb-2 text-primary-black text-center">
                {base_msg || t("common:messages.no_results.base")}
              </h4>
              {msg && (
                <p className="xs:text-body-text-1 text-body-text-3 text-primary-black-800 text-center xs:mb-8 mb-6">
                  {msg}
                </p>
              )}
            </div>

            <div
              className={`flex flex-wrap gap-3 xs:grid xs:gap-4 ${
                GSF_telephone ? "xs:grid-cols-3" : "xs:grid-cols-2"
              }`}
            >
              {GSF_telephone && (
                <Button
                  prefetch={false}
                  theme="blue-btn-xs"
                  icon="callIcon"
                  iconPosition="left"
                  className="font-semibold w-[calc(50%-6px)] xs:w-full"
                  type="link"
                  href={`tel:${GSF_telephone}`}
                >
                  {GSF_telephone}
                </Button>
              )}
              <Button
                theme="blue-btn-xs"
                icon="gsfChatIcon"
                iconPosition="left"
                onClick={openZendesk}
                className={`font-semibold xs:w-full ${
                  GSF_telephone ? "w-[calc(50%-6px)]" : "w-full"
                }`}
              >
                {t("common:messages.no_results.liveSupport")}
              </Button>
              <Link
                prefetch={false}
                className="font-semibold w-full blue-btn-xs"
                href={routes.contactUs}
              >
                <GsfMailIcon />
                {t("common:messages.no_results.contactUs")}
              </Link>
            </div>
          </div>
          <div className="pt-6 flex flex-col items-center gap-4 w-full">
            <Link
              prefetch={false}
              className="blue-bordered-btn"
              href={routes.parts}
            >
              {t("common:messages.no_results.browseParts")}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default NoResult;

import Link from "next/link";
import { constants } from "@/lib/utils";
import Accordian from "@/components/common/shared/Accordion";
import ImageWithFallback from "@/components/common/ImageWithFallback";

const SubCategoriesListing = ({ categories = [], queryString = "" }) => {
  const queryParam = queryString ? "?" + queryString : "";

  return (
    <div className="detail-tab accerroies-tab">
      <div className="main-content">
        {categories?.map((item, i) => {
          const {
            name = "",
            children = [],
            url_path = "",
            children_count = children?.length || 0,
          } = item || {};

          const hasChildItems = children_count > 0;
          return (
            <Accordian
              title={name}
              key={`${name}-${i}`}
              // defaultActive={i === 0}
              urlPath={`/${url_path + queryParam}`}
            >
              <div className="category-listing mb-0 accessories-listing">
                {children?.map?.((subCategory) => (
                  <Link prefetch={false}
                    key={subCategory?.id}
                    href={`/${subCategory?.url_path + queryParam}`}
                  >
                    <div className="w-12/12 py-2 lg:px-12 sm:px-2 xs:px-[3px] px-2 border border-primary-black-50 max-w-full rounded-xl bg-tertiary-white">
                      <div className="w-[132px] h-[132px] flex justify-center items-center mx-auto">
                        <ImageWithFallback
                          width={110}
                          height={110}
                          alt={subCategory.name}
                          src={subCategory.image}
                          type={constants.image_types.category}
                          className="w-full h-[auto] max-w-full max-h-full object-contain"
                        />
                      </div>
                      <p className="text-body-text-2-sb text-center mt-1">
                        {subCategory.name}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
              {hasChildItems && (
                <div className="pb-12 w-12/12 text-center xs:hidden xs:mt-0 mt-12">
                  <Link prefetch={false}
                    href={`/${item?.url_path + queryParam}`}
                    className="text-error underline text-body-text-3-b ml-auto"
                  >
                    View All
                  </Link>
                </div>
              )}
            </Accordian>
          );
        })}
      </div>
    </div>
  );
};

export default SubCategoriesListing;

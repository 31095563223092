"use client";

import { useEffect, useMemo, useState } from "react";
import Button from "../../../Button/button";
import FilterOptions from "./FilterOption";

const Filters = ({
  t,
  filters,
  isVisible,
  categoryId,
  totalSelected,
  totalProducts,
  filterOptions,
  toggleVisibility,
  handleFilterSelection,
}) => {
  const [selected, setSelected] = useState([...filters]);
  const applyFilters = () => {
    handleFilterSelection([...selected]);
    toggleVisibility();
  };

  const handleFilterChange = (item, isChecked = false, filterInfo = {}) => {
    let selection = [...selected];
    if (isChecked) {
      const exist = selection.some(
        (i) => i.code === filterInfo?.code && i.value === item.value,
      );
      if (!exist) selection.push({ ...item, ...filterInfo });
    } else {
      selection = [];
      selected.forEach((i) => {
        const unselectOpt =
          i.code === filterInfo?.code && i.value === item.value;
        if (!unselectOpt) selection.push(i);
      });

      const filterOption = document.querySelector(
        `#filter-option-${item.value}`,
      );
      if (filterOption) {
        filterOption.checked = false;
      }
    }
    setSelected(selection);
  };

  useEffect(() => {
    const handleAccordionClick = (event) => {
      const header = event.target.closest(".accordion-header");
      if (!header) return; // Exit if the click didn't happen on an accordion header

      const accordionContent =
        header.parentElement.querySelector(".accordion-content");
      const accordionMaxHeight = accordionContent.style.maxHeight;

      if (accordionMaxHeight === "0px" || accordionMaxHeight.length === 0) {
        accordionContent.style.maxHeight = `${
          accordionContent.scrollHeight + 16
        }px`;
        header.parentElement.classList.add("active");
      } else {
        accordionContent.style.maxHeight = "0px";
        header.parentElement.classList.remove("active");
      }
    };

    document.addEventListener("click", handleAccordionClick);
    return () => {
      document.removeEventListener("click", handleAccordionClick);
    };
  }, []);

  useEffect(() => {
    setSelected([...filters]);
  }, [filters, isVisible]);

  const selectedFilterCodes = useMemo(() => {
    return [...new Set(selected.map((i) => i?.code).filter(Boolean))];
  }, [selected]);

  return (
    <div className={`sideBar reverse ${isVisible ? "slide-in" : "slide-out"}`}>
      <div className="box">
        <div className="boxHeader">
          <p className="text-headline-5-sb text-primary-black">
            {t("filters.filter")}
          </p>
          <div className="flex">
            {totalSelected > 0 && (
              <Button
                theme="red-underline"
                className="me-12 custom-line"
                onClick={() => handleFilterSelection([])}
              >
                {t("filters.clear_filters") + " "}
                <span className="number flex-center">{totalSelected}</span>
              </Button>
            )}
            <Button
              theme="iconOnly"
              icon="greyClose"
              onClick={toggleVisibility}
              className="close-icon"
            />
          </div>
        </div>
        <div className="boxListing scrollbar">
          <div className="appliedFilters mb-4">
            <p className="text-primary-black-700 text-body-text-3 mb-2">
              {t("filters.products_found", { count: totalProducts })}
            </p>
          </div>

          <div className="accordionBox">
            {filterOptions.map((f) => {
              if (
                f?.attribute_code === "stop_start_compatible" &&
                categoryId !== 5992
              ) {
                return null;
              }

              return (
                <div key={f?.attribute_code} className="accordion-item">
                  <div className="accordion-header">
                    <p className="relative pr-4">
                      <span className="relative pr-4">
                        {f?.label + " "}
                        {selectedFilterCodes.includes(f?.attribute_code) && (
                          <span className="absolute top-0 right-[7px] bg-primary-red rounded-full w-[5px] h-[5px]"></span>
                        )}
                      </span>
                    </p>
                  </div>
                  <div className="filter accordion-content">
                    <ul className="h-full">
                      <FilterOptions
                        selected={selected}
                        filterLabel={f?.label}
                        options={f?.options || []}
                        filterCode={f?.attribute_code}
                        handleFilterChange={handleFilterChange}
                      />
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="boxFooter">
          <div className="flex flex-col px-4 py-12">
            <Button
              className="blue-btn"
              disabled={!filterOptions.length}
              onClick={applyFilters}
            >
              {t("button.apply_filter")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Filters;

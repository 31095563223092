import Image from "next/image";
import { useRef } from "react";
import Input from "./shared/Input/input";
import Button from "./shared/Button/button";
import { useTranslation } from "@/i18n/client";
import AddIcon from "@/assets/icon/add.svg?url";
import Remove from "@/assets/icon/remove.svg?url";

const CartActions = ({
  cartItem,
  handleQuantityInput,
  productState,
  handleEnterOnInput,
  handleMinus,
  handlePlus,
  showRemoveModal,
  homeDelivery,
  cncStockCheck,
  isCnCSelected,
  fromMinitrolly,
}) => {
  const { t } = useTranslation(["common", "basket"]);
  const formRef = useRef(null);
  const handleOnBlur = () => {
    formRef?.current?.requestSubmit?.();
  };

  return (
    <>
      <div className="cart-action-content">
        <Button
          theme="blue-btn"
          onClick={() => handleMinus({ cartItem, showRemoveModal })}
          className="h-auto xs:px-[13px] px-[7.4px] w-auto !rounded-tr-none !rounded-br-none"
        >
          <span className="xs:w-[13px] w-[12px] xs:h-[13px] h-[12px] relative flex">
            <Image fill src={Remove} alt="remove" />
          </span>
        </Button>
        <form
          ref={formRef}
          onSubmit={(e) => handleEnterOnInput(e, { cartItem, showRemoveModal })}
        >
          <Input
            type="number"
            pattern="[0-9]*"
            onBlur={handleOnBlur}
            value={productState?.quantity?.toString?.() || ""}
            onChange={(e) => handleQuantityInput(e, { cartItem })}
          />
        </form>
        <Button
          theme="blue-btn"
          onClick={() => handlePlus({ cartItem })}
          className="xs:px-[13px] px-[7.4px] w-auto !rounded-tl-none !rounded-bl-none h-auto"
        >
          <span className="xs:w-[13px] w-[12px] xs:h-[13px] h-[12px] relative flex">
            <Image
              fill
              alt="add"
              src={AddIcon}
              className="xs:w-[13px] xs:max-w-[13px] w-[12px] max-w-[13px]"
            />
          </span>
        </Button>
      </div>
      {!fromMinitrolly && (
        <>
          {!homeDelivery && !isCnCSelected && (
            <p className="text-body-text-3-sb text-primary-red xs:text-right xs:absolute xs:-bottom-[11px] w-12/12 right-0 xs:mt-0 mt-2">
              {t("basket:quantityUnavailableForHomeDelivery")}
            </p>
          )}
          {!cncStockCheck && isCnCSelected && (
            <p className="text-body-text-3-sb text-primary-red xs:text-right xs:absolute xs:-bottom-[11px] w-12/12 right-0 xs:mt-0 mt-2">
              {t("basket:quantityUnavailableForClick&Collect")}
            </p>
          )}
        </>
      )}
    </>
  );
};

export default CartActions;

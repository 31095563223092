import { keys } from "./keys";
import { getCurrentURL } from "./helpers/metadata";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { getContentfulImgData } from "../services/contentful";
import ContentfulLink from "@/components/common/shared/ContentfulLink";
import ContentfulEntry from "@/components/common/shared/ContentfulEntry";
import ContentfulAsset from "@/components/common/shared/ContentfulAsset";
import { documentToReactComponents as richTextReactRenderer } from "@contentful/rich-text-react-renderer";
import { updateImageURL } from ".";

/**
 * @param {{
 *  title: string,
 *  description: string,
 *  keywords: string[],
 *  follow: boolean,
 *  index: boolean,
 * 	canonicalUrl: string
 *  shareImage: any
 * }} seoFields
 *
 * @returns {import("next").Metadata}
 */
export const getMetadata = (seoFields, canonicalPath) => {
  if (!seoFields) return {};

  const img = getContentfulImgData(seoFields.shareImage);
  let shareImages = {};
  if (img?.imageUrl) {
    shareImages = {
      images: {
        url: img?.imageUrl,
        alt: img?.title || img?.fileName,
      },
    };
  }

  const enableBot = keys.general.enableBot === "1";
  return {
    title: seoFields.title,
    description: seoFields.description,
    keywords: seoFields.keywords,
    openGraph: {
      title: seoFields.title,
      description: seoFields.description,
      ...shareImages,
    },
    robots: {
      index: enableBot,
      follow: enableBot,
      googleBot: {
        index: enableBot,
        follow: enableBot,
      },
    },
    alternates: {
      canonical: getCurrentURL(canonicalPath),
    },
  };
};

/**
 * @param {import("@contentful/rich-text-types/dist/types").Document} content
 * @param {{
 * 	renderEmbeddedEntry: (contentType: string, fields: any) => React.ReactNode,
 * 	renderEmbeddedAsset: (fileType: string, fields: any, defaultUI: React.ReactNode) => React.ReactNode,
 * 	renderLink: (label: string, link: string) => React.ReactNode,
 *  renderingLevel: number
 * }} options
 * @returns {React.ReactNode}
 */
export const documentToReactComponents = (content, options = {}) => {
  return richTextReactRenderer(content, {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <ContentfulAsset
          node={node}
          renderEmbeddedAsset={options.renderEmbeddedAsset}
        />
      ),
      [INLINES.EMBEDDED_ENTRY]: (node) => (
        <ContentfulEntry
          node={node}
          renderEmbeddedEntry={options.renderEmbeddedEntry}
          renderingLevel={options.renderingLevel}
        />
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => (
        <ContentfulEntry
          node={node}
          renderEmbeddedEntry={options.renderEmbeddedEntry}
          renderingLevel={options.renderingLevel}
        />
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (node?.content?.length === 1 && !node.content[0].value) {
          return null;
        }
        return <p>{children}</p>;
      },
      [INLINES.HYPERLINK]: (node) => (
        <ContentfulLink node={node} renderLink={options.renderLink} />
      ),
    },
    preserveWhitespace: true,
  });
};

export const updateMengaMenuItems = (listArr = []) => {
  const recursiveMenuUpdate = (items, filterArr) => {
    if (!items) return filterArr;
    items.forEach(({ fields }) => {
      if (fields?.menuIcon) {
        const iconInfo = getContentfulImgData(fields.menuIcon);
        fields.menuIcon = updateImageURL(
          iconInfo.imageUrl,
          iconInfo.width,
          iconInfo.height,
          true,
        );
      }

      if (fields.hasChildItems) {
        fields.childItems = recursiveMenuUpdate(fields.childItems, []);
      }

      filterArr.push(fields);
    });
    return filterArr;
  };

  return recursiveMenuUpdate(listArr, []);
};

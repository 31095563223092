import React from "react";
import { keys } from "./keys";
import Cookies from "js-cookie";
import * as htmlEntity from "he";
import { routes } from "./routes";
import { encrypt } from "./crypto";
import { toast } from "react-toastify";
import { login, sign_up } from "../gtm";
import { constants } from "./constants";
import { getEncodedQueryString } from "./helpers/plp";
import { fetchCustomer } from "../store/reducers/customer";
import CustomToaster, {
  ProductToast,
} from "@/components/common/shared/CustomToaster";
import {
  fetchCartForLoggedInUser,
  fetchCartItems,
  fetchClickAndCollectStock,
  mergeCartsThunk,
  resetCartAddressThunk,
  setBillingAddressThunk,
  setCartId,
  setCnCBranchThunk,
  setShippingAddressThunk,
} from "../store/reducers/cart";
import { getBranchDetails } from "../services/storeLocator";
import { updateBranch } from "../store/reducers/misc";
import { handleErrorLog } from "./error_logs";

export const isSSR = () => typeof window === "undefined";

/**
 * @param {string} message
 * @param {import("react-toastify/dist/types").ToastOptions} options
 */
export const handleToastError = (message, options = {}) => {
  toast.error(
    (toastProps) => <CustomToaster message={message} {...toastProps} />,
    {
      toastId: message,
      icon: false,
      closeButton: false,
      ...options,
    },
  );
};

/**
 * @param {string} message
 * @param {import("react-toastify/dist/types").ToastOptions} options
 */
export const handleToastSuccess = (message, options = {}) => {
  toast.success(
    (toastProps) => <CustomToaster message={message} {...toastProps} />,
    {
      icon: false,
      closeButton: false,
      ...options,
      autoClose: 2000,
      hideProgressBar: true,
    },
  );
};

/**
 * @param {string} message
 * @param {import("react-toastify/dist/types").ToastOptions} options
 */
export const handleToastInfo = (message, options = {}) => {
  toast.info(
    (toastProps) => <CustomToaster message={message} {...toastProps} />,
    {
      icon: false,
      closeButton: false,
      ...options,
    },
  );
};

export const handleToastProduct = ({ name, image, showCTA }, options = {}) => {
  toast.success(
    (toastProps) => (
      <ProductToast name={name} image={image} showCTA={false} {...toastProps} />
    ),
    {
      icon: false,
      closeButton: false,
      autoClose: 2000,
      hideProgressBar: true,
      ...options,
    },
  );
};

export const handleGraphqlResponse = (res, getUserErrors = () => {}) => {
  const { data, errors } = res;
  if (errors) {
    throw new Error(errors[0].message);
  }
  const userErrors = getUserErrors?.(data);
  if (userErrors?.length) {
    throw new Error(userErrors[0].message);
  }
  return data;
};

export const handleThunkResponse = (res) => {
  const { payload, error } = res;
  if (error) {
    throw new Error(error.message);
  }
  return payload;
};

export const cookieExpireHours = (hours) => {
  const now = new Date();
  now.setTime(now.getTime() + hours * 60 * 60 * 1000);
  return {
    date: now,
    dateString: now.toUTCString(),
  };
};

export const handleSignInData = async (signInData) => {
  const customerToken = signInData.generateCustomerToken.token;
  const customerTokenCipher = await encrypt(customerToken);
  Cookies.set(constants.cookies.customerToken, customerTokenCipher, {
    expires: cookieExpireHours(constants.cookies.customerTokenExpiryHours).date,
    sameSite: "Lax",
    secure: true,
  });
  return { customerToken, customerTokenCipher };
};

export const getCustomerName = (data) => {
  const { firstname, middlename, lastname, suffix } = data || {};
  return [firstname, middlename, lastname, suffix].filter(Boolean).join(" ");
};

export const getAddressFromId = (addressId, addresses = []) => {
  return addresses.find(({ id }) => +id === +addressId);
};

export const getAddressDetails = (address, t) => {
  if (!address) return;
  if (!Object.keys(address).length) return;
  const addressClone = structuredClone(address);

  let {
    id,
    customer_address_id,
    street = [],
    city,
    region = {},
    postcode,
    country_code,
    company,
    country,
  } = addressClone || {};

  if (region?.code) {
    region.region_code = region.code;
    region.region = region.label;
  }

  if (country?.code) {
    country_code = country.code;
  }

  if (customer_address_id) {
    id = customer_address_id;
  }

  const addressObj = {
    ...addressClone,
    id,
    fullname: getCustomerName(addressClone),
    fullstreet: street.join(", "),
    country: t?.(`countries:${country_code}`) || country_code,
  };
  const addressArr = [
    getCustomerName(addressClone),
    company,
    ...street,
    [city, region?.region, postcode].filter(Boolean).join(", "),
    t?.(`countries:${country_code}`) || country_code,
  ].filter(Boolean);

  return {
    ...addressObj,
    addressArr,
  };
};

export const replaceArray = (string, findArr, replaceArr) => {
  let res = string;
  if (Array.isArray(findArr) && Array.isArray(replaceArr)) {
    for (let i = 0; i < findArr.length; i++) {
      res = res?.replace(findArr[i], replaceArr[i]);
    }
  }
  return res;
};

export const renderHTML = (rawHTML) => {
  if (!rawHTML) return null;
  return React.createElement("div", {
    dangerouslySetInnerHTML: { __html: htmlEntity.decode(rawHTML) },
  });
};

export const pushHistoryState = ({ data = null, unused = "", url = "" }) => {
  if (!isSSR()) {
    window.history.pushState(data, unused, url || location.pathname);
  }
};

export const replaceHistoryState = ({ data = null, unused = "", url = "" }) => {
  if (!isSSR()) {
    window.history.replaceState(data, unused, url || location.pathname);
  }
};

export const formatProductPrice = (amount) => {
  let priceTag = { amount: "0.00", currencySymbol: constants.currency.GBP };
  if (amount) {
    const symbol = amount?.symbol
      ? amount?.symbol
      : amount?.currency
      ? constants.currency?.[amount.currency]
      : "";
    priceTag.currencySymbol = symbol || priceTag.currencySymbol;

    try {
      let price = amount?.value;
      price = parseFloat(price);
      if (isNaN(price)) price = 0;
      priceTag.amount = price.toFixed(2);
    } catch (error) {
      handleErrorLog({
        error,
        additional_info: { amount },
        msg: "Error while checking Formattng amount",
      });
      priceTag.amount = "0.00";
    }
  }
  return `${priceTag.currencySymbol}${priceTag.amount}`;
};

export const updateRecentlyViewed = (productSKU) => {
  let recentViewed = [];
  const rawData = localStorage.getItem(constants.storageKeys.recently_viewed);

  try {
    recentViewed = rawData ? JSON.parse(rawData) : [];
  } catch (error) {
    recentViewed = [];
    handleErrorLog({
      error,
      additional_info: { productSKU },
      msg: "Error while parsing recently products",
    });
  }

  if (productSKU && recentViewed.includes(productSKU)) {
    const skuIndex = recentViewed.indexOf(productSKU);
    recentViewed.splice(skuIndex, 1);
  }

  recentViewed = [productSKU, ...recentViewed];
  if (recentViewed.length > 6) recentViewed.pop();

  localStorage.setItem(
    constants.storageKeys.recently_viewed,
    JSON.stringify(recentViewed),
  );
};

export const hasParentWithMatchingSelector = (target, selector) => {
  return [...document.querySelectorAll(selector)].some(
    (el) => el !== target && el.contains(target),
  );
};

export const getProductsMap = (products = []) => {
  const productsMap = {};
  products.forEach((details) => {
    const { product } = details;
    const { sku } = product;
    productsMap[sku.toUpperCase()] = {
      ...details,
      product: { ...product, sku: sku.toUpperCase() },
    };
  });
  return productsMap;
};

export const keepLastTwoDigitsAfterDecimal = (num) => {
  let number = Number(num);
  if (isNaN(number)) return "0.00";
  const roundedNumber = Math.round(num * 100) / 100;
  const strNumber = roundedNumber.toString();
  if (!strNumber.includes(".")) {
    return strNumber.concat(".00");
  } else {
    const arr = strNumber?.split(".") || [];
    return arr?.[1]?.length === 1 ? strNumber?.concat("0") : strNumber;
  }
};

export const getPrice = (value, currency = "GBP", options = {}) => {
  const { space = false } = options;
  const price = keepLastTwoDigitsAfterDecimal(value);
  return `${constants.currency[currency]}${space ? " " : ""}${price}`;
};

export const formatGeocodeError = (code) => {
  let message = "";
  switch (code) {
    case "ZERO_RESULTS": {
      message =
        "Unable to get location/postal code details. Please enter valid location/postal code.";
      break;
    }
    case "INVALID_REQUEST": {
      message = "Please enter a location/postal code";
      break;
    }
    default: {
      message = "Unable to get branches";
      break;
    }
  }
  return message;
};

export const scrollToElement = (elementId, margin = 140, delay = 250) => {
  if (!isSSR()) {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        const y = element.getBoundingClientRect().top + window.scrollY;
        window.scroll({
          top: y - margin,
          behavior: "smooth",
        });
      }
    }, delay);
  }
};

export const getRedirectURL = (data, searchTerm, onResultPage = false) => {
  const totalProducts = data?.products?.total_count || 0;
  const totalCategories = data?.products?.category_count || 0;
  let redirectURL = "";

  if (data?.products?.search_trems) {
    const searchTerms = data.products.search_trems;
    const redirect = searchTerms[0]?.redirect;
    redirectURL = redirect;
  }

  if (!redirectURL) {
    if (
      totalProducts === 1 &&
      !totalCategories &&
      data?.products?.items?.length > 0
    ) {
      const result = data.products.items[0];
      redirectURL = `${routes.products}/${result.url_key}`;
    }

    if (
      totalCategories === 1 &&
      !totalProducts &&
      data?.products?.categories?.length > 0
    ) {
      const result = data.products.categories[0];
      redirectURL = `/${result.link}`;
    }

    if (!onResultPage && !redirectURL) {
      const queryString = getEncodedQueryString({ q: searchTerm });
      redirectURL = `${routes.searchResults}?${queryString}`;
    }
  }

  return { totalProducts, totalCategories, redirectURL };
};

export const sortArrayOfObjects = (array, column, order = "asc") => {
  // Helper function to determine if a label is a number or fraction
  const isNumeric = (label) => !isNaN(label);
  const isFraction = (label) => label.includes("/");

  // Helper function to convert fractions to numerical value for comparison
  const fractionToNumber = (fraction) => {
    if (fraction) {
      const [numerator, denominator] = fraction.split("/").map(Number);
      return numerator / denominator;
    }
    return 0;
  };

  return array.sort((a, b) => {
    const labelA = a[column];
    const labelB = b[column];

    const aIsNumeric = isNumeric(labelA);
    const bIsNumeric = isNumeric(labelB);

    const aIsFraction = isFraction(labelA);
    const bIsFraction = isFraction(labelB);
    if (aIsNumeric && bIsNumeric) {
      // Both labels are numeric
      if (order === "desc") {
        return Number(labelB) - Number(labelA);
      } else {
        return Number(labelA) - Number(labelB);
      }
    } else if (aIsFraction && bIsFraction) {
      // Both labels are fractions
      if (order === "desc") {
        return fractionToNumber(labelB) - fractionToNumber(labelA);
      } else {
        return fractionToNumber(labelA) - fractionToNumber(labelB);
      }
    } else if (aIsNumeric && bIsFraction) {
      // Compare number with fraction
      if (order === "desc") {
        return fractionToNumber(labelB) - Number(labelA);
      } else {
        return Number(labelA) - fractionToNumber(labelB);
      }
    } else if (aIsFraction && bIsNumeric) {
      // Compare fraction with number
      if (order === "desc") {
        return Number(labelB) - fractionToNumber(labelA);
      } else {
        return fractionToNumber(labelA) - Number(labelB);
      }
    } else {
      if (order === "desc") {
        // Lexicographic comparison for other strings
        if (labelA > labelB) return -1;
        if (labelA < labelB) return 1;
        return 0;
      } else {
        // Lexicographic comparison for other strings
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      }
    }
  });
};

export const mutateProductInfo = (
  data,
  isCart = false,
  bestSellerImg = "",
  recommendedImg = "",
) => {
  let info = isCart ? { ...data?.product } : { ...data };
  const brands = info?.extraVariable?.brand_info || [];
  const firstBrand = brands?.length > 0 ? brands[0] : {};
  info.first_brand = firstBrand;
  info.original_name = info?.name;
  info.bestseller_img = bestSellerImg;
  info.recommended_img = recommendedImg;
  if (firstBrand?.title) {
    info.name = `${firstBrand.title?.toUpperCase()} - ${info.name}`;
  }

  const minPrice = info?.price_range?.minimum_price || {};
  const amountOff = minPrice?.discount?.amount_off || 0;
  const percentOff = minPrice?.discount?.percent_off || 0;

  info.showDiscountedPrice = amountOff >= 5;
  info.final_price = formatProductPrice(minPrice?.final_price);
  info.regular_price = formatProductPrice(minPrice?.regular_price);
  info.percent_off = percentOff > 9 ? `${percentOff}` : `0${percentOff}`;
  info.amountOff = formatProductPrice({
    ...minPrice?.final_price,
    value: amountOff,
  });

  info.showStrikePrice =
    minPrice?.final_price?.value < minPrice?.regular_price?.value ||
    !!info?.special_price;

  return isCart ? { ...data, product: info } : info;
};

export const beginCheckoutPayloadGA4 = (data) => {
  const { cartPrice, cartProducts, appliedCoupons } = data;

  const totalValue = cartPrice?.applied_taxes.reduce((accumulator, current) => {
    return accumulator + current.amount.value;
  }, 0);

  const products = Object.values(cartProducts).map((el) => {
    return {
      ...el.product,
      first_brand: el?.product?.extraVariable?.brand_info?.[0],
      quantity: el.quantity,
    };
  });

  let payload = {
    ecommerce: {
      value: cartPrice?.subtotal_with_discount_excluding_tax?.value,
      currency: cartPrice?.subtotal_with_discount_excluding_tax?.currency,
      tax: totalValue,
      items: formatProductGA4(products, appliedCoupons),
    },
  };

  return payload;
};

export const createPurchasePayloadGA4 = (data) => {
  const {
    cartPrice,
    cartEmail,
    cartProducts,
    appliedCoupons,
    selectedShippingMethod,
    user,
    orderType = "Home Delivery",
  } = data;

  const totalValue = cartPrice?.applied_taxes.reduce((accumulator, current) => {
    return accumulator + current.amount.value;
  }, 0);

  const products = Object.values(cartProducts).map((el) => {
    return {
      ...el.product,
      prices: mutateProductInfo(el, true)?.prices,
      first_brand: el?.product?.extraVariable?.brand_info?.[0],
      quantity: el.quantity,
    };
  });

  let payload = {
    email: cartEmail,
    ecommerce: {
      value: cartPrice?.subtotal_with_discount_excluding_tax?.value,
      currency: cartPrice?.subtotal_with_discount_excluding_tax?.currency,
      transaction_type: orderType,
      tax: totalValue,
      shipping: selectedShippingMethod?.amount?.value || 0.0,
      items: formatProductGA4(products, appliedCoupons, true).map((el) => ({
        ...el,
        affiliation: orderType,
      })),
    },
  };

  //check customerr logged in
  if (user?.id) {
    payload.user_id = String(user?.id);
  }

  return payload;
};

export const createPaymentInfoPayloadGA4 = (data) => {
  const {
    cartPrice,
    currency,
    cartProducts,
    appliedCoupons,
    selectedPaymentType,
  } = data;

  const products = Object.values(cartProducts).map((el) => {
    return {
      ...el.product,
      prices: mutateProductInfo(el, true)?.prices,
      first_brand: el?.product?.extraVariable?.brand_info?.[0],
      quantity: el.quantity,
    };
  });
  let payload = {
    value: cartPrice,
    currency: currency,
    payment_type: selectedPaymentType,
    items: formatProductGA4(products, appliedCoupons, true),
  };
  return payload;
};

export const createShippingInfoPayloadGA4 = (data) => {
  const {
    currency,
    cartProducts,
    appliedCoupons,
    selectedShippingMethod,
    shippingAmount,
  } = data;

  const products = Object.values(cartProducts).map((el) => {
    return {
      ...el.product,
      prices: mutateProductInfo(el, true)?.prices,
      first_brand: el?.product?.extraVariable?.brand_info?.[0],
      quantity: el.quantity,
    };
  });
  let payload = {
    currency: currency,
    shipping_tier: selectedShippingMethod,
    items: formatProductGA4(products, appliedCoupons, true),
  };
  payload.value = (
    payload?.items?.reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0) + shippingAmount.value
  ).toFixed(2);
  return payload;
};

export const formatSavedBranch = (branchDetails) => {
  const {
    branch_id,
    name,
    type,
    area,
    is_hub,
    closing_mon_fri,
    closing_sat,
    closing_sun,
    open_saturday,
    open_sunday,
    opening_mon_fri,
    opening_sat,
    opening_sun,
    distance,
    address1,
    address2,
    address3,
    address4,
    postcode,
    latitude,
    telephone,
    longitude,
    hyperlink: link,
    email,
    holiday_info,
  } = branchDetails;

  return {
    branch_id,
    title: `${type} ${name}`,
    name,
    type,
    area,
    is_hub: is_hub || "",
    distance,
    address1,
    address2,
    address3,
    address4,
    postcode,
    latitude,
    telephone,
    longitude,
    link,
    email,
    closing_mon_fri,
    closing_sat,
    closing_sun,
    open_saturday,
    open_sunday,
    opening_mon_fri,
    opening_sat,
    opening_sun,
    holiday_info,
  };
};

export const getGeocodeLatLng = async (address = "") => {
  const { results } = await new google.maps.Geocoder().geocode({
    address: address + " ,UK",
  });
  const [res] = results;
  const latLng = res.geometry.location.toJSON();

  return latLng;
};

export const getSelectedBranch = (cb = () => {}) => {
  let branch = null;
  try {
    const storedBranch = localStorage.getItem(
      constants.localStorage.selectedBranch,
    );
    if (storedBranch) {
      const parsedBranch = JSON.parse(storedBranch);
      branch = parsedBranch;
    }
  } catch (error) {
    //Branch Parsing Error Handling
    handleToastError(error.message);
  }
  cb?.(branch);
  return branch;
};

export const handleSignInProcess = async (
  { dispatch },
  signUpEvent = false,
) => {
  const guestCartId = localStorage.getItem(constants.localStorage.cartId);
  //Fetch Customer Details
  const custDetailsRes = await dispatch(fetchCustomer());
  const custDetails = handleThunkResponse(custDetailsRes);

  // fetch cart id and email for local storage
  await dispatch(fetchCartForLoggedInUser());

  if (signUpEvent) sign_up(custDetails?.id);
  login(custDetails?.id);

  const selectedBranch = getSelectedBranch((branch) => {
    dispatch(updateBranch(branch));
  });

  // get preferred branch of the user and set to local and cart if not any
  if (custDetails?.preferred_branch?.branch_id && !selectedBranch) {
    const detailsRes = await getBranchDetails({
      branch: custDetails?.preferred_branch?.hyperlink,
    });

    const [branchRes] = detailsRes;

    const res = await dispatch(
      setCnCBranchThunk({ ...branchRes?.branch_info, setNew: false }),
    );
  }

  if (custDetails?.cart_id) {
    await dispatch(fetchCartItems());
  }

  if (!guestCartId) return;

  const defaultShippingId = custDetails?.default_shipping?.id;
  const defaultBillingId = custDetails?.default_billing?.id;

  //Merge and Fetch Cart Details
  const cartDetailsRes = await dispatch(
    mergeCartsThunk({
      guestCartId,
      getBranchInfo: true,
    }),
  );

  const cartDetails = handleThunkResponse(cartDetailsRes);

  if (cartDetails?.cartId) {
    localStorage.setItem(constants.localStorage.cartId, cartDetails?.cartId);
    dispatch(setCartId(cartDetails?.cartId));
  }

  if (cartDetails?.branchId) {
    await dispatch(
      fetchClickAndCollectStock({ branch_id: cartDetails?.branchId }),
    );
  }

  if (
    !cartDetails?.branchId &&
    !cartDetails?.shippingAddresses?.length &&
    defaultShippingId
  ) {
    //If click and collect branch is not set; and
    //If customer has default shipping address set and no shipping address is
    // associated with his/her cart, then we need to manually set that.
    const cartShipAddrRes = await dispatch(
      setShippingAddressThunk({ addressId: defaultShippingId }),
    );
    handleThunkResponse(cartShipAddrRes);
  }
  if (!cartDetails?.billingAddress && defaultBillingId) {
    //If no billing addr set. And user has a default billing addr.
    //Then set that as billing addr of cart;
    const cartBillAddrRes = await dispatch(
      setBillingAddressThunk({ addressId: defaultShippingId }),
    );
    handleThunkResponse(cartBillAddrRes);
  }
};

export const updateCartShippingAddress = async ({
  currentAddr,
  type,
  dispatch,
  customerDetails,
  shipAddr,
}) => {
  const {
    default_shipping: current_default_shipping = false,
    id: currentAddrId,
  } = currentAddr;

  const currShipAddrId = getAddressDetails(shipAddr)?.id;
  if (type === "add") {
    //Case: When adding a new address;
    if (current_default_shipping) {
      const res = await dispatch(
        setShippingAddressThunk({ addressId: currentAddrId }),
      );
      handleThunkResponse(res);
    }
  } else {
    //When editing the existing address;
    const default_shipping_before = !!getAddressFromId(
      currentAddrId,
      customerDetails?.addresses,
    )?.default_shipping;
    //If there has been a change in the default shipping;
    if (default_shipping_before !== current_default_shipping) {
      if (current_default_shipping) {
        //Case: This address is being set as default shipping;
        //So we need to associate it with the cart;
        const res = await dispatch(
          setShippingAddressThunk({ addressId: currentAddrId }),
        );
        handleThunkResponse(res);
      } else if (currShipAddrId === currentAddrId) {
        //Case: It was set as default_shipping, now the user is removing it from being default_shiping;
        // And this address is also set as current shipping address;
        //So, we need to deassociate it from the cart;
        const res = await dispatch(resetCartAddressThunk("shipping"));
        handleThunkResponse(res);
      }
    } else if (currShipAddrId === currentAddrId) {
      //Current address that is being updated is also set as shipping addres;
      //No Change made in the default shipping addres checkbox;
      //But some other field could have been changed.
      //So need to updated the shipping address of cart with latest changes;
      const res = await dispatch(
        setShippingAddressThunk({ addressId: currentAddrId }),
      );
      handleThunkResponse(res);
    }
  }
};

export const updateCartBillingAddress = async ({
  currentAddr,
  type,
  dispatch,
  customerDetails,
  billAddr,
}) => {
  const {
    default_billing: current_default_billing = false,
    id: currentAddrId,
  } = currentAddr;

  const currBillAddrId = getAddressDetails(billAddr)?.id;
  if (type === "add") {
    //Case: When adding a new address;
    if (current_default_billing) {
      const res = await dispatch(
        setBillingAddressThunk({ addressId: currentAddrId }),
      );
      handleThunkResponse(res);
    }
  } else {
    //When editing the existing address;
    const default_billing_before = !!getAddressFromId(
      currentAddrId,
      customerDetails?.addresses,
    )?.default_billing;
    //If there has been an change in the default billing;
    if (default_billing_before !== current_default_billing) {
      if (current_default_billing) {
        //Case: This address is being set as default billing;
        //So we need to associate it with the cart;
        const res = await dispatch(
          setBillingAddressThunk({ addressId: currentAddrId }),
        );
        handleThunkResponse(res);
      } else if (currBillAddrId === currentAddrId) {
        //Case: It was set as default_billing, now the user is removing it from being default billing;
        // And this address is also set as current billing address;
        //So, we need to deassociate it from the cart;
        const res = await dispatch(resetCartAddressThunk("billing"));
        handleThunkResponse(res);
      }
    } else if (currBillAddrId === currentAddrId) {
      //Current address is also set as cart billing address;
      //No Change made in the default billing address checkbox;
      //But some other field could have been changed.
      //So need to updated the billing address of cart with latest changes;
      const res = await dispatch(
        setBillingAddressThunk({ addressId: currentAddrId }),
      );
      handleThunkResponse(res);
    }
  }
};

export const updateCartShippingAddressForDeletion = async ({
  currRemovedAddrId,
  updatedUserAddresses,
  shipAddr,
  dispatch,
}) => {
  const currShipAddrId = getAddressDetails(shipAddr)?.id;
  if (!updatedUserAddresses.addresses?.length) {
    //Case: Last address is removed. and also there is a shipping address set for cart;
    if (currShipAddrId) {
      const res = await dispatch(resetCartAddressThunk("shipping"));
      handleThunkResponse(res);
    }
  } else if (currRemovedAddrId === currShipAddrId) {
    //Case: There are still other address and removed address is also the current shipping address:
    const defaultShipAddr = updatedUserAddresses?.addresses?.find?.(
      (adr) => adr.default_shipping,
    );
    // - if there is no default shipping address for the user from the remaining addresses --> reset
    // - else set the default shipping address as the cart shipping address;
    if (defaultShipAddr) {
      const res = await dispatch(
        setShippingAddressThunk({ addressId: defaultShipAddr?.id }),
      );
      handleThunkResponse(res);
    } else {
      const res = await dispatch(resetCartAddressThunk("shipping"));
      handleThunkResponse(res);
    }
  }
};

export const updateCartBillingAddressForDeletion = async ({
  currRemovedAddrId,
  updatedUserAddresses,
  billAddr,
  dispatch,
}) => {
  const currBillAddrId = getAddressDetails(billAddr)?.id;
  if (!updatedUserAddresses.addresses?.length) {
    //Case: Last address is removed. and also there is a billing address set for cart;
    if (currBillAddrId) {
      const res = await dispatch(resetCartAddressThunk("billing"));
      handleThunkResponse(res);
    }
  } else if (currRemovedAddrId === currBillAddrId) {
    //Case: There are still other address and removed address is also the current billing address:
    const defaultBillAddr = updatedUserAddresses?.addresses?.find?.(
      (adr) => adr.default_billing,
    );
    // - if there is no default billing address for the user from the remaining addresses --> reset
    // - else set the default billing address as the cart billing address;
    if (defaultBillAddr) {
      const res = await dispatch(
        setBillingAddressThunk({ addressId: defaultBillAddr?.id }),
      );
      handleThunkResponse(res);
    } else {
      const res = await dispatch(resetCartAddressThunk("billing"));
      handleThunkResponse(res);
    }
  }
};

export const convertGarageDataToCookieFormat = (vehicleDetails) => {
  const res = {
    session: {
      sessionId: vehicleDetails?.session_id,
      statusCode: "Vehicle_Found",
    },
    vehicle: vehicleDetails?.data,
  };
  return res;
};

export const isEmptyObject = (obj) => {
  if (obj === null || obj === undefined) return true;
  if (typeof obj !== "object") return false;
  return Object.keys(obj).length === 0;
};

export const showVehicleInfo = (details = null) => {
  if (isEmptyObject(details)) return "";
  if (isEmptyObject(details?.vehicle)) return "";

  let detailArr = [];
  if (details?.vehicle?.make) detailArr.push(details?.vehicle?.make);
  if (details?.vehicle?.model) detailArr.push(details?.vehicle?.model);
  if (details?.vehicle?.engineSize)
    detailArr.push(details?.vehicle?.engineSize);
  if (details?.vehicle?.fuel) detailArr.push(details?.vehicle?.fuel);
  if (details?.vehicle?.dateOfManufacture) {
    let dateArr = [];
    if (details?.vehicle?.dateOfManufacture) {
      dateArr = details?.vehicle?.dateOfManufacture.split("-");
    }
    detailArr.push(dateArr[0] || "");
  }
  if (details?.vehicle?.modelVersion) {
    detailArr.push(
      `(${(details?.vehicle?.modelVersion.match(/\(([^)]+)\)/) || [])[1]})`,
    );
  }

  return detailArr.join(" / ");
};

export function isValidInput(text) {
  // Return true if field is empty (optional field)
  if (!text) {
    return true;
  }
  
  // Check if string only contains whitespace
  if (text.trim().length === 0) {
    return false;
  }

  // Validate against special characters
  let regex = new RegExp(/^[\w-_.!@#£$%^&+*()?/, '".\n]*$/);
  return regex.test(text);
}

export function isValidEmail(text) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(text);
}

export const getBackendURL = () => {
  return isSSR() ? keys.general.graphqlUrl : keys.general.backendGraphqlUrl;
};

export const isVRM = (selectedVehicle) => {
  return !selectedVehicle.includes("_");
};

export const MVLKeyToQueryString = (key) => {
  const paramNames = [
    "manufacturerId",
    "modelId",
    "type",
    "modelVersionId",
    "engineSizeId",
    "bodyStyleId",
    "powerId",
  ];

  const values = key.split("_");
  const params = paramNames.reduce((acc, paramName, index) => {
    if (values[index] !== undefined) {
      acc[paramName] = values[index];
    }
    return acc;
  }, {});

  const queryString = Object.keys(params)
    .filter((key) => params[key] !== null && params[key] !== undefined)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join("&");

  return queryString;
};

export const MVLQueryStringToKey = (queryString) => {
  const paramNames = [
    "manufacturerId",
    "modelId",
    "type",
    "modelVersionId",
    "engineSizeId",
    "bodyStyleId",
    "powerId",
  ];
  const params = new URLSearchParams(queryString);
  const values = paramNames.map((paramName) => params.get(paramName) || "");
  const key = values.filter((value) => value !== "").join("_");
  return key;
};

export const MVLPayloadToKey = (payload) => {
  const parts = [];
  if (payload?.make) parts.push(payload?.make);
  if (payload?.model) parts.push(payload?.model);
  if (payload?.type) parts.push(payload?.type);
  if (payload?.year) parts.push(payload?.year);
  if (payload?.engine) parts.push(payload?.engine);
  if (payload?.body) parts.push(payload?.body);
  if (payload?.kw_hp) parts.push(payload?.kw_hp);
  return parts.join("_");
};

export const manageStickyHeaderMargin = (firstId, secondId) => {
  const isMobile = window.matchMedia("(max-width: 767px)").matches;
  const offset = isMobile ? 99 : 118;
  let clientHeight = 0;

  const element = document.getElementById(firstId);
  if (element) {
    clientHeight = element.clientHeight;
  }

  const stickyEle = document.getElementById(secondId);
  if (stickyEle) {
    if (secondId === "basket-sticky-header") {
      stickyEle.style.top = `${clientHeight}px`;
    } else if (secondId === "mobile-search-box") {
      stickyEle.style.top = `${offset + clientHeight}px`;
    } else if (secondId === "modal-sticky-header") {
      if (isMobile) {
        if (window.scrollY > 433) {
          stickyEle.classList.remove("hidden");
        } else {
          stickyEle.classList.add("hidden");
        }
      } else {
        stickyEle.classList.add("hidden");
      }
      stickyEle.style.top = `${clientHeight}px`;
    } else {
      stickyEle.style.marginTop = `${clientHeight}px`;
    }
  }
};

export const getSortedKeys = (inputObj) => {
  const keys = Object.keys(inputObj);
  const alphaKeys = keys.filter((key) => /^[A-Za-z]+$/.test(key));
  const numberKeys = keys.filter((key) => /^\d+$/.test(key));
  return [...alphaKeys, ...numberKeys];
};

export const updateVehicleCookies = ({ key = "", hasManualLook = false }) => {
  if (key) {
    Cookies.set(constants.cookies.selectedVehicle, key, {
      expires: 7,
      secure: true,
      sameSite: "strict",
    });
  }
  if (hasManualLook) {
    Cookies.set(constants.cookies.manual_lookup, 1, {
      expires: 1,
      secure: true,
      sameSite: "strict",
    });
  }
  Cookies.set(constants.cookies.new_vehicle, 1, {
    expires: 1,
    secure: true,
    sameSite: "strict",
  });
};

export const formatProductGA4 = (
  productArr = [],
  coupons = [],
  isCart = false,
) => {
  const mutatedItems = productArr.map((product, i) => {
    let price = product?.price_range?.minimum_price?.final_price?.value;
    let discount = product?.price_range?.minimum_price?.discount?.amount_off;

    // if on cart update discounts and price
    if (isCart) {
      const regularPrice = product?.price_range?.minimum_price?.regular_price;
      const rowTotalIncTax = product?.prices?.row_total_including_tax?.value;
      const oldPrice = (regularPrice?.value ?? 0) * (product?.quantity ?? 0);
      const amountOff = (oldPrice - rowTotalIncTax) / product?.quantity || 1;

      const singleProductPrice = product?.prices?.price_including_tax?.value;

      discount = +amountOff.toFixed(2);
      price = singleProductPrice;
    }

    const specialCouponApplied =
      !product?.specialCoupon_applied && discount > 0;
    return {
      price,
      ...(coupons?.length &&
        specialCouponApplied && {
          coupon: coupons[0].code,
        }),
      item_id: product?.sku,
      item_name: product?.original_name || product?.name,
      quantity: product?.quantity,
      item_brand: product?.first_brand?.title,
      index: i,
      discount,
      ...formatProductCategoriesGA4(product?.extraVariable?.breadcrumbs),
    };
  });

  return mutatedItems;
};

export const formatRemovedProductGA4 = (
  productArr = [],
  coupons = [],
  isCart = false,
) => {
  const mutatedItems = productArr.map((product, i) => {
    let price = product?.price_range?.minimum_price?.final_price?.value;
    let discount = product?.price_range?.minimum_price?.discount?.amount_off;

    // if on cart update discounts and price
    if (isCart) {
      const regularPrice = product?.price_range?.minimum_price?.regular_price;
      const rowTotalIncTax = product?.prices?.price_including_tax?.value;
      const oldPrice = regularPrice?.value ?? 0;

      const singleProductPrice = product?.prices?.price_including_tax?.value;
      const amountOff = oldPrice - singleProductPrice;

      discount = +amountOff.toFixed(2);
      price = singleProductPrice;
    }

    const specialCouponApplied =
      !product?.specialCoupon_applied && discount > 0;
    return {
      price,
      ...(coupons?.length &&
        specialCouponApplied && {
          coupon: coupons[0].code,
        }),
      item_id: product?.sku,
      item_name: product?.original_name || product?.name,
      quantity: product?.quantity,
      item_brand: product?.first_brand?.title,
      index: i,
      discount,
      ...formatProductCategoriesGA4(product?.extraVariable?.breadcrumbs),
    };
  });

  return mutatedItems;
};

export const formatProductCategoriesGA4 = (breadcrumbs) => {
  let categories = {};
  breadcrumbs &&
    breadcrumbs.forEach((breadcrumb, index) => {
      const key = index === 0 ? "item_category" : `item_category${index + 1}`;
      categories[key] = breadcrumb.label;
    });
  return categories;
};

export const updateGA4PayloadToSelectItem = (
  ga4_payload,
  breadcrumbs,
  item_list,
) => {
  return {
    item_list_id: item_list
      ?.toLowerCase()
      ?.replace(/\s+/g, "_")
      ?.replace(/[^\w_]/g, "")
      ?.replace(/_+/g, "_"),
    items: [
      {
        item_id: ga4_payload?.items[0]?.item_id,
        item_name: ga4_payload?.items[0]?.item_name,
        discount: ga4_payload?.items[0]?.discount,
        item_brand: ga4_payload?.items[0]?.item_brand,
        price: ga4_payload?.items[0]?.price,
        quantity: ga4_payload?.items[0]?.quantity,
        index: ga4_payload?.items[0]?.index,
        ...(ga4_payload?.items[0]?.coupon && {
          coupon: ga4_payload?.items[0]?.coupon,
        }),
        ...formatProductCategoriesGA4(breadcrumbs),
        item_list_name: item_list,
      },
    ],
  };
};

export const getPageType = () => {
  const path = location?.pathname;

  const includesPath = (pathsArray) => pathsArray.some((p) => path.includes(p));

  switch (true) {
    case path === constants.paths.home:
      return "Home";

    case includesPath(constants.paths.customer):
      return "Customer";

    case includesPath(constants.paths.cms):
      return "CMS";

    case path.includes(constants.paths.newsletter):
      return "Newsletter";

    case path.includes(constants.paths.checkout):
      switch (true) {
        case path.includes(constants.paths.cart):
          return "Cart";
        default:
          return "Checkout";
      }

    case path.includes(constants.paths.branch):
      return "Branch";

    case path.includes(constants.paths.blog):
      return "Blog";

    case path.includes(constants.paths.search):
      return "Search";

    case path.includes(constants.paths.makeAndModels):
      return "Make and Models";

    case path.includes(constants.paths.sitemap):
      return "Sitemap";

    case path.includes(constants.paths.product):
      return "Product";

    case constants.paths.category.includes(path):
      return "Category";

    case includesPath(constants.paths.category):
      return "Sub Category";

    default:
      return path;
  }
};

export const getDepartment = () => {
  const match = location?.pathname.match(/^\/([^\/]+)/);
  if (match) {
    const department = match[1];
    return department.charAt(0).toUpperCase() + department.slice(1);
  }
  return "";
};

export const removeCartFromStorage = () => {
  localStorage.removeItem(constants.localStorage.cartId);
};

export const updateImageURL = (
  imgURL,
  width = 308,
  height = 280,
  isBanner = false,
) => {
  if (!imgURL) return "";
  try {
    const urlSchema = new URL(imgURL);
    urlSchema.searchParams.set("auto", "webp");
    if (urlSchema.searchParams.has("height")) {
      urlSchema.searchParams.set("height", height);
    }
    if (urlSchema.searchParams.has("width")) {
      urlSchema.searchParams.set("width", width);
    }

    if (isBanner) {
      urlSchema.searchParams.delete("auto");
      urlSchema.searchParams.set("w", width);
      urlSchema.searchParams.set("h", height);
      urlSchema.searchParams.set("fm", "webp");
    }
    return urlSchema.toString();
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { updateImageURL },
      msg: "Error while updating image url for optimization",
    });
    return imgURL;
  }
};

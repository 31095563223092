import React, { forwardRef } from "react";
import ArrowIcon from "./SvgIcons/arrow";
import ShoppingIcon from "./SvgIcons/shopping";
import OutOfStockIcon from "./SvgIcons/outofstock";
import EditIcon from "./SvgIcons/edit";
import CarIcon from "./SvgIcons/car";
import ArrowOutwardIcon from "./SvgIcons/arrowoutward";
import MapIcon from "./SvgIcons/mapIcon";
import PersonIcon from "./SvgIcons/person";
import CartIcon from "./SvgIcons/cart";
import CancelIcon from "./SvgIcons/cancel";
import MenuIcon from "./SvgIcons/menu";
import SearchMbl from "./SvgIcons/mbsearch";
import Link from "next/link";
import DownloadArrow from "./SvgIcons/downloadArrow";
import CarBlueIcon from "./SvgIcons/carBlue";
import CloseIcon from "./SvgIcons/close";
import Dustbin from "./SvgIcons/dustbin";
import PersonMobileIcon from "./SvgIcons/personMobile";
import ShoppingMobileIcon from "./SvgIcons/carMobileIcon";
import FilterIcon from "./SvgIcons/filter";
import CrossIcon from "./SvgIcons/cross";
import UpDownIcon from "./SvgIcons/upDown";
import GreyCancelIcon from "./SvgIcons/greyClose";
import ViewAll from "./SvgIcons/viewAll";
import BackArrowIcon from "./SvgIcons/backArrow";
import PlusIcon from "./SvgIcons/plus";
import CrossModal from "./SvgIcons/crossModal";
import DeleteIcon from "./SvgIcons/deleteIcon";
import AccountBackArrowIcon from "./SvgIcons/accountBackArrow";
import ArrowDownNew from "./SvgIcons/arrowDownNew";
import CrossWhiteIcon from "./SvgIcons/crossWhiteIcon";
import Zoomin from "./SvgIcons/zoomin";
import Zoomout from "./SvgIcons/zoomout";
import LocationPin from "./SvgIcons/locationPin";
import CallIcon from "./SvgIcons/CallIcon";
import GsfMailIcon from "./SvgIcons/GsfMailIcon";
import GsfChatIcon from "./SvgIcons/GsfChatIcon";
import BackToTopIcon from "./SvgIcons/BackToTopIcon";
import MobileMap from "./SvgIcons/mobileMap";
import "@/assets/css/pages/button.css";

const Button = (
  {
    children,
    theme,
    className,
    icon,
    href = "#",
    iconPosition = "right",
    renderIcon,
    disabled,
    ...rest
  },
  ref,
) => {
  const iconMap = {
    arrow: <ArrowIcon />,
    shopping: <ShoppingIcon />,
    ShoppingMobile: <ShoppingMobileIcon />,
    outofstock: <OutOfStockIcon />,
    edit: <EditIcon />,
    car: <CarIcon />,
    carBlue: <CarBlueIcon />,
    arrowoutwardicon: <ArrowOutwardIcon />,
    map: <MapIcon />,
    mobileMap: <MobileMap />,
    person: <PersonIcon />,
    personMobile: <PersonMobileIcon />,
    cart: <CartIcon />,
    cancel: <CancelIcon />,
    menu: <MenuIcon />,
    mbsearch: <SearchMbl />,
    download: <DownloadArrow />,
    close: <CloseIcon />,
    dustbin: <Dustbin />,
    cross: <CrossIcon />,
    "cross-white": <CrossWhiteIcon />,
    filter: <FilterIcon />,
    upDown: <UpDownIcon />,
    greyClose: <GreyCancelIcon />,
    viewAll: <ViewAll />,
    back: <BackArrowIcon />,
    accountBack: <AccountBackArrowIcon />,
    plus: <PlusIcon />,
    crossBlack: <CrossModal />,
    delete: <DeleteIcon />,
    arrowDownNew: <ArrowDownNew />,
    zoomin: <Zoomin />,
    zoomout: <Zoomout />,
    locationPin: <LocationPin />,
    callIcon: <CallIcon />,
    gsfMailIcon: <GsfMailIcon />,
    gsfChatIcon: <GsfChatIcon />,
    backToIcon: <BackToTopIcon />
  };

  const determineSVG = () => {
    return iconMap[icon] || null;
  };

  if (rest.type === "link" && !disabled) {
    return (
      <Link
        prefetch={false}
        className={`${theme || ""} ${className || ""}`}
        href={href || "#"}
        {...rest}
      >
        {iconPosition === "left" &&
          (renderIcon ? renderIcon() : determineSVG())}
        <span>{children}</span>
        {iconPosition === "right" &&
          (renderIcon ? renderIcon() : determineSVG())}
      </Link>
    );
  }

  return (
    <button
      className={`${theme || ""} ${className || ""}`}
      disabled={!!disabled}
      {...rest}
      ref={ref}
    >
      {iconPosition === "left" && (renderIcon ? renderIcon() : determineSVG())}
      <span>{children}</span>
      <span>
        {iconPosition === "right" &&
          (renderIcon ? renderIcon() : determineSVG())}
      </span>
    </button>
  );
};
export default forwardRef(Button);

"use client";

import Link from "next/link";
import { getImageProps } from "next/image";
import { updateImageURL } from "@/lib/utils/functions";
import { getContentfulImgData } from "@/lib/services/contentful";
import CarRegistrationBox from "@/components/common/shared/CarRegistration";

const HomeBanner = ({
  banner,
  cookieKeys,
  selectedVehicle,
  bannerImageForMobile,
}) => {
  const bannerLink = banner?.fields?.link;
  const desktopImg = getContentfulImgData(banner?.fields?.image);
  const mobileImg = getContentfulImgData(bannerImageForMobile?.fields?.image);
  const common = {
    priority: true,
    alt: desktopImg.title || desktopImg.fileName || "Home Banner",
  };
  const {
    props: { srcSet: desktop },
  } = getImageProps({
    ...common,
    width: 1440,
    height: 440,
    quality: 90,
    src: updateImageURL(desktopImg.imageUrl, 1440, 440, true),
  });

  const {
    props: { srcSet: mobile, ...rest },
  } = getImageProps({
    ...common,
    width: 450,
    height: 450,
    quality: 85,
    src: updateImageURL(mobileImg.imageUrl, 450, 450, true),
  });

  const PictureTag = (
    <picture>
      <source media="(min-width: 768px)" srcSet={desktop} />
      <source media="(max-width: 767px)" srcSet={mobile} />
      <img
        {...rest}
        alt={common.alt}
        className="w-12/12 xs:order-1 order-2"
        style={{ width: "100%", height: "auto" }}
      />
    </picture>
  );

  return (
    <section className="w-12/12 relative flex flex-col xs:pb-[65px] pb-24 max-w-[1440px] mx-auto z-[2]">
      <CarRegistrationBox
        cookieKeys={cookieKeys}
        selectedVehicle={selectedVehicle}
      />
      {bannerLink ? (
        <Link
          rel="preload"
          prefetch={false}
          href={bannerLink}
          className="home-banner-section"
        >
          {PictureTag}
        </Link>
      ) : (
        PictureTag
      )}
    </section>
  );
};

export default HomeBanner;

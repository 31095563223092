"use client";

import Image from "next/image";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { viewItemListAnalytics } from "@/lib/gtm";
import { useSearchParams } from "next/navigation";
import { sortProducts } from "@/lib/utils/helpers/home";
import { getFeaturedProducts } from "@/lib/services/home";
import { createItemListPayload } from "@/lib/utils/helpers/plp";
import SkeletonImg from "@/assets/static/featured_placeholder.svg?url";
import { formatProductGA4, mutateProductInfo } from "@/lib/utils/functions";
const FeaturedProductList = dynamic(() => import("./FeaturedProductList"));

const Deals = ({ headings = {}, bestSellerImg = "", recommendedImg = "" }) => {
  const searchParams = useSearchParams();
  const [fetching, setFetching] = useState(true);
  const [products, setProducts] = useState({ items: [], count: 0 });

  useEffect(() => {
    const sendFeaturedAnalytics = (list) => {
      if (list?.count > 0) {
        // view cart GA4 for Featured viewed
        const formattedProducts = formatProductGA4(
          list?.items
            .map((p) => mutateProductInfo(p))
            .map((el) => ({ ...el, quantity: el.extraVariable.qty })),
        );
        viewItemListAnalytics(
          createItemListPayload(formattedProducts, "Featured Products", true),
        );
      }
    };

    const fetchFeatureProducts = async () => {
      try {
        const scCookie = Cookies.get("specialCoupon");
        const autoApplyCoupon = searchParams.get('auto_apply_coupon');
        const specialCoupon = autoApplyCoupon || scCookie || null;
        const [res, apiErr] = await getFeaturedProducts({
          specialCoupon,
        });
        if (apiErr) {
          console.warn("featured products api>>>", apiErr);
          setFetching(false);
          return;
        }
        if (res?.featured_products) {
          const productList = {
            count: res.featured_products?.total_count,
            items: sortProducts(res.featured_products),
          };
          setProducts(productList);
          sendFeaturedAnalytics(productList);
          setFetching(false);
        }
      } catch (err) {
        console.warn("featured products>>>", err);
        setFetching(false);
      }
    };
    fetchFeatureProducts();
  }, []);

  if (!fetching) {
    if (!products?.count) return null;
    return (
      <FeaturedProductList
        products={products}
        bestSellerImg={bestSellerImg}
        recommendedImg={recommendedImg}
        title={headings?.featured_product_title}
        description={headings?.featured_product_desc}
      />
    );
  }

  return (
    <section className="w-12/12 pb-6 sm:pb-80 bg-tertiary-white category-section animation-fadeIn">
      <div className="container">
        <div className="row">
          <Image
            width={1440}
            height="auto"
            src={SkeletonImg}
            alt={headings.featured_product_title}
            className="w-[450] xs:w-[1440] animate-pulse"
          />
        </div>
      </div>
    </section>
  );
};

export default Deals;

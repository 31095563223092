"use client";

import CustomRadio from "@/components/common/shared/CustomRadio";

export default function CategoryBox({ brands, identifier, handleBrandChange }) {
  return (
    <div className="flex xs:flex-wrap flex-nowrap xs:gap-[10px] gap-2 overflow-auto pt-3">
      {brands?.map((brand, i) => (
        <div
          key={`${brand.id}${i}`}
          className="w-full xs:max-w-[154px] max-w-[120px] "
        >
          <CustomRadio
            brand={brand.image}
            title={brand.title}
            price={brand?.final_price}
            name={`product-${identifier}-brand`}
            checked={brand?.is_selected || false}
            badge={brand?.brand_tier?.toLowerCase()}
            isRecommended={brand?.is_recommended == 1}
            onChange={() => handleBrandChange(identifier, brand.id, i)}
          />
        </div>
      ))}
    </div>
  );
}

"use client";
import Testimonal from "@/components/common/card/Testimonal";
import Button from "@/components/common/shared/Button/button";
import { documentToReactComponents } from "@/lib/utils";
import { useEffect, useState } from "react";
import styles from "@/assets/css/pages/font.module.css";
export default function WhyGsf({ whyGsf }) {
  const { title, usps, description } = whyGsf?.fields || {};
  const [active, setActive] = useState(false);

  const handleShow = () => {
    const branchBox = document.getElementById("gsfDescription");
    setActive(!active);
    const topElement = document.getElementById("top-alert-header");
    let topClientHeight = 0;
    if (topElement) {
      topClientHeight = topElement.clientHeight;
    }
    const branchBoxPosition =
      branchBox.getBoundingClientRect().top + window.scrollY;
    setTimeout(() => {
      window.scrollTo({
        top: branchBoxPosition - topClientHeight, // Apply offset here
        behavior: "smooth",
      });
    }, 50);
  };

  useEffect(() => {
    const gsfDescription = document.getElementById("gsfDescription");
    const scrollHeight = gsfDescription?.scrollHeight;
    if (scrollHeight < 140) {
      readmore.style.display = "none";
    }
  }, []);

  return (
    <section className="testimonial-section bg-tertiary-white py-6 sm:py-[80px]">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <h2 className={`${styles.heading32}`}>{title}</h2>

            <div className="grid grid-cols-4 sm:gap-6 gap-[10px] xs:mt-6 mt-4">
              {usps?.map?.((usp) => {
                const {
                  title: _title,
                  description: _description,
                  icon,
                } = usp?.fields || {};
                return (
                  <Testimonal
                    key={usp?.sys?.id}
                    itemPic={icon}
                    heading={_title}
                    subHeading={_description}
                  />
                );
              })}
            </div>
            {!!description && (
              <div className="flex flex-col xs:border-[3px] border-[1px] border-secondary-500 rounded-2xl p-3 xs:p-8 mt-6 test2">
                <div
                  id="gsfDescription"
                  className={`description ${active ? "show" : ""}`}
                >
                  {documentToReactComponents(description)}
                </div>
                <div className="xs:hidden flex ms-auto mt-1">
                  <Button
                    id="readmore"
                    theme="blue-bordered-btn-arrow"
                    onClick={handleShow}
                  >
                    {active ? "Read Less" : "Read More"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

import axios from "axios";
import { keys } from "../utils/keys";
import { routes } from "../utils/routes";
import { constants } from "../utils/constants";
import { handleErrorLog } from "../utils/error_logs";
import { handleToastError } from "../utils/functions";

const baseURL = keys.general.restUrl;

export const getManualBreadcrumb = (searchParams, category, resObj) => {
  const makeLabel = searchParams.make;
  const modelLabel = searchParams.model;
  resObj.heading = `${makeLabel} ${modelLabel} ${category.name}`;
  resObj.breadcrumbs = [];
  resObj.breadcrumbs.push({
    title: "Makes/Models",
    url: routes.makesAndModels,
  });
  resObj.breadcrumbs.push({
    title: `${makeLabel} Spare Parts`,
    url: `${routes.manualSelection}?make=${makeLabel}`,
  });
  resObj.breadcrumbs.push({
    title: `${makeLabel} ${modelLabel} Car Parts`,
    url: `${routes.manualSelectionParts}?make=${makeLabel}&model=${modelLabel}`,
  });
  resObj.breadcrumbs.push({ title: resObj.heading });
  return resObj;
};

export const getVehicleManufacturers = async () => {
  const { data = [] } = await axios.get(baseURL + routes.restApi.manufacturers);
  if (data.length > 0) {
    const manufacturers =
      data[0]?.manufacturers?.filter(
        (manufacturer) => manufacturer.title !== "Pre G:Code Data",
      ) || [];
    return manufacturers.map((m) => ({
      value: m.id,
      label: m.title,
    }));
  }
  return [];
};

export const getModelList = async (id, t) => {
  try {
    const { data = [], error } = await axios.get(
      `${baseURL + routes.restApi.modellist}/?manufacturerId=${id}`,
    );

    if (error === constants.service_response.unavailable) {
      handleToastError(t("messages.service_unavailable"));
    } else if (data.length > 0) {
      const models = data[0].models || [];
      return models.map((m) => ({
        value: m.id,
        label: m.title,
        type: m.type,
      }));
    }
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { manufacturerId: id },
      msg: "Error while fetching manufacturer models",
    });
  }
  return [];
};

export const getManufacturerModels = async (manufacturerId) => {
  const { data = [] } = await axios.get(
    `${baseURL + routes.restApi.models}/?manufacturerId=${manufacturerId}`,
  );

  if (data.length > 0) {
    const models = data[0].models || [];
    return models.map((m) => ({
      value: m.id,
      label: m.title,
      type: m.type,
    }));
  }
  return [];
};

export const getVehicleNextOptions = async (
  payload,
  currentField,
  hasManualLook = false,
) => {
  let queryArr = [];
  for (const key in payload) {
    let queryKey = constants.vehicle_fields[key];
    if (queryKey && Object.prototype.hasOwnProperty.call(payload, key)) {
      if (payload[key]) {
        queryArr.push(`${queryKey}=${payload[key]}`);
      }
    }
  }

  const queryString = queryArr.join("&");
  let url = `${baseURL + routes.restApi.vehiclemetadata}/?${queryString}`;
  if (hasManualLook) {
    url += `&isMvl=1`;
  }

  const { data = [] } = await axios.get(url);
  let resObject = {
    year: [],
    engine: [],
    body: [],
    kw_hp: [],
  };

  if (data.length > 0) {
    const modelVersions = data[0]?.metaData?.modelVersions || [];
    const engineSizes = data[0]?.metaData?.engineSizes || [];
    const bodyStyles = data[0]?.metaData?.bodyStyles || [];
    const powers = data[0]?.metaData?.powers || [];

    resObject.year = modelVersions.map((y) => ({
      value: y.id,
      label: (y.title.match(/\(([^)]+)\)/) || [])[1] || y.title,
    }));

    switch (currentField) {
      case "body":
        resObject.body = bodyStyles.map((b) => ({
          value: b.id,
          label: b.title,
        }));
        resObject.engine = engineSizes.map((e) => ({
          value: e.id,
          label: e.title,
        }));
        resObject.kw_hp = powers.map((p) => ({
          value: p.id,
          label: p.title?.replace(/bhp/g, ""),
        }));
        break;
      case "engine":
        resObject.body = bodyStyles.map((b) => ({
          value: b.id,
          label: b.title,
        }));
        resObject.engine = engineSizes.map((e) => ({
          value: e.id,
          label: e.title,
        }));
        break;
      case "year":
        resObject.engine = engineSizes.map((e) => ({
          value: e.id,
          label: e.title,
        }));

        if (!engineSizes.length) {
          resObject.search_now = true;
        } else if (
          engineSizes.length === 1 &&
          bodyStyles.length === 1 &&
          powers.length === 1
        ) {
          resObject.extra_fields = {
            engine: engineSizes[0].value,
            body: bodyStyles[0].value,
            kw_hp: powers[0].value,
          };
        }
        break;
    }

    if (hasManualLook) {
      resObject.sessionId = data[0]?.session?.sessionId;
    }
  }
  return resObject;
};

export const getDetailByLabel = async (queryString) => {
  let response = {};
  try {
    const { data = [] } = await axios.get(
      `${baseURL + routes.restApi.makesandmodels}?${queryString}`,
    );

    if (data.length > 0) {
      if (data[0]?.manufacturers) {
        response.manufacturer = {
          label: data[0].manufacturers?.title,
          value: Number(data[0].manufacturers?.id),
        };
      }

      if (data[1] && data[1]?.models) {
        response.model = {
          label: data[1].models?.title,
          value: data[1].models?.id,
        };
      }
    }
  } catch (err) {
    handleErrorLog({
      error: err,
      additional_info: { manufacturerId: id },
      msg: "Error while fetching manufacturer model by labels",
    });
  }
  return response;
};

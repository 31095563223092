import axios from "axios";
import { keys } from "../utils/keys";
import { routes } from "../utils/routes";
import { getCustomerAxios } from "../utils/axios";
import { handleGraphqlResponse, getBackendURL } from "../utils/functions";
import { aggregation, category, detail, productListModel } from "./fragments";
import { handleErrorLog } from "../utils/error_logs";

export const resolveURL = async ({ path }) => {
  try {
    let paramsString = `url: "${path}"`;
    const axiosRes = await axios.post(keys.general.graphqlUrl, {
      query: `query {
        urlResolver(${paramsString}) {
          id
          sku
          type
          level
          entity_uid
          relative_url
          redirectCode
          display_mode
          has_children
          component_id
          is_threebo_view
          is_type_service_part
          breadcrumbs {
            label
            link
            id
            component_id
          }
        }
      }`,
    });
    const res = handleGraphqlResponse(axiosRes);
    return { data: res.data, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getCategoryMeta = async ({ categoryid }) => {
  try {
    const variables = {
      categoryfilters: {
        ids: {
          in: [categoryid],
        },
      },
    };

    const query = `query ProductFilter (
      $categoryfilters: CategoryFilterInput
    ) {
      categoryList (filters: $categoryfilters) {
        id
        name
        image
        meta_title
        meta_keywords
        meta_description
      }
    }`;

    const axiosRes = await axios.post(keys.general.graphqlUrl, {
      query,
      variables,
    });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getProductMeta = async ({ productSKU }) => {
  try {
    const variables = {
      productfilters: { sku: { eq: productSKU } },
    };

    const query = `query ProductFilter (
      $productfilters: ProductAttributeFilterInput
    ) {
      products (
        filter: $productfilters
      ) {
        items {
          id
          name
          meta_title
          meta_keyword
          meta_description
          extraVariable (breadcrumb_required: true) {
            brand_info { title }
          }
          thumbnail {
            url
            label
          }
        }
      }
    }`;

    const axiosRes = await axios.post(keys.general.graphqlUrl, {
      query,
      variables,
    });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getCategoryDetail = async ({ categoryid }) => {
  try {
    const variables = {
      categoryfilters: {
        ids: {
          in: [categoryid],
        },
      },
    };

    const query = `query ProductFilter (
      $categoryfilters: CategoryFilterInput
    ) {
      categoryList (filters: $categoryfilters) {
        ...Category
      }
    }
    ${category}`;

    const axiosRes = await axios.post(getBackendURL(), {
      query,
      variables,
    });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getProductListing = async ({
  page,
  pageSize,
  sortOrder,
  categoryid,
  specialCoupon,
  filtersObj = {},
  skuList = null,
  vehicleFilter = null,
}) => {
  try {
    let productfilters = {
      category_id: { eq: categoryid },
      price: { from: 0.01 },
    };
    if (skuList) {
      productfilters["sku"] = { in: skuList };
    }

    if (
      vehicleFilter &&
      vehicleFilter?.session_id &&
      vehicleFilter?.component_id
    ) {
      productfilters["vrmFilter"] = vehicleFilter;
    }

    for (const key in filtersObj) {
      if (!["page", "make", "model"].includes(key)) {
        productfilters[key] = { in: filtersObj[key] };
      }
    }

    const productModel = productListModel();
    const variables = {
      currentPage: parseInt(page),
      productfilters: productfilters,
      productPageSize: pageSize,
      productSort: {
        [sortOrder.field]: sortOrder.direction.toUpperCase(),
      },
    };

    const query = `query ProductFilter (
      $currentPage: Int,
      $productPageSize: Int
      $productSort: ProductAttributeSortInput
      $productfilters: ProductAttributeFilterInput
      $aggregationsFilter: AggregationsFilterInput
    ) {
      products (
        sort: $productSort
        filter: $productfilters
        currentPage: $currentPage
        pageSize: $productPageSize
        specialCoupon: "${specialCoupon || ""}"
      ) {
        aggregations (filter: $aggregationsFilter) {
          ...AggregationFragment
        }
        items {
          ...Products
        }
        total_count
      }
    }
    ${productModel}
    ${aggregation}`;
    const axiosRes = await axios.post(getBackendURL(), { query, variables });
    return axiosRes.data;
  } catch (error) {
    return { data: [], errors: error.message };
  }
};

export const getProductDetail = async ({ productSKU, specialCoupon }) => {
  try {
      const query = `query ProductFilter (
      $productfilters: ProductAttributeFilterInput
    ) {
      products (
        filter: $productfilters
        specialCoupon: "${specialCoupon || ""}"
      ) {
        items {
          ...Product
        }
      }
    }
    ${detail}`;

      const variables = {
          productfilters: { sku: { eq: productSKU } },
      };

      const axiosRes = await axios.get(getBackendURL(), {
          params: {
              query,
              variables: JSON.stringify(variables), // Serialize the variables as a string
          },
          paramsSerializer: {
              indexes: null, // Ensures proper serialization
          },
      });

      const res = handleGraphqlResponse(axiosRes.data);
      return { data: res, error: null };
  } catch (error) {

      return { data: [], error: error.message };
  }
};

export const getProductPrice = async ({ productSKU, specialCoupon }) => {
  try {
    const variables = JSON.stringify({
      productfilters: { sku: { eq: productSKU } },
    });

    const query = `
      query ProductFilter (
        $productfilters: ProductAttributeFilterInput
      ) {
        products (
          filter: $productfilters
          specialCoupon: "${specialCoupon || ""}"
        ) {
          items {
            sku,
            special_price,
            specialCoupon_applied,
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
            }
          }
        }
      }`;

    const axiosRes = await axios.get(getBackendURL(), {
      params: {
        query,
        variables,
      },
      paramsSerializer: { indexes: null },
    });

    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};




export const getFeaturedDeals = async (specialCoupon) => {
  const productModel = productListModel();
  try {
      const query = `query FeaturedAndSeasonalProducts(
      $featuredFilter: ProductAttributeFilterInput,
      $featuredSort: ProductAttributeSortInput,
      $featuredPageSize: Int
    ) {
      featured_products: products(
        filter: $featuredFilter,
        sort: $featuredSort,
        pageSize: $featuredPageSize
        specialCoupon: "${specialCoupon?.value || ""}"
      ) {
        total_count
        items {
          ...Products
        }
      }
    }
    ${productModel}`;

      const variables = JSON.stringify({
          featuredFilter: {
              featured_product: {
                  eq: 1,
              },
              price: { from: 0.01 },
          },
          featuredSort: {
              featured_product_sort_order: "ASC",
          },
          featuredPageSize: 24, // Example value, adjust as needed
      });

      const axiosRes = await axios.get(getBackendURL(), {
          params: {
              query,
              variables,
          },
          paramsSerializer: {
              indexes: null, // Ensures proper serialization
          },
      });

    if (axiosRes.data.errors) {
      handleErrorLog({
        error: axiosRes.data.errors,
        additional_info: { specialCoupon },
        msg: "API Error while fetching featured deals",
      });
      throw new Error("GraphQL request failed");
    }

    return { data: axiosRes.data.data, error: null };
  } catch (error) {
    handleErrorLog({
      error,
      additional_info: { specialCoupon },
      msg: "Error while fetching featured deals",
    });
    return { data: [], error: error.message };
  }
};

export const searchProductsAndCategories = async ({
  searchText,
  pageSize = 8,
  currentPage = 1,
  catPageSize = 10,
  catCurrentPage = 1,
  specialCoupon,
}) => {
  try {
    const productModel = productListModel();
    const textWithoutQuotes = searchText?.replaceAll('"', "");
    const query = `query {
      products (
        search: "${textWithoutQuotes}",
        pageSize: ${pageSize},
        currentPage: ${currentPage},
        catPageSize: ${catPageSize},
        catCurrentPage: ${catCurrentPage},
        specialCoupon: "${specialCoupon || ""}",
        filter: {
      price: {
        from: 0.01
  }}
      ) {
        search_trems {
          query_text
          redirect
        }
        category_count
        categories {
          name
          link
          image
        }
        total_count
        items {
          ...Products
        }
      }
    }
    ${productModel}`;
    const axiosRes = await axios.post(getBackendURL(), { query });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getCategoryPage = async ({ catId, showChild = true, getRequest = false }) => {
  try {
      const variables = {
          filters: {
              ids: {
                  in: [catId],
              },
          },
      };

      let query = `
      query getCategoryDetails($filters: CategoryFilterInput) {
        categories(filters: $filters) {
          total_count
          items {
            description
            uid
            id
            name
            path
            image
            level
            url_key
            url_path
            children_count
            component_id
            breadcrumbs {
              category_id
              category_name
              category_level
              category_url_path
              category_url_key
            }
            children {
              uid
              name
              path
              image
              level
              url_key
              url_path
              component_id
              children_count
              children {
                id
                name
                path
                image
                level
                url_key
                url_path
                component_id
              }
            }
          }
          page_info {
            page_size
            current_page
            total_pages
          }
        }
      }
    `;

      if (!showChild) {
          query = `query getCategoryDetails($filters: CategoryFilterInput) {
        categories(filters: $filters) {
          total_count
          items {
            description
            id
            uid
            level
            name
            path
            url_path
            url_key
            children_count
            children {
              uid
              level
              name
              path
              url_path
              url_key
              image
            }
          }
        }
      }
    `;
      }

      let axiosRes;

      if (getRequest) {
          axiosRes = await axios.get(getBackendURL(), {
              params: {
                  query,
                  variables: JSON.stringify(variables), // Serialize the variables object for GET requests
              },
              paramsSerializer: {
                  indexes: null, // Ensures proper query parameter formatting
              },
          });
      } else {
          axiosRes = await axios.post(getBackendURL(), {
              query,
              variables,
          });
      }

      const res = handleGraphqlResponse(axiosRes.data);
      return { data: res, error: null };
  } catch (error) {
      return { data: [], error: error.message };
  }
};

export const getFeaturedProducts = async (productType, specialCoupon) => {
  try {
    const productModel = productListModel();
      const variables = JSON.stringify({
          productFilter: {
              [productType]: {
                  eq: 1,
              },
              price: { from: 0.01 },
          },
          productSort: {
              name: "ASC",
          },
          PageSize: 4,
      });

      const query = `
      query showOnBasketProducts(
        $productFilter: ProductAttributeFilterInput
        $productSort: ProductAttributeSortInput
        $pageSize: Int
      ) {
        products: products(
          filter: $productFilter
          sort: $productSort
          pageSize: $pageSize
          specialCoupon: "${specialCoupon.value || ""}"
        ) {
          total_count
          items {
            ...Products
          }
        }
      }
      ${productModel}`;

      const axiosRes = await axios.get(getBackendURL(), {
          params: {
              query,
              variables,
          },
          paramsSerializer: {
              indexes: null, // Ensures proper serialization
          },
      });
      const res = handleGraphqlResponse(axiosRes.data);
      return { data: res, error: null };
  } catch (error) {
      return { data: [], error: error.message };
  }
};

export const getSubCategoryData = async ({ categoryid }) => {
  try {
    const variables = {
      filters: {
        ids: {
          in: [categoryid],
        },
      },
    };

    const query = `
      query GetSubCategoryData($filters: CategoryFilterInput) {
        categories(filters: $filters) {
          total_count
          items {
            uid
            id
            level
            name
            path
            url_path
            url_key
            image
            description
            breadcrumbs {
              category_id
              category_name
              category_level
              category_url_path
              category_url_key
            }
            children_count
            children {
              uid
              id
              level
              name
              path
              url_path
              url_key
              image
              description
              children_count
              breadcrumbs {
                category_id
                category_name
                category_level
                category_url_path
                category_url_key
              }
              children {
                id
                level
                name
                path
                url_path
                url_key
                image
              }
            }
          }
          page_info {
            current_page
            page_size
            total_pages
          }
        }
      }
    `;

    const axiosRes = await axios.post(getBackendURL(), {
      query,
      variables,
    });
    const res = handleGraphqlResponse(axiosRes.data);

    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

// check vehicle compatibility
export const checkVehicleCompatability = async (payload) => {
  const queryStr = new URLSearchParams(payload).toString();
  const axiosRes = await axios.get(
    `${keys.general.restUrl + routes.restApi.vehiclecompatibility}?${queryStr}`,
  );
  return axiosRes.data;
};

export const getCarPartsCategories = async ({ catId, showChild = true }) => {
  try {
    const variables = {
      filters: {
        ids: {
          in: [catId],
        },
      },
    };

    const withSubCat = `{
      id
      name
      description
      breadcrumbs {
        category_id
        category_name
        category_level
        category_url_path
        category_url_key
      }
      children {
        id
        name
        description
        image
        url_path
        component_id
        children {
          id
          name
          image
          url_path
          component_id
        }
      }
    }`;

    const withoutSubCat = `{
      id
      name
      description
      breadcrumbs {
        category_id
        category_name
        category_level
        category_url_path
        category_url_key
      }
      children {
        id
        name
        description
        image
        url_path
        component_id
      }
    }`;

    const query = `query getCategoryDetails ($filters: CategoryFilterInput) {
        categories(filters: $filters) {
          items ${!showChild ? withoutSubCat : withSubCat}
        }
      }
    `;

    const axiosRes = await axios.post(getBackendURL(), { query, variables });
    const res = handleGraphqlResponse(axiosRes.data);

    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const notifyStockAlertMail = async (product_id) => {
  try {
    const currentAxios = await getCustomerAxios();
    const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
      query: `mutation ProductAlertStock($input: ProductAlertInput!) {
  productAlertStock(input: $input){
    status
  }
}
`,
      variables: {
        input: {
          product_id: product_id,
        },
      },
    });
    const res = handleGraphqlResponse(axiosRes);
    let err;
    if (res?.errors) {
      err = res?.errors[0].message;
    } else {
      err = null;
    }
    return { data: res.data, error: err };
  } catch (error) {
    return { data: null, error };
  }
};

export const unsubscribeNotifyStockAlertMail = async () => {
  try {
    const currentAxios = await getCustomerAxios();
    const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
      query: `mutation{
    unSubscribeAllProductStock{
        status
    }
}
`,
    });
    const res = handleGraphqlResponse(axiosRes);
    let err;
    if (res?.errors) {
      err = res?.errors[0].message;
    } else {
      err = null;
    }
    return { data: res.data, error: err };
  } catch (error) {
    return { data: null, error };
  }
};


export const notifyStockAlertMailGuest = async (payload) => {
  try {
    const axiosRes = await axios.post(getBackendURL(), {
      query: `mutation GuestProductAlertStock($input: GuestProductAlertInput!) {
  guestProductAlertStock(input: $input){
    status
  }
}
`,
      variables: {
        input: payload
      }
    });
    const res = handleGraphqlResponse(axiosRes);
    let err;
    if (res?.errors) {
      err = res?.errors[0].message;
    } else {
      err = null;
    }
    return { data: res.data, error: err };
  } catch (error) {
    return { data: null, error };
  }
};

export const unsubscribeNotifyStockAlertMailGuest = async (payload) => {
  try {
    const axiosRes = await axios.post(getBackendURL(), {
      query: `mutation UnSubscribeGuestProductAlertStock($input: GuestProductAlertUnsubscribeInput!) {
  unSubscribeGuestProductAlertStock(input: $input){
    status
    email
  }
}
`,
      variables: {
        input: payload
      }
    });
    const res = handleGraphqlResponse(axiosRes);
    let err;
    if (res?.errors) {
      err = res?.errors[0].message;
    } else {
      err = null;
    }
    return { data: res.data, error: err };
  } catch (error) {
    return { data: null, error };
  }
};
"use client";

import Link from "next/link";
import React, { useState } from "react";

const Accordian = ({
  title,
  children,
  urlPath = "",
  className = "",
  defaultActive = false,
}) => {
  const [active, setActive] = useState(defaultActive);
  const handleAccordionClick = () => setActive(!active);

  const rednderTitle = () => {
    if (urlPath) {
      return <Link prefetch={false} href={urlPath} className="pointer-events-none xs:pointer-events-auto">
        <h3 className="text-body-text-1-b">{title}</h3>
      </Link>
    }
    return <h3 className="text-body-text-1-b">{title}</h3>
  }

  return (
    <div
      className={`accordion accessories-accordion ${active ? "active" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div
              tabIndex={0}
              role="button"
              onClick={handleAccordionClick}
              className={`accordion-header ${className}`}
            >
              {rednderTitle()}
              <svg
                width="12"
                height="7"
                fill="none"
                viewBox="0 0 12 7"
                xmlns="http://www.w3.org/2000/svg"
                className={`xs:hidden block transition-all duration-300 ${
                  active ? " " : "rotate-180"
                }`}
              >
                <path
                  fill="#1D242B"
                  d="M6.00424 0.655634C6.12476 0.655634 6.23694 0.674867 6.34077 0.713334C6.44462 0.751783 6.54334 0.817809 6.63692 0.911408L11.1311 5.40563C11.2696 5.54408 11.3404 5.71812 11.3436 5.92773C11.3469 6.13733 11.276 6.31457 11.1311 6.45943C10.9863 6.60432 10.8106 6.67676 10.6042 6.67676C10.3978 6.67676 10.2222 6.60432 10.0773 6.45943L6.00424 2.38636L1.93115 6.45943C1.7927 6.5979 1.61866 6.66873 1.40905 6.67193C1.19945 6.67515 1.02221 6.60432 0.877346 6.45943C0.732463 6.31457 0.660021 6.13893 0.660021 5.93253C0.660021 5.72613 0.732463 5.5505 0.877346 5.40563L5.37157 0.911408C5.46515 0.817809 5.56387 0.751783 5.66772 0.713334C5.77155 0.674867 5.88373 0.655634 6.00424 0.655634Z"
                />
              </svg>
            </div>
            <div className="accordion-content">
              <div className="main-content-section">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordian;

import Link from "next/link";
import { getImageProps } from "next/image";
import { updateImageURL } from "@/lib/utils/functions";
import { getContentfulImgData } from "@/lib/services/contentful";

const PLPBanner = ({ desktopBanner, mobileBanner }) => {
  const mobileImg = getContentfulImgData(mobileBanner?.fields?.image);
  const desktopImg = getContentfulImgData(desktopBanner?.fields?.image);
  const bannerLink = desktopBanner?.fields?.link;
  const common = {
    priority: true,
    alt: desktopImg.title || desktopImg.fileName || "banner",
  };
  const {
    props: { srcSet: desktop },
  } = getImageProps({
    ...common,
    width: 1440,
    height: 440,
    quality: 90,
    src: updateImageURL(desktopImg.imageUrl, 1440, 440, true),
  });

  const {
    props: { srcSet: mobile, ...rest },
  } = getImageProps({
    ...common,
    width: 450,
    height: 450,
    quality: 85,
    src: updateImageURL(mobileImg.imageUrl, 450, 450, true),
  });

  const BannerImage = (
    <picture className="flex xxs:min-h-[144px] min-h-[40px] h-full w-12/12 xs:order-1 order-2 xxs:max-h-[440px] max-h-[144px] relative">
      <source media="(min-width: 451px)" srcSet={desktop} />
      <source media="(max-width: 450px)" srcSet={mobile} />
      <img
        {...rest}
        alt={common.alt}
        className="xs:object-contain object-fill"
        style={{ width: "100%", height: "auto" }}
      />
    </picture>
  );

  return (
    <section className="w-12/12 relative flex flex-col max-w-[1440px] mx-auto">
      {bannerLink ? (
        <Link prefetch={false} href={bannerLink}>
          {BannerImage}
        </Link>
      ) : (
        BannerImage
      )}
    </section>
  );
};

export default PLPBanner;

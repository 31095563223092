
export const hasDataLayer = () => {
  if (typeof window === "undefined") return false;
  return window?.dataLayer && process.env.NEXT_PUBLIC_GTM_ID;
};

const viewItemListAnalytics = (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "view_item_list",
      ecommerce: data,
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push(payload);
  }
};

const beginCheckoutAnalytics = (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "begin_checkout",
      ecommerce: data?.ecommerce,
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push(payload);
  }
};

const branchSearchAnalytics = (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "branch_search",
      postcode: data?.keyword || "",
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push(payload);
  }
};

const basketViewedAnalytics = (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "view_cart",
      ecommerce: {
        currency: data?.total?.currency,
        value: data?.total?.value,
        items: data?.items,
      },
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push(payload);
  }
};

const removeFromCartAnalytics = (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "remove_from_cart",
      ecommerce: {
        currency: data?.total?.currency,
        value: data?.total?.value.toFixed(2),
        items: data?.items,
      },
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push(payload);
  }
};

const purchaseOrderAnalytics = (data, transactionId) => {
  if (hasDataLayer()) {
    let payload = {
      event: "purchase",
      email: data?.email,
      ecommerce: {
        ...data?.ecommerce,
        transaction_id: transactionId,
      },
    };

    if (data?.user_id) {
      payload.user_id = data?.user_id;
    }
    dataLayer.push({ ecommerce: null });
    dataLayer.push(payload);
  }
};

const no_products_found = async (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "no_products_found",
      ...data,
    };
    dataLayer.push({
      ...payload,
    });
  }
};

const page_information = async (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "page_information",
      ...data,
    };
    dataLayer.push({
      ...payload,
    });
  }
};

const select_item = async (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "select_item",
      ecommerce: data,
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      ...payload,
    });
  }
};

const add_shipping_info = async (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "add_shipping_info",
      ecommerce: data,
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      ...payload,
    });
  }
};

const add_payment_info = async (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "add_payment_info",
      ecommerce: data,
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      ...payload,
    });
  }
};

const add_to_cart = async (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "add_to_cart",
      ecommerce: data,
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      ...payload,
    });
  }
};

const view_item = async (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "view_item",
      ecommerce: data,
    };
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      ...payload,
    });
  }
};

const registration_lookup = async (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "registration_lookup",
      ...data,
    };
    dataLayer.push({
      ...payload,
    });
  }
};

const search = async (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "search",
      ...data,
    };
    dataLayer.push({
      ...payload,
    });
  }
};

const add_vehicle = async (data) => {
  if (hasDataLayer()) {
    const payload = {
      event: "add_vehicle",
      ...data,
    };
    dataLayer.push({
      ...payload,
    });
  }
};

const login = async (user_id) => {
  if (hasDataLayer()) {
    const payload = {
      event: "login",
      user_id: String(user_id),
    };
    dataLayer.push({
      ...payload,
    });
  }
};
const sign_up = async (user_id) => {
  if (hasDataLayer()) {
    const payload = {
      event: "sign_up",
      user_id: String(user_id),
    };
    dataLayer.push({
      ...payload,
    });
  }
};

const logout = async () => {
  if (hasDataLayer()) {
    const payload = {
      event: "logout",
    };
    dataLayer.push({
      ...payload,
    });
  }
};

const newsletter_subscribe = async () => {
  if (hasDataLayer()) {
    const payload = {
      event: "newsletter_subscribe",
    };
    dataLayer.push({
      ...payload,
    });
  }
};

const entered_payment_gateway = async (method) => {
  if (hasDataLayer()) {
    const payload = {
      event: "entered_payment_gateway",
      payment_gateway_name: method,
    };
    dataLayer.push({
      ...payload,
    });
  }
};

export {
  viewItemListAnalytics,
  beginCheckoutAnalytics,
  branchSearchAnalytics,
  basketViewedAnalytics,
  removeFromCartAnalytics,
  purchaseOrderAnalytics,
  sign_up,
  registration_lookup,
  add_vehicle,
  login,
  view_item,
  add_payment_info,
  add_shipping_info,
  add_to_cart,
  select_item,
  logout,
  newsletter_subscribe,
  search,
  no_products_found,
  page_information,
  entered_payment_gateway,
};

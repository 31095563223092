"use client";

import { Fragment } from "react";
import { routes } from "@/lib/utils/routes";
import { Navigation } from "swiper/modules";
import { useAppSelector } from "@/lib/store";
import { useTranslation } from "@/i18n/client";
import CommonSwiper from "@/components/common/shared/Slider";
import Button from "@/components/common/shared/Button/button";
import CategoryCard from "@/components/common/card/CategoryCard";
import styles from "@/assets/css/pages/font.module.css";
const CategorySection = ({ categories = [], headings = {} }) => {
  const { t } = useTranslation("home");
  const { storeConfig } = useAppSelector((state) => state.globalAssets);
  const sliderConfig = {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      prevEl: ".swiper-categoryBtn-prev",
      nextEl: ".swiper-categoryBtn-next",
    },
    speed: 300,
    modules: [Navigation],
    breakpoints: {
      0: {
        slidesPerView: "auto",
        spaceBetween: 7.5,
        freeMode: true,
      },
      767: {
        slidesPerView: 4,
        spaceBetween: 24,
        freeMode: true,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 24,
      },
      1344: {
        slidesPerView: 6,
        spaceBetween: 24,
      },
    },
  };

  const slides = categories.map((item) => ({
    content: (
      <CategoryCard
        key={item.id}
        heading={item.name}
        href={item.url_path}
        categoryid={item.id}
        description={item.description}
        image={item?.image ? `${item.image}?width=152` : item.image}
      />
    ),
  }));

  return (
      <section className="w-12/12 pb-6 sm:pb-80 bg-tertiary-white overflow-hidden z-[1] relative">
        <div className="container">
          <div className="row">
            <div className="col w-12/12">
              <div className="flex justify-between xs:mb-8 mb-4 items-baseline xs:items-end">
                <div className="flex flex-col">
                  <h2 className={`${styles.homeHeading} xs:mb-12`}>
                    {storeConfig?.popular_category_title ||
                      headings?.popular_category_title}
                  </h2>
                  <p className="sub-heading">
                    {storeConfig?.popular_category_desc ||
                      headings?.popular_category_desc}
                  </p>
                </div>
                <div className="flex items-center">
                  <Button
                    type="link"
                    icon="arrow"
                    href={routes.parts}
                    className="font-semibold"
                    theme="blue-bordered-btn-arrow"
                  >
                    {t("btns.view_all_btn")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col w-12/12">
              <div className="categories-slider">
                <CommonSwiper slides={slides} config={sliderConfig} />
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default CategorySection;

"use client";

import Link from "next/link";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { routes } from "@/lib/utils/routes";
import { add_to_cart, select_item } from "@/lib/gtm";
import ImageWithFallback from "../ImageWithFallback";
import Button from "@/components/common/shared/Button/button";
import {
  updateImageURL,
  formatProductCategoriesGA4,
  updateGA4PayloadToSelectItem,
} from "@/lib/utils/functions";
import styles from "@/assets/css/pages/font.module.css";

const SpecialPriceBadge = dynamic(() => import("./SpecialPriceBadge"));
const Badge = dynamic(() => import("@/components/common/shared/Badge"));

const SubCategoryCard = ({
  t,
  product,
  item_list,
  loop_index,
  imageTypes,
  handleAddToTrolley,
}) => {
  const brands = product?.brands || [];
  const productBranch = product?.branch;
  const selectedBrand = brands.find((b) => b.is_selected);

  const homeDeliveryStock = selectedBrand?.quantity > 0;
  const selectedBranchStock = selectedBrand?.branch_stock > 0;
  const muteClickCollect = productBranch?.branch_id && !selectedBranchStock;
  const productTitle =
    selectedBrand?.product_title_with_brand || product?.product_title;

  let ga4_payload = {
    currency: selectedBrand?.currency,
    value: selectedBrand?.final_price_without_currency,
    items: [
      {
        item_id: selectedBrand?.sku,
        item_name: product?.product_title,
        discount: selectedBrand?.amountOff_without_currency,
        item_brand: selectedBrand?.title,
        price: selectedBrand?.final_price_without_currency,
        quantity: 1,
        index: loop_index,
        ...(Number(selectedBrand?.amountOff_without_currency) &&
          !selectedBrand?.specialCoupon_applied && {
            coupon: Cookies.get("specialCoupon"),
          }),
        ...formatProductCategoriesGA4(selectedBrand?.breadcrumbs),
      },
    ],
  };

  const addProductToBasket = () => {
    const addToCartEvent = () => {
      add_to_cart(ga4_payload);
    };
    handleAddToTrolley({
      product: {
        name: productTitle,
        sku: selectedBrand?.sku,
        thumbnail: selectedBrand?.thumbnail,
      },
      addToCartEvent: addToCartEvent,
    });
  };

  return (
    <div className="productBox relative">
      <div
        className="coverProductImage"
        onClick={() => {
          select_item(
            updateGA4PayloadToSelectItem(
              ga4_payload,
              selectedBrand?.breadcrumbs,
              item_list,
            ),
          );
        }}
      >
        <Link
          prefetch={false}
          href={`${routes.products}/${selectedBrand.url_key}`}
        >
          <ImageWithFallback
            width={308}
            height={280}
            type={imageTypes.product}
            className="mx-auto min-w-full"
            alt={selectedBrand?.thumbnail?.label || "Product"}
            src={updateImageURL(selectedBrand?.thumbnail?.url, 308, 280)}
          />
        </Link>

        {selectedBrand?.showDiscountedPrice && (
          <SpecialPriceBadge price={selectedBrand?.amountOff} />
        )}
      </div>
      <div className="p-[8px] xs:p-[10px] xs:pb-3 flex flex-col justify-between h-12/12">
        {selectedBrand?.best_seller == 1 &&
          selectedBrand?.is_recommended == 1 && (
            <Badge
              imageTypes={imageTypes}
              bestSeller={selectedBrand?.best_seller == 1}
              recommended={selectedBrand?.is_recommended == 1}
            />
          )}

        <Link
          prefetch={false}
          href={`${routes.products}/${selectedBrand.url_key}`}
          className="sm:h-[52px] xs:h-[48px] h-[40px] overflow-hidden overflow-ellipsis whitespace-pre-wrap flex flex-col"
        >
          <h2
            className={`${styles.heading18B} lg:max-w-12/12 xs:max-w-[90%] flex-1 h-auto line-clamp-2`}
            onClick={() => {
              select_item(
                updateGA4PayloadToSelectItem(
                  ga4_payload,
                  selectedBrand?.breadcrumbs,
                  item_list,
                ),
              );
            }}
          >
            {productTitle || "Product"}
          </h2>
        </Link>
        {/* Product Description */}

        <div className="mt-auto">
          <div className="my-1 xs:my-10">
            <span
              className={`productPrice ${
                selectedBrand?.showStrikePrice ? "red" : ""
              }`}
            >
              {selectedBrand?.final_price}
            </span>
            {!!selectedBrand?.showStrikePrice && (
              <span className="oldPrice">{selectedBrand?.regular_price}</span>
            )}
          </div>
          <Button
            type="button"
            icon="shopping"
            iconPosition="left"
            theme="add-to-basket-btn"
            onClick={addProductToBasket}
            disabled={!homeDeliveryStock && muteClickCollect}
          >
            {t("button.add_to_basket")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubCategoryCard;

"use client";

import Cookies from "js-cookie";
import { useRouter } from "next-nprogress-bar";
import { useTranslation } from "@/i18n/client";
import { registration_lookup } from "@/lib/gtm";
import { constants } from "@/lib/utils/constants";
import CurrenVehicleBox from "./CurrenVehicleBox";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/lib/store";
import Button from "@/components/common/shared/Button/button";
import RecommendedIcon from "@/assets/icon/red_recommended_icon.svg";
import { getVehicleInfo, handleVehicleResposne } from "@/lib/services/api";
import {
  showVehicleInfo,
  handleToastError,
  replaceHistoryState,
} from "@/lib/utils/functions";
import { updateVehicle } from "@/lib/store/reducers/vehicle";
import { updateBreadcrumb } from "@/lib/store/reducers/globalAssets";
import { endCommonLoading, startCommonLoading } from "@/lib/store/reducers/uiState";
import styles from "@/assets/css/pages/font.module.css";

export default function CurrentVehicle({
  heading = "",
  subHeading = "",
  isPDP = false,
  hideForm = false,
  selectedMake = {},
  selectedModel = {},
  isCompatible = true,
  isSearchPage = false,
  categoryHeading = "",
  hasManualLook = false,
  selectedVehicle = null,
  categoryBreadcrumbs = [],
  handleShowProducts = () => {},
}) {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);
  const { vehicle } = useAppSelector((state) => state.vehicle);

  const [manual, setManual] = useState(hasManualLook);
  const [headerTitle, setHeaderTitle] = useState(heading);
  const [currentVehicle, setCurrentVehicle] = useState(selectedVehicle);
  const info = useMemo(() => showVehicleInfo(currentVehicle), [currentVehicle]);

  useEffect(() => {
    setHeaderTitle(heading);
  }, [heading]);

  const handleGoBack = () => router.back();
  const renderBackButton = () => {
    const HeadingTag = isPDP ? "h2" : "h1";
    if (isSearchPage) {
      return (
        <div className="flex flex-col">
          <Button
            theme="iconOnly"
            icon="accountBack"
            iconPosition="left"
            className="back-btn mt-4 xs:hidden"
            onClick={handleGoBack}
          >
            {t("button.back")}
          </Button>
          <h1 className={`${styles.heading32} small-heading capitalize sm:mt-24 xs:mt-5 mt-[10px] sm:mb-24 xs:mb-5 mb-10`}>
            {headerTitle}
          </h1>
        </div>
      );
    }

    return (
      <div className="flex flex-col xs:mb-12 mb-10">
        <div className="flex sm:mt-24 xs:mt-5 mt-[10px]">
          <Button
            theme="iconOnly"
            icon="accountBack"
            iconPosition="left"
            onClick={handleGoBack}
            className="back-btn xs:!hidden"
          ></Button>
          <HeadingTag className={`${styles.heading32} capitalize xs:mb-12 mb-10 small-heading-text`}>
            {headerTitle}
          </HeadingTag>
        </div>
        <p className="text-primary-black-800 xs:text-body-text-2 text-body-text-3">
          {subHeading}
        </p>
      </div>
    );
  };

  const handleFindParts = async (e) => {
    const regNumber = e?.reg?.replace(/\s/g, "");
    if (!regNumber) {
      handleToastError(t("messages.error.required_vehicle_registration"));
      return;
    }

    try {
      document
        .querySelector("#current-vehicle")
        .classList.remove("border-danger");

      dispatch(startCommonLoading());
      const vRes = await getVehicleInfo(`vrm=${regNumber}`, true);
      const vData = handleVehicleResposne(t, vRes, handleToastError);
      if (!vData) return;
      registration_lookup({
        manufacturer: vData?.vehicle?.make,
        model: vData?.vehicle?.model,
        years: vData?.vehicle?.dateFirstRegistered?.match(/^(\d{4})/)[0],
        ...(vData?.vehicle?.engineSize && {
          engine: vData?.vehicle?.engineSize,
        }),
      });

      dispatch(updateVehicle(vData));
      setHeaderTitle(categoryHeading || heading);
      dispatch(updateBreadcrumb({ breadcrumbs: categoryBreadcrumbs }));

      const urlSearchParams = new URLSearchParams(window.location.search);
      const { make, model, ...params } = Object.fromEntries(
        urlSearchParams.entries(),
      );

      if (make && model) {
        const searchParamString = new URLSearchParams(params).toString();
        replaceHistoryState({ url: `?${searchParamString}` });
      }
    } catch (err) {
      handleToastError(err.message);
    } finally {
      dispatch(endCommonLoading());
    }
  };

  const removeManualSelection = () => {
    setManual((prev) => {
      if (prev) {
        setHeaderTitle(categoryHeading || heading);
        dispatch(updateBreadcrumb({ breadcrumbs: categoryBreadcrumbs }));
      }
      return false;
    });
  };

  const handleChangeVehicle = () => {
    removeManualSelection();
    dispatch(updateVehicle(null));
    Cookies.remove(constants.cookies.new_vehicle);
    Cookies.remove(constants.cookies.manual_lookup);
    Cookies.remove(constants.cookies.selectedVehicle);
  };

  useEffect(() => {
    setCurrentVehicle(() => vehicle);
    const ele = document.querySelector("#current-vehicle");
    if (ele) {
      ele.classList.remove("border-danger");
    }
  }, [vehicle]);

  return (
    <section className="recomonded-header">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <div>
              {renderBackButton()}
              {!hideForm && (
                <div
                  id="current-vehicle"
                  className={`current-vehicle-card ${
                    currentVehicle && !isCompatible && "border-danger"
                  }`}
                >
                  <div>
                    <div>
                      {!currentVehicle ? (
                        <>
                          <p className="text-body-text-1-sb mb-[12px] text-tertiary-black sm:pt-0 pt-[6px]">
                            {t("current_vehicle.title")}
                          </p>
                          <CurrenVehicleBox
                            t={t}
                            hasManualLook={manual}
                            selectedMake={selectedMake}
                            submitForm={handleFindParts}
                            selectedModel={selectedModel}
                            removeManualSelection={removeManualSelection}
                          />
                        </>
                      ) : (
                        <div className="flex sm:flex-row flex-col justify-between sm:items-center">
                          <div className="flex items-center max-w-full">
                            {!isCompatible && (
                              <RecommendedIcon className="xs:flex hidden" />
                            )}
                            {isPDP && (
                              <h5 className="text-subtitle-2 text-primary-black xs:block hidden ml-1 mr-1">
                                {isCompatible
                                  ? t("current_vehicle.part_fit")
                                  : t("current_vehicle.part_not_fit")}
                              </h5>
                            )}

                            <p className="text-primary-black-600 lg:text-subtitle-2 xs:text-body-text-1-sb text-minor-text-1">
                              {info}
                            </p>
                          </div>

                          <div className="flex justify-between  gap-4 items-center w-full xs:w-auto xs:mt-0 mt-2">
                            <Button
                              className="text-body-text-3-sb text-primary-blue-900 text-start border-b border-primary-blue-900 leading-4"
                              onClick={handleChangeVehicle}
                            >
                              {t("current_vehicle.change_vehicle")}
                            </Button>
                            {isPDP && !isCompatible && (
                              <Button
                                theme="blue-btn-xs"
                                onClick={handleShowProducts}
                                className="!font-semibold"
                              >
                                {t("button.show_parts_that_fit")}
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import axios from "axios";
import { keys } from "../utils/keys";
import { getYouMayLikeProducts } from "./cart";
import { productListModel } from "./fragments";
import { handleGraphqlResponse, isSSR } from "../utils/functions";

const baseRequest = async (query, variables = {}) => {
  try {
    const url = isSSR()
      ? keys.general.graphqlUrl
      : keys.general.backendGraphqlUrl;
      const queryString = JSON.stringify({ query, variables });
      const { data } = await axios.get(url, {
          params: {
              query,
              variables: queryString,
          },
          paramsSerializer: {
              indexes: null, // Ensures proper serialization
          },
      });
      return [handleGraphqlResponse(data), null];
  } catch (err) {
      return [
          null,
          {
              message: err.message,
          },
      ];
  }
};

// get seasonal products for homepage
export const getSeasonalProducts = ({ specialCoupon }) => {
  const productModal = productListModel("listing");
  const query = `{
  seasonal_products: products(
    filter: {seasonal_product: { eq: 1 }, price: { from: 0.01 } }
    sort: { seasonal_product_sort_order: ASC }
    pageSize: 6
    specialCoupon: "${specialCoupon || ""}"
  ) {
    total_count
    items {
      ...Products
      list_sort: seasonal_product_sort_order
    }
  }
}
${productModal}`;
  return baseRequest(query);
};

// get featured products for homepage
export const getFeaturedProducts = ({ specialCoupon }) => {
  const productModal = productListModel("listing");
  const query = `{
  featured_products: products(
    filter: { featured_product: { eq: 1 }, price: { from: 0.01 } }
    sort: { featured_product_sort_order: ASC }
    pageSize: 8
    specialCoupon: "${specialCoupon || ""}"
  ) {
    total_count
    items {
      ...Products
      list_sort: featured_product_sort_order
    }
  }
}
 ${productModal}`;
  return baseRequest(query);
};

export const getPopularCategories = (withoutStoreConfig = false) => {
  const categoriesQuery = `{
  getPopularCategories(currentStoreId: 1${withoutStoreConfig ? "" : ", pageSize: 6"
      }) {
    pageSize
    category {
      id
      name
      url_path
      image
      description
      meta_title
      meta_keyword
      meta_description
    }
  }
}`;

  return baseRequest(categoriesQuery);
};

export const getRecentlyViewedProducts = async (
  payload,
  specialCoupon,
  recentProducts = [],
) => {
  try {
    const productModel = productListModel("recent");
      const queryParam = payload.map((val) => `"${val}"`);
      const query = `{
    products(filter: { sku: { in: [${queryParam.join(
          ", ",
      )}] }, price:{from:0.01} }, specialCoupon: "${specialCoupon || ""}") {
      total_count
      items {
        ...Products
      }
    }
  }
  ${productModel}`;

      const axiosRes = await axios.get(keys.general.backendGraphqlUrl, {
          params: {
              query,
          },
          paramsSerializer: {
              indexes: null, // Ensures proper serialization
          },
      });

      const data = handleGraphqlResponse(axiosRes.data);
      let products = recentProducts
          ?.map((id) => data?.products?.items?.find((p) => p.sku == id))
          ?.filter?.(Boolean);

      if (products?.length < 4) {
          const pageSize = 4 - products.length; // Dynamically calculate pageSize
          const cartproductsRes = await getYouMayLikeProducts({
              pageSize, // Adjust the pageSize based on remaining products needed
              order: "ASC",
              specialCoupon,
          });
          const cartproductsData = handleGraphqlResponse(cartproductsRes);
          const remainingProducts = cartproductsData?.products?.items;
          products = [...products, ...remainingProducts];
      }

      return { products, storeConfig: data?.storeConfig };
  } catch (error) {
      return { products: [], storeConfig: {}, error: error.message };
  }
};